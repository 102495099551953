<div class="main">
<div class="heading">
     <p>Podešavanje profila</p>
     <h3 *ngIf="PlaceDTO">{{PlaceDTO.name}}</h3>
</div>
<div class="heading display">
     <h4>Podešavanje profila</h4>
</div>
<div class="page">
    <ul class="page-options">
        <li  class="page-options-item" routerLinkActive="active"> 
            <a routerLink="general"  href="">Opšte</a>
        </li>
        <li  class="page-options-item" routerLinkActive="active">
            <a routerLink="photo"  href="">Slike</a>
        </li>
        <li class="page-options-item" routerLinkActive="active">
            <a routerLink="treatments"  href="">Usluge</a>
        </li>
    </ul>
</div>
<router-outlet></router-outlet>
</div>

