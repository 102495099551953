import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  router:Router = inject(Router);
  isDropdownOpen1: boolean = false;
  isDropdownOpen2: boolean = false;
  categories: string[] = [
    'Saloni', 'Gruming', 'Automobili',
    
  ];

  UserDTO: any;

  constructor(private auth: AuthService) {}

  ngOnInit(){

    const userObservable = this.auth.getUser();

    if (userObservable) {
      userObservable.subscribe({
        next: (data) => {
          if (data) {
            this.UserDTO = data;
          } else {
        
          }
        },
        error: (userError) => {
          console.error(userError);
        }
      });
    } 
  }

  isUserLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }
 
  toggleDropdown(dropdownId: string) {
    if (dropdownId === 'dropdown1') {
      this.isDropdownOpen1 = !this.isDropdownOpen1;
      this.isDropdownOpen2 = false;
    } else if (dropdownId === 'dropdown2') {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
      this.isDropdownOpen1 = false;
    }
  }

  closeDropdown(dropdownId: string) {
    if (dropdownId === 'dropdown1') {
      this.isDropdownOpen1 = false;
    } else if (dropdownId === 'dropdown2') {
      this.isDropdownOpen2 = false;
    }
  }

  closeMenu(){
    this.isDropdownOpen1 = false;
  }

  navigatetoPage(){
    const userRole = localStorage.getItem('userRole');

    if (userRole === 'OWNER') {
      this.router.navigate(['partner']);
    } else if (userRole === 'CLICKO_USER') {
      this.router.navigate(['client']);
    } else {
      this.router.navigate(['homepage']);
    }
  }

  navigatetoPartner(){
    this.router.navigate(['partnerpage']);
  }

  navigatetoHomepage(){
    this.router.navigate(['homepage']);
  }

  navigatetoLogin(){
    this.router.navigate(['login']);
  }
  navigatetoSignup(){
    this.router.navigate(['signup']);
  }

  
  logout(){
    this.auth.signout();
   }

}