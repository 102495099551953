<div class="flex">
    <section class="pending" >
       <h4>Na Čekanju</h4>
       <p *ngIf="allHold.length === 0">Trenutno nema termina na čekanju</p>
       <div class="appointment-div" *ngIf="allHold.length > 0">
         <div class="appointment-pending"  *ngFor="let appointment of allHold" >
          <div class="treatment-div">
            <p class="treatment">{{appointment.appointmentName}}</p>
            <p class="time">
             {{  formatTime(appointment.time,appointment.durationTime)}} , {{ dateTranform(appointment.date) }}
            </p>
            <p class="staff">
              {{appointment.staffName}} 
            </p>
         </div>
           <div class="middle">
             <p class="time">
              {{  formatTime(appointment.time,appointment.durationTime)}}
             </p>
             <p class="time">
             {{ dateTranform(appointment.date) }}
             </p>
             <p class="staff">
               {{appointment.staffName}} 
             </p>
             <p class="client">
              {{appointment.userName}} 
             </p>
           </div>
           <div class="icons">
             <span class="material-symbols-outlined green" (click)="accept(appointment.id, 'PRIHVACEN')">
                check_circle
             </span>
             <span class="material-symbols-outlined red" (click)="sentId(appointment.id, 'ODBIJEN')">
                cancel
             </span>
             <span class="material-symbols-outlined green" (click)="openTreatment(appointment.id)">
                edit
             </span>
           </div>
         </div>
       </div>
    </section>
    <section class="others">
        <h4>Ostali termini</h4>
        <p *ngIf="allNotOnHold.length === 0">Trenutno nema ostalih termina</p>
        <div class="appointment-div" *ngIf="allNotOnHold.length > 0">
          <div [ngClass]="getAppointmentClass(appointment.typeOfBA)"  *ngFor="let appointment of allNotOnHold" >
            <div class="treatment-div">
              <p class="treatment">{{appointment.appointmentName}}</p>
              <p class="time">
               {{  formatTime(appointment.time,appointment.durationTime)}} , {{ dateTranform(appointment.date) }}
              </p>
              <p class="staff">
                {{appointment.staffName}} 
              </p>
           </div>
            <div class="middle">
              <p class="time">
                {{ formatTime(appointment.time,appointment.durationTime)}}
              </p>
              <p class="time">
                {{ dateTranform(appointment.date) }}
                </p>
              <p class="staff">
                {{appointment.staffName}} 
              </p>
              <p class="client">
                {{appointment.userName}}
              </p>
            </div>
            <div class="icons">
              <span class="material-symbols-outlined hidden" >
                 check_circle
              </span>
               <span [ngClass]="getIconClassRed(appointment.typeOfBA)"   (click)="sentId(appointment.id, 'ODBIJEN')">
                 cancel
                  </span>
              <span [ngClass]="getIconClassGreen(appointment.typeOfBA)" (click)="openTreatment(appointment.id)">
                 edit
              </span>
            </div>
          </div>
        </div>
    </section>
  
    <div class="confirm box" *ngIf="confirm" (click)="closePopUp()">
       <img src="../assets/icons/ToJeTo 1.png"  alt="">
       <p>Termin je potvrđen</p>
    </div>
    <div class="box cancel" *ngIf="cancel">
      <img src="../assets/icons/Upitnik 1.png"  alt="">
      <p>Da li ste sigurni da želite da otkažete termin?</p>
      <div class="button-box">
        <button class="discard-button" (click)="reject('ODBIJEN')">Otkaži</button>
        <button class="save-button" (click)="closePopUp()">Odustani</button>
      </div>
    </div>
    <div class="box cancel" *ngIf="confirmCancel" (click)="closePopUp()">
      <img src="../assets/icons/Tuzan.png"  alt="">
      <p>Termin je otkazan</p>
    </div>
    <div  class="overlay" *ngIf="confirm || cancel || confirmCancel" (click)="closePopUp()">
     </div>
</div>
