import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'wc-workpeople',
  templateUrl: './workpeople.component.html',
  styleUrls: ['./workpeople.component.css']
})
export class WorkpeopleComponent {

  allStaff: any;
  appointments: any;
  placeId: any;
  salonId: any;
  PlaceDTO: any;
  UserDTO: any;
  staffForm: FormGroup;
  showStaffForm: boolean = false;
  position: any;

  
  router:Router = inject(Router);
  activeRoute:ActivatedRoute = inject(ActivatedRoute);

  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService,
    private auth: AuthService
    ) {}



  ngOnInit() {

    
    this.staffForm = new FormGroup({ 
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      typeOfJob: new FormControl(null, Validators.required),
      phoneNumber: new FormControl(null, Validators.required),
      placeID: new FormControl(null),
      userRole: new FormControl(null),
    });

   


    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          console.log(this.UserDTO);
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeId = this.UserDTO.placeID;

            this.staffForm.get('placeID').setValue(this.placeId);
            this.staffForm.get('userRole').setValue('STAFF');
            this.auth.getSalon(this.placeId).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
                  console.log(this.PlaceDTO);
          
                  this.salonService.getStaff(this.placeId).subscribe({
                    next: (staffData) => {
                      this.allStaff = staffData;
                      console.log(this.allStaff);
                    },
                    error: (staffError) => {
                      console.error("nema zaposlenih", staffError);
                    }
                  });

                  this.salonService.getALLJobs().subscribe({
                    next: (data) => {
                      this.position = data;
                    },
                    error: (data) => {
              
                    }
                  });
    
                } else {
                  console.warn();
                }
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });
   

  }

  onFileChange(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.uploadFiles(files);
    }
  }
  
  uploadFiles(files: File[]) {
  

  }


  transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
  }

  addStaff(){
    this.showStaffForm = true;
  }

  transformBack(word: string): string {
    return word.toUpperCase().replace(/ /g, '_');
    }

  onSubmit() {
    console.log(this.staffForm);
    if (this.staffForm.valid) {
      console.log(this.staffForm);
      const originalValue = this.staffForm.get('typeOfJob').value;
      this.staffForm.get('typeOfJob').setValue(originalValue);
       this.salonService.addStaff(this.staffForm.value)
         .subscribe({
           next: (res) => {
             console.log(res);
             window.location.reload();
           },
           error: (err) => {
             alert(err?.error.message);
           }
         });
      }else{
        console.warn('Greska');
      }
     
    }

    resetForm() {
      this.staffForm.reset();
      this.showStaffForm = false;
    }
}
