<div class="information">
    <p class="info-title">Cena i trajanje</p>
    <p class="info-description">Dodajte cenu usluge i njeno trajanje</p>
    <div class="form-div">
      <form class="form-time" [formGroup]="step3Form" >
        <div class="input-div">
            <label for="">Vreme trajanja</label>
            <select class="select" formControlName="time" (change)="selectTime($event)">
                <option class="option" hidden disabled selected value></option>
                <option class="option" value="15">15 min</option>
                <option class="option" value="30">30 min</option>
                <option class="option" value="45">45 min</option>
                <option class="option" value="60">60 min</option>
                <option class="option" value="75">1 h 15 min</option>
                <option class="option" value="90">1 h 30 min</option>
                <option class="option" value="105">1 h 45 min</option>
                <option class="option" value="120">2 h</option>
                <option class="option" value="135">2 h 15 min</option>
                <option class="option" value="150">2 h 30 min</option>
                <option class="option" value="165">2 h 45 min</option>
                <option class="option" value="180">3 h</option>
                <option class="option" value="195">3 h 15 min</option>
                <option class="option" value="210">3 h 30 min</option>
                <option class="option" value="225">3 h 45 min</option>
                <option class="option" value="240">4 h</option>
              </select>
        </div>
        <div class="price">
            <div class="input-div">
                <label for="">Tip cene</label>
                <select class="select" id="" name="" form="" >
                    <option class="option" value="" selected value>Fiksna</option>
                    <option class="option" value=""></option>
                  </select>
            </div>
            <div class="input-div">
                <label for="">Cena</label>
                <select class="price-select" formControlName="price"   (change)="selectPrice($event)">
                    <option class="option" hidden disabled selected value></option>
                    <option class="option" value="200">200</option>
                    <option class="option" value="300">300</option>
                    <option class="option" value="400">400</option>
                    <option class="option" value="500">500</option>
                    <option class="option" value="600">600</option>
                    <option class="option" value="700">700</option>
                    <option class="option" value="800">800</option>
                    <option class="option" value="900">900</option>
                    <option class="option" value="1000">1000</option>
                    <option class="option" value="1100">1100</option>
                    <option class="option" value="1200">1200</option>
                    <option class="option" value="1300">1300</option>
                    <option class="option" value="1400">1400</option>
                    <option class="option" value="1500">1500</option>
                    <option class="option" value="1600">1600</option>
                    <option class="option" value="1700">1700</option>
                    <option class="option" value="1800">1800</option>
                    <option class="option" value="1900">1900</option>
                    <option class="option" value="2000">2000</option>
                  </select>
            </div>
        </div>
        <div class="addtime">
            <p class="paragraph">Dodatno vreme</p>
            <p class="secparagraph">Dodajte dodatno vreme ukoliko je potrebno nakon termina</p>
            <div class="switch-time">
            <label class="switch">
                <input type="checkbox" (change)="onCheckboxChange($event)" > 
                <span class="slider"></span>
            </label>
            <select class="select" formControlName="extraTime" [hidden]="!showDiv" (change)="selectExtraTime($event)">
                <option class="option" hidden disabled selected value=""></option>
                <option class="option" value="10">10 min</option>
                <option class="option" value="15">15 min</option>
                <option class="option" value="20">20 min</option>
                <option class="option" value="25">25 min</option>
                <option class="option" value="30">30 min</option>
                <option class="option" value="35">35 min</option>
                <option class="option" value="40">40 min</option>
                <option class="option" value="45">45 min</option>
                <option class="option" value="50">50 min</option>
                <option class="option" value="55">55 min</option>
                <option class="option" value="60">60 min</option>
              </select>
            </div>
        </div>
        <div class="btns">
          <button (click)="closeTreatment()" class="btn-back">
           Odustani
          </button>
          <button type="submit" (click)="sentForm()" class="btn-next">
           Sačuvaj
          </button>
         </div>
      </form>
    </div>
  </div>