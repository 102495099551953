<div class="main" #scroll>
    <section class="header">
      <app-header></app-header>
    </section>  
      <section class="section1">
          <p>Upoznajte Clicko</p>
          <h1>Novi <span class="green">alat</span> za <span class="red">zakazivanje</span></h1>
          <button (click)="signupPartner()">Postani Clicko Partner</button>
      </section>
      <section class="section2">
      <app-faq></app-faq>
      </section>
      <section class="section3">
          <img src="../assets/icons/ClickoFlajer2 1.png" alt="">
      </section>
      <section class="section4">
        <h2>Clicko Partner paketi</h2>
        <p class="info">Odaberite paket koji vam najviše odgovara</p>
        <div class="slider">
            <input type="radio" name="slider" id="slide1" checked>
            <input type="radio" name="slider" id="slide2">
            <input type="radio" name="slider" id="slide3">
            <div class="package-wrapper">
             
                <div class="package package1">
                  <div class="name">
                    <p class="name-clicko">Clicko</p>
                    <h1 class="name-package">Start</h1>
                    <p class="name-info">Za male privatne salone</p>
                  </div>
                  <div class="price">
                    <div class="price-div">
                      <div class="green-div">2 000 RSD</div>
                      <p>mesečno</p>
                    </div> 
                    <p>ili 1500 RSD sa godišnjom pretplatom</p>
                   </div>
                  <button (click)="signupPartner()">
                       Postani Clicko Partner
                  </button>
             <div class="text">
                <p class="title">Paket obuhvata</p>
                 <div class="list">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                        <p>Neograničen broj usluga</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                        <p>Neograničen broj mesečnih zakazivanja</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                        <p>Jedan zaposleni</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                        <p>E-mail obaveštenja</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                        <p>Privatni profil</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                        <p>Booking kalendar</p>
                    </div>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                      <p>Samostalno unošenje usluga</p>
                    </div>
                 </div>
             </div>
          </div>
          <div class="package package2" >
            <div class="popular">
              Najpopularnije
            </div>
            <div class="name">
                <p class="name-clicko">Clicko</p>
                <h1 class="name-package">Standard</h1>
                <p class="name-info">Za male privatne salone</p>
            </div>
            <div class="price">
                <div class="price-div">
                   <div class="green-div">5% od termina</div>
                   <p>mesečno</p>
                </div> 
                <p>ili 1500 RSD sa godišnjom pretplatom</p>
            </div>
            <button (click)="signupPartner()">
              Postani Clicko Partner
            </button>
            <div class="text">
               <p class="title">Sve iz Clicko Start, plus</p>
                <div class="list">
                   <div>
                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                       <p>Viber i sms obavestenje</p>
                   </div>
                   <div>
                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                       <p>Neograničen broj radnika</p>
                   </div>
                   <div>
                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                       <p>Javni profil</p>
                   </div>
                   <div>
                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                       <p>Clicko stiker sa QR kodom</p>
                   </div>
                   <div>
                       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                       <p>Analitika</p>
                   </div>
                </div>
            </div>
         </div>
         <div class="package package3" >
          <div class="name">
              <p class="name-clicko">Clicko</p>
              <h1 class="name-package">Premium</h1>
              <p class="name-info">Za male privatne salone</p>
          </div>
          <div class="price">
              <div class="price-div">
                 <div class="green-div">4 000 RSD</div>
                 <p>mesečno</p>
              </div> 
              <p>ili 1500 RSD sa godišnjom pretplatom</p>
          </div>
          <button (click)="signupPartner()">
            Postani Clicko Partner
          </button>
          <div class="text">
             <p class="title">Sve iz Clicko Standard, plus</p>
              <div class="list">
                 <div>
                     <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                     <p>Podrska 24/7</p>
                 </div>
                 <div>
                     <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                     <p>Istaknutost pri pretrazi</p>
                 </div>
                 <div>
                     <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#21211F"><path d="M382-232.35 146.35-468l64.89-64.89L382-362.13l366.76-366.76L813.65-664 382-232.35Z"/></svg>
                     <p>Usluzno unosenje usluga</p>
                 </div>
                   </div>
                </div>
              </div>
            </div>
      
         </div>
         <div class="dots">
            <label for="slide1" id="dot1"></label>
            <label for="slide2" id="dot1"></label>
            <label for="slide3" id="dot1"></label>
         </div>
     </section>
      <section class="cards-section">
        <div class="display" >
            <div class="background">
              <p>Zašto postati Clicko</p>
              <h1>Partner</h1>
            </div>
            <div class="col">
                <div class="cards">
                    <article [ngClass]="{'expanded': isExpanded1, 'in-frame': isArticleInFrame(1)}">
                        <h2>01</h2>
                        <h5>Onlajn profil na Clicko platformi =</h5>
                        <p *ngIf="isExpanded1 && isParagraphVisible(1)">Hiljade potencijalnih klijenata u vašoj okolini</p>
                        <span class="material-symbols-rounded" (click)="toggleArticle(1)"[ngClass]="{'rotated': isExpanded1}">
                            arrow_outward
                        </span>
                    </article>
                    <article [ngClass]="{'expanded': isExpanded2, 'in-frame': isArticleInFrame(2)}">
                        <h2>02</h2>
                        <h5>Onlajn profil na Clicko platformi =</h5>
                        <p *ngIf="isParagraphVisible(2) && isExpanded2">Hiljade potencijalnih klijenata u vašoj okolini</p>
                        <span class="material-symbols-rounded" (click)="toggleArticle(2)" [ngClass]="{'rotated': isExpanded2}">
                            arrow_outward
                        </span>
                    </article>
                    <article [ngClass]="{'expanded': isExpanded3, 'in-frame': isArticleInFrame(3)}">
                        <h2>03</h2>
                        <h5>Onlajn profil na Clicko platformi =</h5>
                        <p *ngIf="isParagraphVisible(3) && isExpanded3">Hiljade potencijalnih klijenata u vašoj okolini</p>
                        <span class="material-symbols-rounded" (click)="toggleArticle(3)" [ngClass]="{'rotated': isExpanded3}">
                          arrow_outward
                      </span>
                    </article>
                    <article class="last">
                        <img src="../assets/icons/Like_Svetle_Senke 1.png" alt="3D MODEL">
                    </article>
                          
                  </div>
                
             </div>
              <button class="btn-partnerinfo" (click)="signupPartner()" >Postani Clicko Partner</button>
          </div> 
       </section>
       <section class="section-7">
        <div class="mail">
           <div>
          <p>Ukoliko imate dodatnih pitanja ili vam se ćaska sa nama, pišite nam</p>
          <button><a class="link" href="mailto:hi@clicko.rs">hi&#64;clicko.rs</a></button>
           </div>
            <img src="../assets/icons/TV3_01 3.png" alt="">
        </div>  
     </section>
       <a  class="to-top" #toTop>
           <span class="material-symbols-outlined" (click)="tothetop()">
             arrow_upward_alt
           </span>
       </a>
      
       <section class="section6">
         <app-footer></app-footer>
       </section>
       
  </div>