<div class="flex">
  <p *ngIf="allAccepted.length === 0">Trenutno nema potvrđenih termina</p>
    <div class="appointment-div" *ngIf="allAccepted.length > 0">
        <div class="appointment-confirmed" *ngFor="let appointment of allAccepted">
          <div class="treatment-div">
            <p class="treatment">{{appointment.appointmentName}}</p>
            <p class="time">
             {{  formatTime(appointment.time,appointment.durationTime)}}
            </p>
            <p class="time">
            {{ dateTranform(appointment.date) }}
            </p>
            <p class="staff">
              {{appointment.staffName}} 
            </p>
         </div>
            <div class="middle">
              <p class="time">
                {{  formatTime(appointment.time,appointment.durationTime)}}
              </p>
              <p class="time">
                {{ dateTranform(appointment.date) }}
                </p>
              <p class="staff">
                {{appointment.staffName}} 
              </p>
              <p class="client">
                {{appointment.userName}}
              </p>
            </div>
            <div class="icons">
                  <span class="material-symbols-outlined hidden">
                  check_circle
                  </span>
                  <span class="material-symbols-outlined red" (click)="sentId(appointment.id, 'ODBIJEN')">
                   cancel
                  </span>
                  <span class="material-symbols-outlined green" (click)="openTreatment(appointment.id)">
                   edit
                  </span>  
            </div>
    </div>
   <div class="box cancel" *ngIf="cancel">
     <img src="../assets/icons/Upitnik 1.png"  alt="">
     <p>Da li ste sigurni da želite da otkažete termin?</p>
     <div class="button-box">
       <button class="discard-button" (click)="reject('ODBIJEN')">Otkaži</button>
       <button class="save-button" (click)="closePopUp()">Odustani</button>
     </div>
   </div>
   <div class="box cancel" *ngIf="confirmCancel" (click)="closePopUp()">
     <img src="../assets/icons/Tuzan.png"  alt="">
     <p>Termin je otkazan</p>
   </div>
   <div  class="overlay" *ngIf="confirm || cancel || confirmCancel" (click)="closePopUp()">
    </div>
    <router-outlet></router-outlet>
</div>
