<div class="main">
    <div class="btn-div">
        <button (click)="goBack()" class="close-btn">
          <span class="material-symbols-outlined">
            close
          </span>
        </button>
    </div>
    <div class="flex">
    <section class="service">
    <div class="all-categories">
      <div class="categories-name" *ngFor="let categories of appointmentCategoryPlaceDTO.categories">
        <h4 >{{ transformWord(categories) }}</h4>
        <div class="all-treatments" >
           <ng-container *ngFor="let appointment of appointments">
           <div class="treatment" *ngIf="transformWord(appointment.category) === transformWord(categories)">
               <div class="text">
                   <p class="full-name"> {{ appointment.name }}</p>
                   <p class="grey"  *ngIf="appointment.time">{{ appointment.time }}min</p>
                   <p class="price" *ngIf="appointment.price">{{ appointment.price }} RSD</p>
               </div>
                     <span class="material-symbols-outlined" (click)="addTreatment(appointment)">
                         add
                     </span>
                 </div>  
           </ng-container>
          </div>
        </div>
     </div>
     </section>
    </div>
    
</div>