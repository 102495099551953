import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { General } from 'src/app/partner/page/model/general';

const baseURL = "http://localhost:3000";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private httpClient: HttpClient) { }

  create(general: General): Observable<any> {
    return this.httpClient.post(baseURL, general);
  }
}
