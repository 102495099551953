<div class="box">
   <div class="photos">
        <div class="addphoto">
            <span class="material-symbols-outlined">
                cloud_upload
            </span>
            <div class="file-upload">
              <input type="file" (change)="onFileChange($event)" id="fileInput" />
              <label for="fileInput" class="custom-button">Dodaj fotografije</label>
            </div>
        </div>
        <div class="photo" *ngFor="let photoId of PlaceDTO.photoIDs">
          <img *ngIf="photoId" [src]="getPhotoUrl(photoId)" alt="PlacePhoto">
          <div class="delete" (click)="openPopUp(photoId)">
             <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#F6F1ED"><path d="M304.62-160q-26.85 0-45.74-18.88Q240-197.77 240-224.62V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.38q0 27.62-18.5 46.12Q683-160 655.38-160H304.62ZM680-720H280v495.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h350.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-720ZM392.31-280h40v-360h-40v360Zm135.38 0h40v-360h-40v360ZM280-720v520-520Z"/></svg>
          </div>
        </div>
    </div>
    <div class="deletePopUp" *ngIf="deletePopUp">
      <p>Da li ste sigurni da želite da obrišete sliku?</p>
      <div class="button-box">
        <button class="discard-button" (click)="deleteImage()">Obriši</button>
        <button class="save-button" (click)="closePopUp()">Odustani</button>
      </div>
    </div>
    <div  class="overlay" *ngIf="deletePopUp" (click)="closePopUp()">
    </div>
</div>

  
