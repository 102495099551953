<div class="box">
    <div class="staff-box">

            <button type="button"  class="add-btn" (click)="addStaff()">
                <span class="material-symbols-outlined btnicon">
                    add
                </span>
                <p>
                   Dodaj
                </p>
            </button>
            <form [formGroup]="staffForm" (ngSubmit)="onSubmit()" *ngIf="showStaffForm">
                <div class="staff-information" >
                     <div class="image">
                          <span class="material-symbols-outlined">
                              cloud_upload
                          </span>
                          <div class="file-upload">
                            <input type="file" (change)="onFileChange($event)" id="fileInput" formControlName="profilePhoto"/>
                            <label for="fileInput" class="custom-button"></label>
                          </div>
                    </div>
                    <div class="staff-information-div">
                      <label class="staff-heading">Ime  </label>
                      <input class="staff-info" formControlName="firstName">
                   </div>
                   <div class="staff-information-div">
                      <label class="staff-heading">Prezime </label>
                      <input class="staff-info" formControlName="lastName">
                   </div>
                  <div class="staff-information-div">
                      <label class="staff-heading">Email</label>
                     <input class="staff-info" formControlName="email">
                  </div>
                  <div class="staff-information-div ">
                      <label class="staff-heading">Broj telefona</label>
                      <input class="staff-info" formControlName="phoneNumber">
                   </div>
                  <div class="staff-information-div">
                    <label class="staff-heading" for="typeOfJob">Pozicija</label>
                    <select class="select-staff-info"   id="typeOfJob" formControlName="typeOfJob" >
                        <option class="option" hidden disabled selected></option>
                        <option  class="option" *ngFor="let pos of position" [value]="transformBack(pos)">{{ transformWord(pos) }} </option>
                    </select>
                </div>
                    </div> 
      
                    <div class="button-box" > 
                      <button type="button" class="discard-button" (click)="resetForm()">Odustani</button>
                      <button type="submit" class="save-button">Sačuvaj</button> 
                      </div>  
                 
                  </form> 
        
       <div class="staff-information" *ngFor="let staff of allStaff">
            <div class="div-name"> 
                <div class="image">
                     <img class="img" src="{{ staff.profilePhoto }}" *ngIf="staff.profilePhoto">
                     <p class="initial" *ngIf="!staff.profilePhoto"> {{ staff.firstName?.slice(0, 1) }}</p>
                </div>
                <p class="staff-info">{{ staff.firstName }} {{ staff.lastName }}</p>
            </div>
            <div class="div-info">
                <p class="staff-info">{{ staff.email}}</p>
                <p class="staff-info">{{ staff.telephone }}</p>
                <p class="staff-info position">{{ transformWord(staff.jobPosition)}}</p>
            </div>  
        </div>
          
    
<!--   
       <div class="worktime">
        <div class="date-div">
            <h3>Radno vreme zaposlenog</h3>
            <div class="date">
                <div class="day">
                    <span class="material-symbols-outlined">
                    calendar_month
                    </span>
                    <p>Danas</p>
                </div>
                <div class="week">
                  <p>23 - 30 August 2023</p>
                </div>
                <div class="arrow">
                    <span class="material-symbols-outlined">
                        arrow_left_alt
                    </span>
                    <span class="material-symbols-outlined">
                        arrow_right_alt
                    </span>
                </div>
        </div>
    </div>
            <div class="title-div">
               <p>Zaposleni</p>
               <p>Pon [datum]</p>
               <p>Ut [datum]</p>
               <p>Sre [datum]</p>
               <p>Cet [datum]</p>
               <p>Pet [datum]</p>
               <p>Sub [datum]</p>
               <p>Ned [datum]</p>
            </div>
            <div class="staff-div" *ngFor="let staff of allStaff">
               <p>{{ staff.firstName }} {{ staff.lastName }}</p>
            </div>
        </div> -->
       </div>

</div>
   
   