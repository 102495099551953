import { AppointmentDTO } from "./AppointmentDTO";
import { UserDTO } from "./UserDTO";

export class BookAppointmentDTO{
    appointmentID: number;
    userID: number;
    date: string;
    staffID: number;
    time: string;
    attended: Attended;
    typeOfBA: TypeOfBA;
    canChange: boolean;
}

export class Attended{
    static ARRIVED = 'ARRIVED'; 
    static CANCELED = 'CANCELED';
    static NOT_ARRIVED = 'NOT_ARRIVED';
    static NULL = 'NULL';
  }

  export class TypeOfBA{
    static NA_CEKANJU = 'NA_CEKANJU'; 
    static ODBIJEN = 'ODBIJEN';
    static PRIHVACEN = 'PRIHVACEN';
  }