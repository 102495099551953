  <nav>
    <ul class="nav-list">
      <div class="overlay" *ngIf="isMenuVisible" (click)="hideMenu()"></div>
      <li class="nav-item">
        <div class="logo" (click)="navigatetoHomepage()">
           <img src="../assets/icons/Clicko Logo.svg" alt=""> 
         </div>
         <div class="vector" (click)="toggleMenu()">
          <img  src="../assets/icons/Group.png" alt="Icon" >
         </div>
           <div class="menu" *ngIf="isMenuVisible">
           <div class="exit">
             <img src="../assets/icons/Clicko Logo.svg" alt="" (click)="navigatetoHomepage()"> 
             <span class="material-symbols-outlined" (click)="hideMenu()">
               close
             </span>
           </div>
           <ul class="menu-list">
            <li class="menu-item" (click)="hideMenu()">
              <a routerLink="calendar" routerLinkActive="active">
                <span class="material-symbols-outlined" >
                  calendar_month
                </span>
                Kalendar
              </a>
            </li>
            <li class="menu-item" (click)="hideMenu()">
              <a routerLink="page" routerLinkActive="active">
                <span class="material-symbols-outlined">
                  home
                </span>
                Profil
              </a>
            </li>
            <li class="menu-item" (click)="hideMenu()">
              <a routerLink="appointments" routerLinkActive="active">
                <span class="material-symbols-outlined">
                    check_circle
                </span>
                Termini
              </a>
            </li>
            
            <li class="menu-item" (click)="hideMenu()">
              <a routerLink="clients" routerLinkActive="active" >
                <span class="material-symbols-outlined">
                  clinical_notes
                  </span>
                  Klijenti
              </a>
            </li>
            <li class="menu-item" (click)="hideMenu()">
              <a routerLink="settings" routerLinkActive="active">
                <span class="material-symbols-outlined">
                  settings
                  </span>
                  Podešavanja
              </a>
            </li>
            <li class="menu-item disabled">
              <a href="" routerLinkActive="active">
                <span class="material-symbols-outlined">
                  bid_landscape
                  </span>
                  Analitika
              </a>
            </li>
            <li class="menu-item disabled">
              <a href="" routerLinkActive="active">
                <span class="material-symbols-outlined">
                  award_star
                  </span>
                  VIP
              </a>
            </li>
          </ul>
          </div>
      </li>
      <!-- <li class="nav-item">
        <a href="">
          <span class="material-symbols-outlined">
            notifications
            </span>
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <div class="image">
          <img class="profile-picture" src="../assets/icons/Layer 2 2.png" alt="">
        </div>
      </li> -->
      <li class="dropdown meni" >
        <button  class="dropbtn" (click)="toggleDropdown('dropdown1')" >
            <p>Meni </p>
           <img src="../assets/icons/expand_more.png" alt="" class="arrow" >
        </button>
        <div *ngIf="isDropdownOpen1" id="myDropdown1" class="dropdown-content">
        <div class="flex">
            <img src="../assets/icons/Clicko Logo.svg" alt="" (click)="navigatetoHomepage()">
            <span class="material-symbols-outlined" (click)="closeMenu()">
                    close
            </span>
        </div>
        <div class="box" (click)="navigatetoPage()" >
            <p>Profil</p>
        </div>
        <div class="box green" (click)="logout()" >
            <p>Odjavi me</p>
        </div>
        </div>
       </li>
    </ul>
</nav>
