import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-client-appointment',
  templateUrl: './client-appointment.component.html',
  styleUrls: ['./client-appointment.component.css']
})
export class ClientAppointmentComponent {
  router:Router = inject(Router);
  UserDTO: any;
  appointments: any[] = [];
  acceptedAppointments: any[] = [];
  notAcceptedAppointments: any[] = [];
  pendingAppointments: any[] = [];
  pendingAppointmentsPast: any[] = [];
  pastAppointments: any[] = [];
  appointmentId: any;

  appointmentsDetails: any[] = [];

  currentTime = new Date();

  cancel:boolean = false;
  confirmCancel: boolean = false;
 
  constructor(private auth: AuthService, 
    private salonService: SalonService,
    private userService: UserService){}

  ngOnInit(){



    this.auth.getUser().subscribe(
      (data) => {
        if (data) {
          this.UserDTO = data;

        
          this.userService.getAppointments(this.UserDTO.id)
           .subscribe(appointments => {
            this.appointments = appointments;

            const currentTime = new Date();

            this.pastAppointments = this.appointments.filter(appointment => {
              const appointmentDate = this.convertDate(appointment.date);
              return new Date(appointmentDate).getTime() < currentTime.getTime();
          });

         
          
        
          this.pendingAppointments = this.appointments.filter(appointment => {
            const appointmentDate = this.convertDate(appointment.date);
            return new Date(appointmentDate).getTime() > currentTime.getTime();
        });
    
            this.acceptedAppointments = this.pendingAppointments.filter(appointment => appointment.typeOfBA === "PRIHVACEN");
            this.notAcceptedAppointments = this.pendingAppointments.filter(appointment => appointment.typeOfBA === "NA_CEKANJU");
 


        // console.log('svi:', this.appointments); 
        // console.log('prihvaceni:' ,this.acceptedAppointments); 
        // console.log('na cekanju:', this.pendingAppointments); 
        // console.log( 'prosli:', this.pastAppointments); 
       
      });
        } else {
          console.warn("");
        }
      },
      (error) => {
        console.error("", error);
      }
    );


 
  }

  convertDate(inputDate: string): string {
    const parts = inputDate.split('-');
    const date = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
    const currentTime = date.toDateString();
    return currentTime;
  }

  getMonthName(dateTime: string): string {
    const parts = dateTime.split("-"); 
    const day = parseInt(parts[0], 10); 
    const monthIndex = parseInt(parts[1], 10) - 1; 
    const monthNames = ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'okt', 'nov', 'dec'];
    return monthNames[monthIndex];
  }

  getDay(dateTime: string): number {
    const [day, ,] = dateTime.split('-');
    return Number(day);

  }

   getDayOfWeek(getdate: string): string {
    const parts = getdate.split("-"); 
    const day = parseInt(parts[0], 10); 
    const month = parseInt(parts[1], 10) - 1; 
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day); 

    const daysOfWeek = ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'];
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  
  formatDate(dateTime: string): string {
    const [day, month, year] = dateTime.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
  }


  formatDateForPast(dateTime: Date): string {
    const date = new Date(dateTime);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return day + '-' + month + '-' + year;
  }

  formatTime(startTime: string, duration: number): string {
    let startHours: number;
    let startMinutes: number;
  
    if (typeof startTime === 'string') {
      const parts = startTime.split(':');
      startHours = parseInt(parts[0], 10);
      startMinutes = parseInt(parts[1], 10);
    } else if (typeof startTime === 'number') {
      startHours = Math.floor(startTime / 60);
      startMinutes = startTime % 60;
    } else {
      return '';
    }
  
    let endHours = startHours;
    let endMinutes = startMinutes + duration;
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes = endMinutes % 60;
    }
  
    return `${startHours}:${startMinutes < 10 ? '0' + startMinutes : startMinutes} - ${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`;
  }

  sentId(id: number, typeOfBA: string){
    this.appointmentId = id;
    this.cancel = true;
  }

  reject( typeOfBA: string): void {
    const id = this.appointmentId;
    this.salonService.rejectAppointment( id, typeOfBA)
      .subscribe(
        (response) => {
          console.log(response);
          
        },
        (error) => {
          console.error( error);
        }
      );

      this.cancel = false;
      this.confirmCancel = true;
  
  }

  closePopUp(){
    this.cancel = false;
    this.confirmCancel = false;
  }

}
