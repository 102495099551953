import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent {
  router:Router = inject(Router);

  selectedReason: string;

  constructor(private auth: AuthService){}

  navigateBack(){
    this.router.navigate(['client/client-info']);
  }

  onDeleteUser() {
    this.auth.deleteUser().subscribe(
      () => {
        console.log('Selected reason:', this.selectedReason);
        this.router.navigate(['signup']);
      },
      error => {
        console.error('Error deleting user', error);
      }
    );
  }
}
