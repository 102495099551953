import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-schedule-a-appointment',
  templateUrl: './schedule-a-appointment.component.html',
  styleUrls: ['./schedule-a-appointment.component.css']
})
export class ScheduleAAppointmentComponent {
  router:Router = inject(Router);
  weekDate: string;

  PlaceDTO: any;

  salonId: any;

  appointmentIDs: number[] = [];
  appointments: any[] = [];
  categories: any[] = [];


  
  constructor(private salonService: SalonService,
    private auth: AuthService,
     private location: Location,
     private route: ActivatedRoute) {
 }

 ngOnInit(): void {

  console.log(this.route.snapshot.paramMap.get('id')); 
  this.route.params.subscribe(params => {
    this.salonId = params['id'];
    console.log(this.salonId);
 
  this.salonService.getPlaceDetails(this.salonId).subscribe(
    (data) => {
        this.PlaceDTO = data;
        console.log(this.PlaceDTO);
    
        if (this.PlaceDTO && this.PlaceDTO.appointmentIDs) {
          this.appointmentIDs = this.PlaceDTO.appointmentIDs;
          console.log(this.appointmentIDs);
          this.getTreatmentDetails(this.appointmentIDs);
        }
    },
    (error) => {
      console.error("", error);
    }
  );
 });


}

getTreatmentDetails(appointmentIDs: number[]): void {
  for (const id of appointmentIDs) {
    this.salonService.getAppointments(id).subscribe((appointment) => {
      this.appointments.push(appointment);
      console.log(appointment);
    });
  }
}


getTreatmentName(appointmentId: number): string {
  const appointment = this.appointments.find(app => app.id === appointmentId);
  return appointment ? appointment.name : '';
}

getTreatmentPrice(appointmentId: number): number {
  const appointment = this.appointments.find(app => app.id === appointmentId);
  return appointment ? appointment.price : '';
}

getTreatmentTime(appointmentId: number): number {
  const appointment = this.appointments.find(app => app.id === appointmentId);
  return appointment ? appointment.time : 0;
}

getTreatmentId(appointmentId: number): number {
  const appointment = this.appointments.find(app => app.id === appointmentId);
  return appointment ? appointment.id : 0;
}


addTreatment(appointment: any, checkDuplicate: boolean = true) {
  const treatmentId = appointment.id;

      const treatment = {
          id: this.getTreatmentId(treatmentId),
          name: this.getTreatmentName(treatmentId),
          time: this.getTreatmentTime(treatmentId),
          price: this.getTreatmentPrice(treatmentId),
          
      };
     
      this.salonService.addTreatment(treatment);

} 

get selectedTreatments() {
return this.salonService.selectedTreatment;
}

get formattedTotalTime() {
return this.salonService.formattedTotalTime;
}

get totalTime() {
return this.salonService.totalTime;
}

get totalPrice() {
return this.salonService.totalPrice;
}

goBack(){
  this.location.back();
}

bookAppointment(){
  if (this.auth.isLoggedIn()) {
  
 
  } else {
   
    this.router.navigate(['/login']);
  }
}


}
