import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'wc-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent {
  PlaceDTO: any;
  UserDTO: any;
  placeID:any;

  constructor(
 
    private auth: AuthService) { 

}
 
  ngOnInit(): void {

  

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
      
        
                } else {
                  console.warn();
                }
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });

  }
}
