import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent {
  router:Router = inject(Router);
  
  PlaceDTO: any;
  salonId: any;
  photos: any[] = [];


  constructor(
      private salonService: SalonService,
      private route: ActivatedRoute,
      private auth: AuthService,
      private location: Location
      ) {}

  ngOnInit(): void {
    
    this.salonId = this.route.snapshot.paramMap.get('id');
    console.log(this.salonId);
    this.salonService.getPlaceDetails(this.salonId).subscribe(
      (data) => {
          this.PlaceDTO = data;
          console.log(this.PlaceDTO);
          if (this.PlaceDTO && this.PlaceDTO.photoIDs) {
            this.getPhotos(this.PlaceDTO.photoIDs);
            console.log(this.PlaceDTO.photoIDs)
          }
      },
      (error) => {
        console.error("", error);
      }
    );

    console.log(this.photos);
    }


  getPhotos(photoId: number[]): void {
    for (const photoId of this.PlaceDTO.photoIDs) {
      this.auth.getPhoto(photoId).subscribe(photo => {
        this.photos.push(photo);
      });
    }
  }

  getPhotoUrl(photoId: number): string {
    const photo = this.photos.find(app => app.id === photoId);
    return photo ? photo.url : '';
  }

  goBack(){
    this.location.back();
  }

  // getDimensions(photoId: number): Promise<{ width: number; height: number }> {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.src = this.getPhotoUrl(photoId);
  
  //     img.onload = () => {
  //       resolve({ width: img.width, height: img.height });
  //     };
  
  //     img.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // }
  
  // async calculateImageDimensions(photoId: number): Promise<any> {
  //   try {
  //     const { width, height } = await this.getDimensions(photoId);
  
  //     if (width / height === 1280 / 490) {
  //       return { width: '1280px', height: '490px' };
  //     } else if (width / height === 624 / 490) {
  //       return { width: '624px', height: '490px' };
  //     } else {
  //       console.warn(`${photoId}`);
  //       return null; 
  //     }
  //   } catch (error) {
  //     console.error(`${photoId}: ${error}`);
  //     return null; 
  //   }

// }

}