import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClientsService } from 'src/app/services/partner-services/clients.service';
import { CommonModule, Location } from '@angular/common';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'wc-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent {
  clientId:any;
  client: any;
  PlaceDTO: any;
  UserDTO: any;
  placeID:any;
  history: any;
 

  constructor(
    private clientService: ClientsService,
    private location: Location,
    private userService: UserService,
    private salonService: SalonService,
    private auth: AuthService,
    private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {

      this.auth.getUser().subscribe({
        next: (data) => {
          if (data) {
            this.UserDTO = data;
            if (this.UserDTO && this.UserDTO.placeID) {
              this.placeID = this.UserDTO.placeID;
              this.auth.getSalon(this.placeID).subscribe({
                next: (data) => {
                  if (data) {
                    this.PlaceDTO = data;
    
                   
        this.userService.getUserId(this.clientId).subscribe({
          next: (data) => {
              this.client = data;
                },
         error: (error) => {
              console.error( error);
             }
        });

        this.salonService.getClientHistory(this.PlaceDTO.id, this.clientId).subscribe({
          next: (data) => {
              this.history = data;
              console.log(this.history)
                },
         error: (error) => {
              console.error( error);
             }
        });
          
                  } else {
                    console.warn();
                  }
                },
                error: (salonError) => {
                  console.error( salonError);
                }
              });
            } else {
              console.warn();
            }
          } else {
            console.warn();
          }
        },
        error: (userError) => {
          console.error( userError);
        }
      });
     
       this.clientId = this.route.snapshot.paramMap.get('id');
      

  
    }

    formatDate(dateString: string): string {
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAJ', 'JUN', 'JUL', 'AVG', 'SEP', 'OKT', 'NOV', 'DEC'];
  
      const [day, month, year] = dateString.split('-');
      const monthIndex = parseInt(month, 10) - 1;
  
      return `${day} ${months[monthIndex]} ${year}`;
    }

    
  
    closeClient() {
      this.location.back();
      this.clientService.closeClient();
    }
 
  
}
