import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component { 

  salonId: any;
  placeID: any;
  PlaceDTO: any;
  UserDTO: any;
  price: any;
  time:any;
  extraTime: any;
  
  step3Form: FormGroup;
  showDiv: boolean = false;

  @Input() formData: any;

  @Output() onNext: EventEmitter<any> = new EventEmitter();
  @Output() onBack: EventEmitter<any> = new EventEmitter();
   
  constructor( private salonService: SalonService,
    private auth: AuthService,
    private treatmentsService: TreatmentsService,) {}

    ngOnInit() {
    this.step3Form = new FormGroup({ 
      time: new FormControl(''),
      price: new FormControl(''),
      extraTime: new FormControl(''),
    })
   
    this.showDiv = false;

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                this.PlaceDTO = data;
                console.log(this.PlaceDTO);
                } else {
                  console.warn();
                }
              }
            });
          }
        }
      }
    });


  }
  

  selectPrice(event: Event) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    this.price = value;
  }
  selectTime(event: Event) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    this.time = value;
  }
  selectExtraTime(event: Event) {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    this.extraTime = value;
  }


  receiveFormData(formData: any) {
    this.formData = formData;
  }
  
  sentForm(){
    if (this.step3Form.valid) {
    
      this.formData.price = this.price;
      this.formData.extraTime= this.extraTime;
      this.formData.time = this.time;
      this.formData.placeDTO = this.PlaceDTO;
     console.log(this.formData);
      this.salonService.makeAppointment(this.formData)
      .subscribe({
        next:(res)=>{
         console.log(res);
         window.location.reload();
        },
        error:(err)=>{
          alert(err?.error.message)
        }
       });
    }
   
  }

  goBack() {
    this.onBack.emit();
  }

  onCheckboxChange(event) {
 
    if (event.target.checked) {
        this.showDiv = true;
    } else {
        this.showDiv = false;
    }
  
  }

  closeTreatment() {
    this.treatmentsService.closeTreatment();
  }


 
}
