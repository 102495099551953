import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { GeneralComponent } from './partner/page/general/general.component';
import { PhotoComponent } from './partner/page/photo/photo.component';
import { TreatmentsComponent } from './partner/page/treatments/treatments.component';
import { PageComponent } from './partner/page/page.component';
import { ClientsComponent } from './partner/clients/clients.component';
import { AppComponent } from './app.component';
import { ClientsListComponent } from './partner/clients/clients-list/clients-list.component';
import { DetailsComponent } from './partner/clients/clients-list/client-details/details/details.component';
import { AppointmentComponent } from './partner/clients/clients-list/client-details/appointment/appointment.component';
import { VoucherComponent } from './partner/clients/clients-list/client-details/voucher/voucher.component';
import { RatingsComponent } from './partner/clients/clients-list/client-details/ratings/ratings.component';
import { ClientDetailsComponent } from './partner/clients/clients-list/client-details/client-details.component';
import { AllComponent } from './partner/appointments/all/all.component';
import { ConfirmedComponent } from './partner/appointments/confirmed/confirmed.component';
import { PendingComponent } from './partner/appointments/pending/pending.component';
import { CanceledComponent } from './partner/appointments/canceled/canceled.component';
import { AppointmentsComponent } from './partner/appointments/appointments.component';
import { SettingsComponent } from './partner/settings/settings.component';
import { PackageComponent } from './partner/settings/package/package.component';
import { ProfileComponent } from './partner/settings/profile/profile.component';
import { WorkpeopleComponent } from './partner/settings/workpeople/workpeople.component';
import { CalendarComponent } from './partner/calendar/calendar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PartnerComponent } from './partner/partner.component';
import { ClickopartnerComponent } from './clickopartner/clickopartner.component';
import { SignUpPartnerComponent } from './login/sign-up-partner/sign-up-partner.component';
import { SignupComponent } from './login/signup/signup.component';
import { LoginComponent } from './login/login/login.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { ClientAppointmentComponent } from './client-profile/client-appointment/client-appointment.component';
import { ClientVoucherComponent } from './client-profile/client-voucher/client-voucher.component';
import { ClientFavoriteComponent } from './client-profile/client-favorite/client-favorite.component';
import { ClientInfoComponent } from './client-profile/client-info/client-info.component';
import { DeleteAccountComponent } from './client-profile/delete-account/delete-account.component';
import { SalonComponent } from './salon/salon.component';
import { SalonServicesComponent } from './salon/salon-services/salon-services.component';
import { SalonInfoComponent } from './salon/salon-info/salon-info.component';
import { SalonReviewComponent } from './salon/salon-review/salon-review.component';
import { GalleryComponent } from './salon/gallery/gallery.component';
import { PlaceComponent } from './salon/place/place.component';
import { CreateAnAppointmentComponent } from './salon/create-an-appointment/create-an-appointment.component';
import { PlaceSearchComponent } from './salon/place-search/place-search.component';
import { ScheduleAAppointmentComponent } from './salon/schedule-a-appointment/schedule-a-appointment.component';
import { AddTreatmentComponent } from './salon/add-treatment/add-treatment.component';
import { AppointmentInfoComponent } from './partner/calendar/appointment-info/appointment-info.component';
import { AddClientComponent } from './partner/clients/clients-list/add-client/add-client.component';
import { AddAppointmentComponent } from './partner/calendar/add-appointment/add-appointment.component';
import { EditAppointmentComponent } from './partner/appointments/edit-appointment/edit-appointment.component';




const routes: Routes = [
  { path: '', redirectTo: 'homepage', pathMatch: 'full'},
  { path: 'homepage', component: HomepageComponent},
  { path: 'partnerpage', component: ClickopartnerComponent},
  { path: 'place', component: PlaceComponent},
  { path: 'edit-appointment/:id', component: EditAppointmentComponent, canActivate: [AuthGuard], data: { expectedRole: 'OWNER' }},
  { path: 'add-appointment', component: AddAppointmentComponent, canActivate: [AuthGuard], data: { expectedRole: 'OWNER' }},
  { path: 'place-search', component: PlaceSearchComponent},
  { path: 'add-treatment/:id', component: AddTreatmentComponent},
  { path: 'schedule-a-appointment/:id', component: ScheduleAAppointmentComponent},
  { path: 'salon',
   component: SalonComponent,
   children: [
    { path: '', redirectTo: 'salon-services/:id', pathMatch: 'full' },
    { path: 'salon-services/:id',
     component: SalonServicesComponent,
     children: [
      { path: 'create-an-appointment/:id', component: CreateAnAppointmentComponent}
     ], data: { title: 'Usluge' }},
    { path: 'salon-info/:id', component: SalonInfoComponent, data: { title: 'O salonu' }},
    { path: 'salon-review/:id', component: SalonReviewComponent, data: { title: 'Utisci' }}
  ]},
  { path: 'gallery/:id', component: GalleryComponent},
  { path: 'client', 
     component: ClientProfileComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: 'CLICKO_USER' },
    children: [
      { path: '', redirectTo: 'client-info', pathMatch: 'full' },
      { path: 'client-info', component: ClientInfoComponent,  canActivate: [AuthGuard], 
       data: { 
        title: 'Profil',
        expectedRole: 'CLICKO_USER'
      }},
      { path: 'client-appointment', component: ClientAppointmentComponent,  canActivate: [AuthGuard], 
      data: { 
       title: 'Tretmini',
       expectedRole: 'CLICKO_USER'
     }},
      { path: 'client-vocher', component: ClientVoucherComponent, canActivate: [AuthGuard], 
      data: { 
       title: 'Vaučeri',
       expectedRole: 'CLICKO_USER'
     }},
      { path: 'client-favorite', component: ClientFavoriteComponent,canActivate: [AuthGuard], 
      data: { 
       title: 'Omiljeni',
       expectedRole: 'CLICKO_USER'
     }}
    ],
   },
  { path: 'delete-account', component: DeleteAccountComponent, canActivate: [AuthGuard], data: { expectedRole: 'CLICKO_USER' },},
  { path: 'signup-partner', component:SignUpPartnerComponent},
  { path: 'signup', component:SignupComponent},
  { path: 'login', component: LoginComponent},
  { path: 'partner',
component: PartnerComponent,
canActivate: [AuthGuard],
data: { expectedRole: 'OWNER' },
children: [
  { path: '', redirectTo: 'calendar', pathMatch: 'full' },
 { path: 'page',
    component: PageComponent,
    canActivate: [AuthGuard],
   data: { expectedRole: 'OWNER' },
    children: [
    { path: '', redirectTo: 'general', pathMatch: 'full' },
    { path: 'general', component: GeneralComponent,  canActivate: [AuthGuard], 
    data: { 
      title: 'Opšte',
      expectedRole: 'OWNER'
    }},
    { path: 'photo', component: PhotoComponent,  canActivate: [AuthGuard], 
    data: { 
      title: 'Slike',
      expectedRole: 'OWNER'
    }},
    { path: 'treatments', component: TreatmentsComponent,  canActivate: [AuthGuard], 
    data: { 
      title: 'Usluge',
      expectedRole: 'OWNER'
    }},
   ]
  },
  { path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: 'OWNER' },
    children: [
      { path: '', redirectTo: 'clients-list', pathMatch: 'full' },
      { path: 'clients-list',
       component: ClientsListComponent,
       canActivate: [AuthGuard],
      data: { expectedRole: 'OWNER' },
        children: [ 
          {path: 'add-client', component: AddClientComponent, canActivate: [AuthGuard], data: { expectedRole: 'OWNER' }},
          {path: 'client-info/:id', component: ClientDetailsComponent, canActivate: [AuthGuard], data: { expectedRole: 'OWNER' }},
        ]
      }
     ]
  },
  { path: 'appointments',
   component: AppointmentsComponent,
   canActivate: [AuthGuard],
   data: { expectedRole: 'OWNER' },
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full'},
      { path: 'all', component: AllComponent, canActivate: [AuthGuard], 
      data: { 
      title: 'Svi',
      expectedRole: 'OWNER'
    }},
      { path: 'confirmed', component: ConfirmedComponent, canActivate: [AuthGuard], 
      data: { 
        title: 'Potvrđeni',
        expectedRole: 'OWNER'
      }},
      { path: 'pending', component: PendingComponent, canActivate: [AuthGuard],
      data: { 
        title: 'Na čekanju',
        expectedRole: 'OWNER'
      }}, 
      { path: 'canceled', component: CanceledComponent, canActivate: [AuthGuard],
      data: { 
        title: 'Otkazani',
        expectedRole: 'OWNER'
      }},
    ]
  },
  { path: 'settings', 
  component: SettingsComponent,
  canActivate: [AuthGuard],
  data: { expectedRole: 'OWNER' },
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full'},
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard],
      data: { 
        title: 'Opšte',
        expectedRole: 'OWNER'
      }}, 
      { path: 'package', component: PackageComponent, canActivate: [AuthGuard], 
      data: { 
        title: 'Paket',
        expectedRole: 'OWNER'
      }}, 
      { path: 'workpeople', component: WorkpeopleComponent, canActivate: [AuthGuard],
      data: { 
        title: 'Zaposleni',
        expectedRole: 'OWNER'
      }},

    ]
  },
  { path: 'calendar', component: CalendarComponent,
  canActivate: [AuthGuard],
  data: { expectedRole: 'OWNER' },    
  children: [
    { path: '', redirectTo: 'table', pathMatch: 'full'},
    { path: 'appointment-info/:id', component: AppointmentInfoComponent, canActivate: [AuthGuard], data: { expectedRole: 'OWNER' }},
  ]}

]

}
 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
