<div class="flex">
  <p *ngIf="allRejected.length === 0">Trenutno nema otkazanih termina</p>
        <div class="appointment-div" *ngIf="allRejected.length > 0">
          <div class="appointment-canceled" *ngFor="let appointment of allRejected">
            <div class="treatment-div">
              <p class="treatment">{{appointment.appointmentName}}</p>
              <p class="time">
               {{  formatTime(appointment.time,appointment.durationTime)}}
              </p>
              <p class="time">
              {{ dateTranform(appointment.date) }}
              </p>
              <p class="staff">
                {{appointment.staffName}} 
              </p>
           </div>
             <div class="middle">
               <p class="time">
                {{  formatTime(appointment.time,appointment.durationTime)}}
               </p>
               <p class="time">
                {{ dateTranform(appointment.date) }}
                </p>
               <p class="staff">
                {{appointment.staffName}} 
               </p>
               <p class="client">
                {{appointment.userName}}
               </p>
             </div>
             <div class="icons">
               <div class="tooltip">
                  <span class="material-symbols-outlined hidden">
                  check_circle
                  </span>
                  <span class="tooltiptext">Prihvati</span>
                </div>
                <div class="tooltip">
                  <span class="material-symbols-outlined hidden">
                   cancel
                  </span>
                  <span class="tooltiptext">Odbij</span>
                </div>
                <div class="tooltip">
                  <span class="material-symbols-outlined hidden">
                   edit
                  </span>
                  <span class="tooltiptext">Uredi</span>
                </div>
             </div>
        </div>
        <router-outlet></router-outlet>
</div>
