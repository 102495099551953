<div class="flex">
    <section class="card" *ngIf="acceptedAppointments.length > 0">
        <h2 >Predstojeći termini</h2>
        <div class="div" *ngFor="let appointment of acceptedAppointments" >
            <div class="date">
                <p>{{ getMonthName(appointment.date)}}</p>
                <h3>{{ getDay(appointment.date)}}</h3>
            </div>
            <div class="appointment">
                <h5>{{  appointment.appointmentName}}</h5>
                <p class="gray"> {{appointment.placeName}}  &#x2022; {{appointment.durationTime}} min</p>
                <p>{{ getDayOfWeek(appointment.date)}} {{appointment.time}} &#x2022; {{  appointment.price }} RSD</p>
            </div>
        </div>
    </section>
    <section class="list">
      <div class="group">
      <h4>Na čekanju</h4>
       <p *ngIf="notAcceptedAppointments.length === 0">Trenutno nema termina na čekanju</p>
       <div class="appointment-div">
         <div class="appointment-pending" *ngFor="let appointment of notAcceptedAppointments">
           <p class="treatment">{{ appointment.appointmentName }}</p>
           <div class="middle">
             <p class="time">
              {{ formatTime(appointment.time, appointment.durationTime)}}
             </p>
             <p class="appointment-date">
             {{ formatDate(appointment.date)}}
             </p>
             <p class="price">
              {{  appointment.price }} RSD
             </p>
             <p class="salon">
              {{appointment.placeName}}
             </p>
           </div>
           <div class="icons">
             <span class="material-symbols-outlined red hidden" (click)="sentId(appointment.id, 'ODBIJEN')">
                cancel
             </span>
           </div>
         </div>
       </div>
      </div>
      <div class="group">
        <h4>Predstojeći termini</h4>
        <p *ngIf="acceptedAppointments.length === 0">Trenutno nema predstojećih termina</p>
         <div class="appointment-div">
           <div class="appointment-upcoming" *ngFor="let appointment of acceptedAppointments">
             <p class="treatment">{{appointment.appointmentName}}</p>
             <div class="middle">
               <p class="time">
                {{ formatTime(appointment.time, appointment.durationTime)}}
               </p>
               <p class="appointment-date">
                {{ formatDate(appointment.date)}}
               </p>
               <p class="price">
                {{  appointment.price }} RSD
               </p>
               <p class="salon">
                {{appointment.placeName}}
               </p>
             </div>
             <div class="icons">
               <span class="material-symbols-outlined red" (click)="sentId(appointment.id, 'ODBIJEN')">
                  cancel
               </span>
             </div>
           </div>
         </div>
        </div>
        <div class="group">
          <h4>Prošli termini</h4>
          <p *ngIf="pastAppointments.length === 0">Nema prošlih termina</p>
           <div class="appointment-div">
             <div class="appointment-past" *ngFor="let appointment of pastAppointments">
               <p class="treatment">{{appointment.appointmentName}}</p>
               <div class="middle ">
                 <p class="time">
                  {{ formatTime(appointment.time, appointment.durationTime)}}
                 </p>
                 <p class="appointment-date">
                  {{ formatDate(appointment.date)}}
                 </p>
                 <p class="pric">
                  {{  appointment.price }} RSD
                 </p>
                 <p class="salon">
                  {{appointment.placeName}}
                 </p>
               </div>
               <div class="icons">
                 <span class="material-symbols-outlined red hidden">
                    cancel
                 </span>
               </div>
             </div>
           </div>
          </div>   
    </section>
    <section class="mobile">
      <div class="group">
         <h4>Na čekanju</h4>
         <p *ngIf="notAcceptedAppointments.length === 0">Trenutno nema termina na čekanju</p>
          <div class="appointment-div">
            <div class="appointment-pending-mobile" *ngFor="let appointment of notAcceptedAppointments">
                <div class="first-div">
                   <div class="mini-date">
                     <p>{{ getMonthName(appointment.date)}}</p>
                     <h1>{{ getDay(appointment.date)}}</h1>
                     <p>{{appointment.time}}</p>
                   </div>
                   <div class="appointment">
                     <h5>{{appointment.appointmentName}}</h5>
                     <p class="gray">{{appointment.placeName}}  &#x2022; {{ appointment.durationTime}} min</p>
                     <p>{{ getDayOfWeek(appointment.date)}} {{ appointment.time}} &#x2022; {{appointment.price }} RSD</p>
                 </div>
                </div>
                <div class="btns"> 
                    <button class="cancel" (click)="sentId(appointment.id, 'ODBIJEN')">Otkaži</button>
                </div>
            </div>
          </div>
         </div>
         <div class="group">
            <h4>Predstojeći termini</h4>
            <p *ngIf="acceptedAppointments.length === 0">Trenutno nema predstojećih termina</p>
           <div class="appointment-div" >
               <div class="appointment-upcoming-mobile" *ngFor="let appointment of acceptedAppointments">
                  <div class="first-div">
                     <div class="mini-date">
                       <p>{{ getMonthName(appointment.date)}}</p>
                       <h1>{{ getDay(appointment.date)}}</h1>
                       <p>{{ appointment.time}}</p>
                     </div>
                     <div class="appointment">
                       <h5>{{appointment.appointmentName}}</h5>
                       <p class="gray">{{appointment.placeName}} &#x2022; {{appointment.durationTime}} min</p>
                       <p>{{ getDayOfWeek(appointment.date)}} {{ appointment.time}} &#x2022; {{appointment.price }} RSD</p>
                   </div>
                  </div>
                  <div class="btns"> 
                      <button class="cancel" (click)="sentId(appointment.id, 'ODBIJEN')">Otkaži</button>

                  </div>
               </div>
             </div>
            </div>
            <div class="group">
               <h4>Prošli termini</h4>
               <p *ngIf="pastAppointments.length === 0">Nema prošlih termina</p>
             <div class="appointment-div" >
                  <div class="appointment-past-mobile" *ngFor="let appointment of pastAppointments">
                     <div class="first-div">
                        <div class="mini-date">
                          <p>{{ getMonthName(appointment.date)}}</p>
                          <h1 >{{ getDay(appointment.date)}}</h1>
                          <p>{{ appointment.time}}</p>
                        </div>
                        <div class="appointment">
                          <h5>{{appointment.appointmentName}}</h5>
                          <p class="gray">{{appointment.placeName}} &#x2022; {{appointment.durationTime}} min</p>
                          <p>{{ getDayOfWeek(appointment.date)}} {{appointment.time}} &#x2022; {{appointment.price }} RSD</p>
                      </div>
                     </div>
                     <div class="btns"> 
                         <button class="cancel" disabled>Otkaži</button>
                     </div>
                  </div>
                 </div>
               </div>
    </section>
    <div class="box cancel" *ngIf="cancel">
      <img src="../assets/icons/Upitnik 1.png"  alt="">
      <p>Da li ste sigurni da želite da otkažete termin?</p>
      <div class="button-box">
        <button class="discard-button" (click)="reject('ODBIJEN')">Otkaži</button>
        <button class="save-button" (click)="closePopUp()">Odustani</button>
      </div>
    </div>
    <div class="box cancel" *ngIf="confirmCancel" (click)="closePopUp()">
      <img src="../assets/icons/Tuzan.png"  alt="">
      <p>Termin je otkazan</p>
    </div>
    <div  class="overlay" *ngIf="cancel || confirmCancel" (click)="closePopUp()">
     </div>
</div>