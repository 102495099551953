<div class="main">
    <h1>Prijavi se ili kreiraj nalog</h1>
    <div class="buttons">
        <button (click)="navigatetoLogin()">
            Prijavi se
        </button>
        <button (click)="navigatetoSignup()">
            Kreiraj nalog
        </button>
    </div>
    <div class="hr-p">
        <hr><p>ili</p><hr>
    </div> 
    <div class="google">
     <p> Putem Google naloga</p>
     <img src="../assets/icons/logo googleg 48dp.png" alt="">
    </div>
</div>
