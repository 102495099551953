<section class="section2">
    <div class="title">
        <h2>Šta bi moglo da vas zanima o Clicku</h2>
        <h3>Česta pitanja o Clicku</h3>
    </div>
    <div class="faq-list">
      <div class="faq"  *ngFor="let item of faqItems">
        <button class="question" (click)="togglePanel(item)">
           <p>{{ item.question }}</p>
           <span class="material-symbols-outlined">
            arrow_downward_alt
           </span>
        </button>
        <div class="answer" [ngClass]="{'active': item.active}">
            <p>
                {{ item.answer }}
            </p>
        </div>
      </div>
    </div></section>