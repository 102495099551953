import { Component, EventEmitter, HostListener, Input, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { DatePipe } from '@angular/common';
import { Observable, first, forkJoin, tap } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Attended, BookAppointmentDTO, TypeOfBA } from 'src/app/models/BookAppointmentDTO';
import { AppointmentDTO } from 'src/app/models/AppointmentDTO';
import { UserDTO } from 'src/app/models/UserDTO';
import { AnyComponent } from '@fullcalendar/core/preact';

interface AppointmentData {
  id: number;
  appointmentID: number;
  date: string;
  startTime: string;
  staffID: number;
  placeID: number;
  sumTime: number;
}

interface DateData {
  start: string;
  end: string;
  appointments: AppointmentData[];
}


@Component({
  selector: 'app-add-date',
  templateUrl: './add-date.component.html',
  styleUrls: ['./add-date.component.css']
})

export class AddDateComponent {
  @Output() onNext: EventEmitter<any> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }
 
  router:Router = inject(Router);
  weekDate: string;
  weekDates: string[] = [];

  isMobile: boolean = false;

  PlaceDTO: any;
  UserDTO: any;
  appointmentIDs: number[] = [];
  appointments: any[] = [];

  salonId: any;

  term: any;
  termDate: any;
  // dateData: { [key: string]: any[] } = {};
  dateData: { [key: string]: DateData  } = {};


  selectedTreatment: any;
  isTreatmentSelected: boolean = false;
  selectedStaff: any;
  selectedUser: any;


  scheduleData: any[];


  selectedIndex: number;

 

  currentDate: Date = new Date();
  startOfWeek: Date = this.getStartOfWeek(this.currentDate);
  endOfWeek: Date = this.getEndOfWeek(this.currentDate);
  weekDays: string[] = this.generateWeekDays();
  mobileDays: string[] = this.generateMobileDays();

  
  constructor(private salonService: SalonService,
    private auth: AuthService,
     private datePipe: DatePipe,
     private location: Location,
     private route: ActivatedRoute) {
    this.showWeek();
 }

 ngOnInit(): void {


  this.checkScreenSize(); 
  window.addEventListener('resize', this.checkScreenSize.bind(this));

  this.currentDate = new Date();
  this.showWeek();
  
 
  this.auth.getUser()?.subscribe({
    next: (user) => {
      this.UserDTO = user;
      const userId = user.id;
    },
    error: (error) => {
      console.error(error);
    }
  });
    

  this.route.params.subscribe(params => {
    this.salonId = params['id'];

  
    this.salonService.getPlaceDetails(this.salonId).subscribe({
      next: (data) => {
        this.PlaceDTO = data;
     
      },
      error: (error) => {
        console.error("", error);
      }
    });
  });

 this.selectedTreatment = this.salonService.selectedTreatment;
 this.selectedUser = this.salonService.selectedUser;
 this.isTreatmentSelected = true;

 this.loadAppointmentsForWeek();

}
 loadAppointmentsForWeek() {
  this.weekDates.forEach(date => {
    this.getBookedAppointments(date).subscribe(
      data => {
        if (data && data.appointments && Array.isArray(data.appointments)) {
          this.dateData[date] = data;
          const appointmentsForDate = data.appointments.map(appointment => ({ startTime: appointment.startTime, sumTime: appointment.sumTime }));
        } else {
          console.error("Invalid data format for date", date);
        }
      },
      error => {
        console.error("Error fetching appointments for", date, ":", error);
      }
    );
  });

 }
 getBookedAppointments(date: string): Observable<any> {
  const staffId = this.selectedUser?.id;
  if (!staffId) {
    console.error("Staff ID is not available");
    return new Observable<any>();
  }
  return this.salonService.getBookedAppointments(date, staffId).pipe(
    tap(data => {
      this.dateData[date] = data; 
    })
  );
}


generateTimeSlots(startTime: string, endTime: string): string[] {
  const slots: string[] = [];
  let currentTime = startTime;

  while (currentTime < endTime) {
    slots.push(currentTime);
    currentTime = this.addMinutes(currentTime, 15);
  }

  return slots;
}

isDisabledTerm(date: string, time: string, treatmentTime: number): boolean {
  const appointmentsForDate = this.dateData[date];
  const selectedSlotTime = new Date(`${date}T${time}`);


  const salonClosingTime = new Date(`${date}T${this.dateData[date]?.end}`);
  const treatmentEndTime = this.addMinutes(time, treatmentTime);

  if (new Date(`${date}T${treatmentEndTime}`) > salonClosingTime) {
    return true; 
  }


  if (appointmentsForDate && appointmentsForDate.appointments && Array.isArray(appointmentsForDate.appointments)) {
    for (const appointment of appointmentsForDate.appointments) {
      const appointmentStartTime = new Date(`${date}T${appointment.startTime}`);
      
      if (selectedSlotTime >= appointmentStartTime) {
        continue;
      }


      if (new Date(`${date}T${treatmentEndTime}`) > appointmentStartTime) {
        return true; 
      }
    }
  }

  return false;
}

isDisabled(date: string, time: string): boolean {
  const appointmentsForDate = this.dateData[date];
  const currentDate = new Date();

  const slotDate = new Date(`${date}T${time}`);


  if (slotDate < currentDate) {
    return true;
  }


  if (appointmentsForDate && appointmentsForDate.appointments && Array.isArray(appointmentsForDate.appointments)) {
    for (const appointment of appointmentsForDate.appointments) {
      if (typeof appointment.startTime === 'string' && typeof appointment.sumTime === 'number') {
        if (time >= appointment.startTime && time < this.addMinutes(appointment.startTime, appointment.sumTime)) {
          return true;  
        }
      }
    }
  }
  return false; 
}
addMinutes(time: string, minutes: number): string {
  const [hours, mins] = time.split(':').map(Number);
  const d = new Date();
  d.setHours(hours);
  d.setMinutes(mins + minutes);

  const newHours = ('0' + d.getHours()).slice(-2);
  const newMinutes = ('0' + d.getMinutes()).slice(-2);

  return `${newHours}:${newMinutes}`;
}




selectTerm(term: string, date: string) {
  if (this.isDisabledTerm(date, term, this.selectedTreatment.time)) {
    alert('Neodgovarajući termin, izaberite drugo vreme');
    return;
  }

  this.term = term;
  this.termDate = date;
  

  this.selectedTreatment.term = term;
  this.selectedTreatment.date = date;
  
  console.log(this.selectedTreatment);
  console.log(date);

  if (this.isMobile) {
    this.salonService.setSelectedUser(this.selectedUser);
    this.onNext.emit();
  }
  
}


  showWeek(){
    const today = new Date();
    const firstDay = today.getDate() - today.getDay();
    const lastDay = firstDay + 6;

    const prviDan = new Date(today.getFullYear(), today.getMonth(), firstDay);
    const lastDayObj = new Date(today.getFullYear(), today.getMonth(), lastDay);


    const firstDayFormatted = this.datePipe.transform(prviDan, 'dd.MM.yyyy');
    const lastDayFormatted = this.datePipe.transform(lastDayObj, 'dd.MM.yyyy');

    this.weekDate = `${firstDayFormatted}-${lastDayFormatted}`;

    this.startOfWeek = this.getStartOfWeek(this.currentDate);
    this.endOfWeek = this.getEndOfWeek(this.currentDate);
    this.weekDays = this.generateWeekDays();
    this.weekDates = this.generateWeekDatesForApi(this.startOfWeek);
    
  }

  getMonthYearString(date: Date): string {
    const months = [
      'Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun',
      'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'
    ];
  
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return `${months[monthIndex]} ${year}`;
  }
  
    private getStartOfWeek(date: Date): Date {
      const currentDate = new Date(date);
      const dayOfWeek = currentDate.getDay();
      const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      return new Date(currentDate.setDate(diff));
    }
  
    private getEndOfWeek(date: Date): Date {
      const startOfWeek = this.getStartOfWeek(date);
      return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
    }
  
     generateWeekDays(): string[] {
      const days = ['Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota', 'Nedelja'];
      return days;
    }
  
     generateMobileDays(): string[] {
      const days = ['Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub', 'Ned'];
      return days;
    }
  
    nextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
      this.startOfWeek = this.getStartOfWeek(this.currentDate);
      this.endOfWeek = this.getEndOfWeek(this.currentDate);
      this.showWeek();
      this.loadAppointmentsForWeek();
    }
  
    prevWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      this.startOfWeek = this.getStartOfWeek(this.currentDate);
      this.endOfWeek = this.getEndOfWeek(this.currentDate);
      this.showWeek();
      this.loadAppointmentsForWeek();
    }
  
    formatDate(date: Date): string {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }

  
    
    formatDateForDay(date: Date): string {
       return this.datePipe.transform(date, 'd');
    }
  
    formatDateForMonth(date: Date): string {
    
      return this.datePipe.transform(date, 'MMM');
   }
 
  
    getCircleDate(startOfWeek: Date, index: number): number {
      const clonedDate = new Date(startOfWeek);
      clonedDate.setDate(clonedDate.getDate() + index);
      return clonedDate.getDate();
    }
  
    getCircleDateMobile(startOfWeek: Date, index: number): number {
      const clonedDate = new Date(startOfWeek);
      clonedDate.setDate(clonedDate.getDate() + index);
      return clonedDate.getDate();
    }
  
    isPastDate(startOfWeek: Date, index: number): boolean {
      const currentDate = new Date();
      const targetDate = new Date(startOfWeek);
      targetDate.setDate(targetDate.getDate() + index);
  
      return targetDate < currentDate;
  }
  
  isCurrentDate(startOfWeek: Date, index: number): boolean {
    const currentDate = new Date();
    const targetDate = new Date(startOfWeek);
    targetDate.setDate(targetDate.getDate() + index);
  
    return targetDate.toDateString() === currentDate.toDateString();
  }

  generateWeekDatesForApi(startOfWeek: Date): string[] {
    const weekDates: string[] = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      weekDates.push(this.getFormattedDateForApi(currentDate)); 
    }
    return weekDates;
  
  }


  changeWeek(offset: number){
    const today = new Date();
    today.setDate(today.getDate() + (offset * 7));
    this.showWeek();
    console.log(this.weekDates);
  }

  getFormattedDateForApi(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  
  createAppointmentDTO(treatment: any): BookAppointmentDTO {
    const appointmentDTO: AppointmentDTO = {
      id: treatment.id,
      name: treatment.name,
      userDTOs: treatment.userDTOs,
      time: treatment.time,
      category: treatment.category,
      price: treatment.price,
      specialPrice: treatment.specialPrice,
      description: treatment.description,
      extraTime: treatment.extraTime,
      needAcception: treatment.needAccept,
    
    };
  
    const userDTO: UserDTO = {
      id: this.UserDTO.id, 
      firstName: this.UserDTO.firstName,
      lastName: this.UserDTO.lastName,
      email: this.UserDTO.email,
      telephone: this.UserDTO.telephone,

    };

  
    const bookAppointmentDTO: BookAppointmentDTO = {
    
      appointmentID: appointmentDTO.id,
      userID: userDTO.id,
      date: treatment.date, 
      time: treatment.term, 
      attended: Attended.NULL,
      typeOfBA: TypeOfBA.NA_CEKANJU,
      canChange: true,
      staffID: treatment.staff.id,
    };
  
    return bookAppointmentDTO;
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 768;
  }
  
  exit(salonId:number){
    this.router.navigate(['salon', 'salon-services', salonId]);
  }

  goBack(){
    this.location.back();
  }

  goToBook(salonId:number){
    this.router.navigate(['schedule-a-appointment', salonId]);
  }

  navigateToAddTreatment(salonId:number){
    this.router.navigate(['add-treatment', salonId]);
  }

  goToNextStep() {

    if (!this.term || !this.termDate) {
      alert('Morate selektovati termin pre nego što nastavite.');
      return;
    }
   
    this.salonService.setSelectedUser(this.selectedUser);
    this.onNext.emit();
    }


}
