<div class="menu" >
    <ul class="menu-list">
      <li class="menu-item">
        <a routerLink="calendar" routerLinkActive="active">
          <span class="material-symbols-outlined" >
            calendar_month
          </span>
        </a>
      </li>
      <li class="menu-item">
        <a routerLink="page" routerLinkActive="active">
          <span class="material-symbols-outlined">
            home
          </span>
        </a>
      </li>
      <li class="menu-item">
        <a routerLink="appointments" routerLinkActive="active">
          <span class="material-symbols-outlined">
              check_circle
          </span>
        </a>
      </li>
      
      <li class="menu-item">
        <a routerLink="clients" routerLinkActive="active" >
          <span class="material-symbols-outlined">
            clinical_notes
            </span>
        </a>
      </li>
      <li class="menu-item">
        <a routerLink="settings" routerLinkActive="active">
          <span class="material-symbols-outlined">
            settings
            </span>
        </a>
      </li>
      <li class="menu-item disabled">
        <a href="" routerLinkActive="active">
          <span class="material-symbols-outlined">
            bid_landscape
          </span>
        </a>
      </li>
      <li class="menu-item disabled">
        <a href="" routerLinkActive="active">
          <span class="material-symbols-outlined">
            award_star
            </span>
        </a>
      </li>
    </ul>
</div>
