import { Component, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import ValidateForm from 'src/app/helpers/validateform';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sign-up-partner',
  templateUrl: './sign-up-partner.component.html',
  styleUrls: ['./sign-up-partner.component.css']
})
export class SignUpPartnerComponent {
  
  router:Router = inject(Router);
  isDropdownOpen: boolean = false;

  type: string = "password";
  isText: boolean = false;
  eyeIcon: string = "visibility_off";

  signupPartnerForm!: FormGroup;

  constructor(
    private fb: FormBuilder, 
    private location: Location, 
    private auth: AuthService
    ) { }

  hideShowPass(){
    this.isText = !this.isText;
    this.isText ?  this.eyeIcon = "visibility" : this.eyeIcon = "visibility_off";
    this.isText ? this.type = "text" : this.type = "password"
 }

 ngOnInit(){
  this.signupPartnerForm =this.fb.group({
    // category: ['', Validators.required],
    placeName: ['', Validators.required],
    phoneNumber: [ '', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    userRole: ['OWNER'],
    clickoPackage: [ '', Validators.required],
    typeOfPlace: [ '', Validators.required],
  });

}

OnSignUpPartner(){
  if(this.signupPartnerForm.valid){
    this.auth.signup(this.signupPartnerForm.value)
    .subscribe({
      next:(res)=>{
        this.signupPartnerForm.reset();
        this.router.navigate(['partner/']);
      },
      error:(err)=>{
        alert(err?.error.message)
      }
     })
    console.log(this.signupPartnerForm.value)
    }else{
      ValidateForm.validateAllFormFields( this.signupPartnerForm);
      alert("Molimo popunite sva polja.")
    }
}

  navigateBack(): void {
    this.location.back();
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event) {
    if (!(event.target as HTMLElement).classList.contains('dropbtn')) {
      this.isDropdownOpen = false;
    }
  }

  navigatetoPartner(){
    this.router.navigate(['partnerpage']);
  }
  navigatetoHomepage(){
    this.router.navigate(['homepage']);
  }

  navigatetoLogin(){
    this.router.navigate(['login']);
  }
  navigatetoSignup(){
    this.router.navigate(['signup']);
  }
  navigatetoSignupPartner(){
    this.router.navigate(['signup-partner']);
  }
  
} 
