<div class="main">
  <section class="header">
    <app-header></app-header>
  </section>  
  <section class="content">
    <div class="signup"> 
      <h2>Clicko Partner</h2>
      <p class="text">Unesite vaše podatke da kreirate nalog</p>
      <form class="form-box" [formGroup]="signupPartnerForm">
       <div class="form-information">
  
       <div class="input-group"> 
         <label class="label-text" for="placeName">Naziv Salona</label>
         <input class="input-text" type="text" id="placeName" formControlName="placeName">
       </div> 
       <div class="input-group">
         <label class="label-text" for="phoneNumber">Broj telefona</label>
         <input class="input-text" type="text" id="phoneNumber" formControlName="phoneNumber">
       </div>
       <div class="input-group">
         <label class="label-text" for="email">Email adresa</label>
         <input class="input-text" type="email" id="email" formControlName="email">
       </div>
       <div class="input-group">
         <label class="label-text" for="password">Lozinka</label>
         <input class="input-text  password" [type]="type" id="password" formControlName="password">
         <span (click)="hideShowPass()" class="material-symbols-outlined eye">
           {{ eyeIcon }}
         </span>
       </div>
       <div class="select">
        <div class="input-group"> 
          <label class="label-text" for="clickoPackage">Clicko Paket</label>
          <select name="" id="clickoPackage" formControlName="clickoPackage">
              <option class="option" value="PRIVATE">Start</option>
              <option class="option" value="STANDARD">Standard</option>
              <option class="option" value="VIP">Premium</option>
          </select>
        </div>
        <div class="input-group"> 
          <label class="label-text" for="typeOfPlace">Kategorija</label>
          <select name="" id="typeOfPlace" formControlName="typeOfPlace">
              <option class="option" value="SALONI">Salon</option>
          </select>
        </div>   
       </div>        
     </div>
     <button (click)="OnSignUpPartner()" class="btn">Pošalji zahtev</button>
      </form>
   </div>
   <div class="image">
    <img src="../assets/icons/ToJeTo 2.png" alt="">
   </div>
   <div class="image-mobile">
    <img src="../assets/icons/ToJeTo 2 (2).png" alt="">
   </div>
  
  </section>
</div>