<div class="main">
    <div class="div-btn">
         <button  (click)="goBack()" class="back-btn">
          <span class="material-symbols-outlined">
            arrow_back
         </span>
        </button>
    </div>
    <div class="salon">
        <h1>{{PlaceDTO.name}}</h1>
            <p>Pregled računa</p>
            <div class="div" *ngFor="let treatment of selectedTreatments; let i = index" >
                <div class="date">
                    <p>avg</p>
                    <h3>24</h3>
                </div>
                <div class="appointment">
                    <h5> {{ treatment.name }}</h5>
                    <p class="gray">{{PlaceDTO.name}} &#x2022; {{ treatment.time }} min</p>
                    <p>Dan {{ treatment.selectedTerm }}  &#x2022;{{ treatment.price }} RSD</p>
                </div>
            </div>
            <div class="payment">
                <p>Način plaćanja: Plaćanje u salonu</p>
                <h4>Ukupno: {{totalPrice}} RSD</h4>
            </div>
            <button class="btn-confirm" (click)="bookAppointment()">
                   Zakaži
            </button>
        </div>
</div>
       