import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { AppointmentCategoryPlaceDTO } from 'src/app/models/AppointmentCategoryPlaceDTO';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'wc-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.css']
})
export class TreatmentsComponent {

  salonId: any;
  placeID: any;
  PlaceDTO: any;
  UserDTO: any;
  categories: any[] = [];
  allCategories: any[] = [];
  appointmentIDs: number[] = [];
  appointments: any[] = [];
  appointmentCategoryPlaceDTO: AppointmentCategoryPlaceDTO;
  appointmentStates: { [key: number]: { isEditOrDelete: boolean, clickedAppointmentId: number | null } } = {};

 
  isTreatmentOpen = false;
  isAddCategory = false;
  isEditOrDelete: boolean = false;

  clickedAppointmentId: number | null = null;

  constructor(private treatmentsService: TreatmentsService,
              private auth: AuthService,
              private salonService: SalonService,
              private elementRef: ElementRef) {
    this.treatmentsService.isOpen$.subscribe((isOpen) => {
      this.isTreatmentOpen = isOpen;
    });
  }

  ngOnInit() {

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
      
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
             
    
                } else {
                  console.warn();
                }
    
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });


            this.salonService.getAppointmentsList(this.placeID).subscribe(
              (data) => {
                  this.appointmentCategoryPlaceDTO = data;
                  // console.log(this.appointmentCategoryPlaceDTO);
                  this.appointments = this.appointmentCategoryPlaceDTO.appointments;
                  // console.log(this.appointments);
              },
              (error) => {
                console.error("", error);
              }
            );
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });
 
   this.salonService.getALLCategories().subscribe(
    (data) => {
      this.allCategories = data.map(item => this.transformWord(item)); 
    },
    (error) => {
      console.error( error);
    }
  );

  }

  transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
  } 

  getTreatmentDetails(appointmentIDs: number[]): void {
    for (const id of appointmentIDs) {
      this.salonService.getAppointments(id).subscribe((appointment) => {
        this.appointments.push(appointment);
   
      });
    }
  }
  
  getTreatmentName(appointmentId: number): string {
    const appointment = this.appointments.find(app => app.id === appointmentId);
    return appointment ? appointment.name : '';
  }
  
  getTreatmentPrice(appointmentId: number): number {
    const appointment = this.appointments.find(app => app.id === appointmentId);
    return appointment ? appointment.price : '';
  }
  
  getTreatmentTime(appointmentId: number): number {
    const appointment = this.appointments.find(app => app.id === appointmentId);
    return appointment ? appointment.time : 0;
  }

  getTreatmentId(appointmentId: number): number {
    const appointment = this.appointments.find(app => app.id === appointmentId);
    return appointment ? appointment.id : 0;
  }

  saveCategory(selectedCategory: string){
    this.categories.push(selectedCategory);
    // const categoryUpperCase = selectedCategory.toUpperCase();
    // console.log(selectedCategory);
    // this.salonService.sentCategories(this.placeID, categoryUpperCase).subscribe({
    //   next: (response) => {
    //     console.log(response);
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   },
    //   complete: () => {
    //   }
    // });
  
  }

  openTreatment() {
    this.treatmentsService.openTreatment();
  }
  
  closeTreatment() {
    this.treatmentsService.closeTreatment();
  }


  addCategory(){
    this.isAddCategory = !this.isAddCategory;
  }

  openEditDelete(appointmentId: number) {
  
    if (this.appointmentStates[appointmentId]) {
      this.appointmentStates[appointmentId].isEditOrDelete = !this.appointmentStates[appointmentId].isEditOrDelete;
    } else {
 
      this.appointmentStates[appointmentId] = { isEditOrDelete: true, clickedAppointmentId: appointmentId };
    }
  }

 
  closeEditDeleteDiv(appointmentId: number) {
    if (this.appointmentStates[appointmentId]) {
      this.appointmentStates[appointmentId].isEditOrDelete = false;
    }
  }


  @HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
  let isOpened = false;

  for (const appointmentId in this.appointmentStates) {
    if (this.appointmentStates.hasOwnProperty(appointmentId)) {
      const editDeleteDiv = document.querySelector(`.edit-or-delete-${appointmentId}`);
      const cNameDiv = document.querySelector(`.c-name-${appointmentId}`);

    
      if (this.appointmentStates[appointmentId].isEditOrDelete) {
        isOpened = true; 

   
        if ((!editDeleteDiv || !editDeleteDiv.contains(event.target as Node)) && (!cNameDiv || !cNameDiv.contains(event.target as Node))) {
          this.closeEditDeleteDiv(Number(appointmentId));
        }
      }
    }
  }

  if (!isOpened) {
    for (const appointmentId in this.appointmentStates) {
      if (this.appointmentStates.hasOwnProperty(appointmentId)) {
        this.closeEditDeleteDiv(Number(appointmentId));
      }
    }
  }
}
  hideAddCategory() {
    this.isAddCategory = false;
  }

  deleteTretment(id: number, appointment): void{

    appointment.active = false;
    console.log(appointment);
    this.salonService.deleteAppointment(id).subscribe({
      next: (response) => {
          console.log("Nije vise aktivan:", appointment);
       
      },
      error: (error) => {
          console.error(error);
      }
  });
  }

}


