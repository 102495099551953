<div class="main">
     <h2>Odaberite datum i vreme</h2>
      <article class="calendar">
        <div class="first-div">
            <p>Odaberite termin</p>
          <div>
            <h2>{{ getMonthYearString(startOfWeek) }}</h2>
            <div class="arrows">
              <span class="material-symbols-outlined mobile" (click)="prevWeek()">
                chevron_left
              </span>
              <span class="material-symbols-outlined mobile" (click)="nextWeek()">
                chevron_right
              </span>
            </div>
          </div>
        </div>
        <div class="second-div">
           <div class="week">
              <p class="week-date web" > {{ formatDate(startOfWeek) }} - {{ formatDate(endOfWeek) }} </p>
              <div class="arrows">
                <span class="material-symbols-outlined web" (click)="prevWeek()">
                  chevron_left 
                </span>
                <span class="material-symbols-outlined web" (click)="nextWeek()">
                  chevron_right
                </span>
              </div>
           </div>
           <div *ngIf="!isMobile; else mobileView">
            <div class="days">
              <div class="day" *ngFor="let day of weekDays; let i = index" [ngClass]="{'past-date': isPastDate(startOfWeek, i), 'current-date': isCurrentDate(startOfWeek, i)}">
                <div class="circle">{{ getCircleDate(startOfWeek, i) }}</div>
                <p>{{ day }}</p>
              </div>
            </div>
          </div>
          <ng-template #mobileView>
            <div class="days">
              <div class="day" *ngFor="let day of generateMobileDays(); let i = index" [ngClass]="{'past-date': isPastDate(startOfWeek, i), 'current-date': isCurrentDate(startOfWeek, i)}">
                <div class="circle">{{ getCircleDate(startOfWeek, i) }}</div>
                <p>{{ day }}</p>
              </div>
            </div>
          </ng-template>
           <div class="timetable" > 
            <div class="scroll">
              <div class="terms"  *ngFor="let date of weekDates; let i = index" >
                <div *ngIf="dateData[date]" class="gap">
                  <div *ngFor="let time of generateTimeSlots(dateData[date].start, dateData[date].end)">
                 <div class="term" [class.disabled]="isDisabled(date, time)" 
                  [class.selected-term]="term === time && termDate === date"
                  (click)="!isDisabled(date, time) && selectTerm(time, date)">{{ time }}</div> <!-- OVDE MOZDA DA POSALJES I dateData[date].date kako bi dobila i datum za koji se pravi rezervacija :) -->
                </div>
                </div>  
            </div>
            </div>
           </div>
        </div>
      </article>
      <div class="bottom">
        <div *ngIf="PlaceDTO">
          <h5>{{ this.selectedTreatment.name }}</h5> 
          <p class="grey">{{ this.PlaceDTO.name }} &#x2022; {{ this.selectedTreatment.time }} min</p>
          <p *ngIf="this.selectedTreatment.price">{{ this.selectedTreatment.price }} RSD</p>
        </div>
        <button (click)="goToNextStep()">
            Dalje
        </button>
      </div>
</div>