import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { AppointmentDTO } from 'src/app/models/AppointmentDTO';
import { Attended, BookAppointmentDTO, TypeOfBA } from 'src/app/models/BookAppointmentDTO';
import { UserDTO } from 'src/app/models/UserDTO';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
  @Output() onNext: EventEmitter<any> = new EventEmitter();

  router:Router = inject(Router);

  PlaceDTO: any;
  allStaff: any;
  salonId: any;
  UserDTO: any;

  selectedTreatment: any;
  isTreatmentSelected: boolean = false;
  selectedUser: any;

  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService,
    private auth: AuthService,
    private datePipe: DatePipe
  ) { }


  
  ngOnInit(): void {
    this.salonId = this.route.snapshot.paramMap.get('id');

  if (this.salonId) {
    this.salonService.getPlaceDetails(this.salonId).subscribe(
      (data) => {
        this.PlaceDTO = data;
       
      },
      (error) => {
        console.error("", error);
      }
    );
  } else {
    console.error("salonId is null");
  }

  this.auth.getUser()?.subscribe({
    next: (user) => {
      this.UserDTO = user;
      const userId = user.id;
    },
    error: (error) => {
      console.error(error);
    }
  });

  this.selectedTreatment = this.salonService.selectedTreatment;
  this.selectedUser = this.salonService.selectedUser;

  console.log(this.selectedTreatment);
  console.log(this.selectedUser);
  console.log(this.selectedTreatment.date);

}

private readonly months: string[] = [
  'Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 
  'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'
];

private readonly days: string[] = [
  'Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'
];
parseDate(dateString: string): Date {
  const parts = dateString.split('-');
  const day = parseInt(parts[2], 10);  
  const month = parseInt(parts[1], 10) - 1;  
  const year = parseInt(parts[0], 10); 
  return new Date(year, month, day);
}

formatDate(dateString: string): string {
  const dateObject = this.parseDate(dateString);
  if (isNaN(dateObject.getTime())) {
    console.error("Invalid date:", dateString);
    return "Nevažeći datum";
  }
  
  const dayOfWeek = this.days[dateObject.getDay()];
  const day = dateObject.getDate();
  const month = this.months[dateObject.getMonth()];

  return `${dayOfWeek}, ${day} ${month}`;
}

      transformWord(word: string): string {
        return word.toLowerCase().split(' ').map(word => { 
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ').replace(/_/g, ' ');
      }

      createAppointmentDTO(treatment: any): BookAppointmentDTO {
        const appointmentDTO: AppointmentDTO = {
          id: treatment.id,
          name: treatment.name,
          userDTOs: treatment.userDTOs,
          time: treatment.time,
          category: treatment.category,
          price: treatment.price,
          specialPrice: treatment.specialPrice,
          description: treatment.description,
          extraTime: treatment.extraTime,
          needAcception: treatment.needAccept,
        
        };
      
        const userDTO: UserDTO = {
          id: this.UserDTO.id, 
          firstName: this.UserDTO.firstName,
          lastName: this.UserDTO.lastName,
          email: this.UserDTO.email,
          telephone: this.UserDTO.telephone,
    
        };
    
      
        const bookAppointmentDTO: BookAppointmentDTO = {
        
          appointmentID: appointmentDTO.id,
          userID: userDTO.id,
          date: treatment.date, 
          time: treatment.term, 
          attended: Attended.NULL,
          typeOfBA: TypeOfBA.NA_CEKANJU,
          canChange: true,
          staffID: this.selectedUser.id,
        };
      
        return bookAppointmentDTO;
      }
      
      bookAppointment() {
        if (this.auth.isLoggedIn() && localStorage.getItem('userRole') === 'CLICKO_USER') {
          console.log(this.selectedTreatment);
          
          if (this.selectedTreatment) {
            const appointmentDTO = this.createAppointmentDTO(this.selectedTreatment);
            console.log('Tretman koji je zakazan:', this.selectedTreatment);
            console.log('AppointmentDTO:', appointmentDTO);
      
            this.salonService.bookAppointment(appointmentDTO).subscribe({
              next: (result) => {
                console.log(result);
                this.router.navigate(['/client/client-appointment']);
              },
              error: (error) => {
                console.error(error);
              }
            });
          } else {
            console.error("No treatment selected.");
          }
        } else {
            this.salonService.updateAddAccount(true); 
        }
      }
}
