import { Component, Input, OnInit } from '@angular/core';
import { GoogleMapsModule } from "@angular/google-maps";
import { RouterOutlet } from '@angular/router';


@Component({
  selector: 'static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.css']
})

export class StaticMapComponent implements OnInit {

  @Input() address!: string;
  @Input() zoom: number = 15;
  @Input() width: number = 600;
  @Input() height: number = 300;
  mapUrl!: string;

  private apiKey: string = 'AIzaSyBrHK3DXckhqGpJNCtk9p1pg3V0mflUE7Y';
  private markerImagePath: string = 'http://test.clicko.rs/assets/icons/clickomarker.png';

  ngOnInit(): void {
    this.generateMapUrl();
  }

  ngOnChanges(): void {
    this.generateMapUrl();
  }

  private generateMapUrl(): void {
    const encodedAddress = encodeURIComponent(this.address);
    this.mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${encodedAddress}&zoom=${this.zoom}&size=${this.width}x${this.height}&maptype=roadmap&key=${this.apiKey}&markers=icon:${this.markerImagePath}%7C${encodedAddress}`;
  }
}




