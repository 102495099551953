<div class="box">
  <div class="center">
     <div class="text"> <p >Podešavanja u ovoj sekciji se odnose na informacije koje će biti prikazane klijentima na vašem profilu.</p></div>
    <form class="form-box"  [formGroup]="generalForm" (ngSubmit)="onSubmit()">
      <div class="form-information">
      <div class="input-group">
        <label class="label-text" for="address">Adresa</label>
        <input class="input-text" type="text" id="address"  formControlName="address" >
      </div>
      <div class="input-group">
        <label class="label-text" for="address2">Adresa 2</label>
        <input class="input-text" type="text" id="address2" > 
      </div>
     </div>
     <div class="inputPhoto">
      <div class="text-input-group">
        <label class="label-text" for="description">O salonu</label>
        <textarea class="textarea" name="description" rows="10" cols="50" formControlName="description">
        </textarea>
      </div>
      <div class="addphoto-div">
        <label class="label-text" for="">Naslovna fotografija</label>
        <div class="addphoto">
          <img *ngIf="this.PlaceDTO?.photoURL" [src]="PlaceDTO.photoURL" alt="">
          <span class="material-symbols-outlined" *ngIf="!this.PlaceDTO?.photoURL">
            cloud_upload
        </span>
        <div class="file-upload">
          <input  type="file" (change)="onFileChange($event)" id="fileInput" />
          <label *ngIf="!this.PlaceDTO?.photoURL" for="fileInput" class="custom-button">Dodaj naslovnu fotografiju </label>
        </div>
        </div>
    </div>
     
    </div>
    <div class="worktime">
     <p class="title-time">
      Radno vreme
     </p>
     <div class="days" *ngFor=" let day of workinTimes">
      <p class="day" >
        {{ transformWord(day.day) }}
      </p>
      <label class="switch">
            <input type="checkbox" [checked]="day.start !== null && day.end !== null" (change)="onCheckboxChange($event, day.day)">
            <span class="slider"></span>
        </label>
      <div class="switch-time"  >
        <div class="hours" [hidden]="day.showDiv" >
          <select class="select start" id="" name="" form="" [value]="day.start">
            <option class="option" value="00:00">00:00</option>
            <option class="option" value="01:00">01:00</option>
            <option class="option" value="02:00">02:00</option>
            <option class="option" value="03:00">03:00</option>
            <option class="option" value="04:00">04:00</option>
            <option class="option" value="05:00">05:00</option>
            <option class="option" value="06:00">06:00</option>
            <option class="option" value="07:00">07:00</option>
            <option class="option" value="08:00">08:00</option>
            <option class="option" value="09:00">09:00</option>
            <option class="option" value="10:00">10:00</option>
            <option class="option" value="11:00">11:00</option>
            <option class="option" value="12:00">12:00</option>
            <option class="option" value="13:00">13:00</option>
            <option class="option" value="14:00">14:00</option>
            <option class="option" value="15:00">15:00</option>
            <option class="option" value="16:00">16:00</option>
            <option class="option" value="17:00">17:00</option>
            <option class="option" value="18:00">18:00</option>
            <option class="option" value="19:00">19:00</option>
            <option class="option" value="20:00">20:00</option>
            <option class="option" value="21:00">21:00</option>
            <option class="option" value="22:00">22:00</option>
            <option class="option" value="23:00">23:00</option>
            <option class="option" value="24:00">24:00</option>
          </select>
          <p class="till">do</p>
          <select class="select end" id="" name="" form=""  [value]="day.end">
            <option class="option" value="00:00">00:00</option>
            <option class="option" value="01:00">01:00</option>
            <option class="option" value="02:00">02:00</option>
            <option class="option" value="03:00">03:00</option>
            <option class="option" value="04:00">04:00</option>
            <option class="option" value="05:00">05:00</option>
            <option class="option" value="06:00">06:00</option>
            <option class="option" value="07:00">07:00</option>
            <option class="option" value="08:00">08:00</option>
            <option class="option" value="09:00">09:00</option>
            <option class="option" value="10:00">10:00</option>
            <option class="option" value="11:00">11:00</option>
            <option class="option" value="12:00">12:00</option>
            <option class="option" value="13:00">13:00</option>
            <option class="option" value="14:00">14:00</option>
            <option class="option" value="15:00">15:00</option>
            <option class="option" value="16:00">16:00</option>
            <option class="option" value="17:00">17:00</option>
            <option class="option" value="18:00">18:00</option>
            <option class="option" value="19:00">19:00</option>
            <option class="option" value="20:00">20:00</option>
            <option class="option" value="21:00">21:00</option>
            <option class="option" value="22:00">22:00</option>
            <option class="option" value="23:00">23:00</option>
            <option class="option" value="24:00">24:00</option>
          </select>
        </div>
       </div>
     </div>
     <!-- *ngIf="form.get('tuesday')?.value" -->
       </div>
     <div class="button-box" > 
    <button type="button" class="discard-button" (click)="resetForm()">Odustani</button>
    <button type="submit" class="save-button">Sačuvaj</button> 
    </div>  
   
  </form>
   </div>
</div>