import { Component, ElementRef, HostListener, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clickopartner',
  templateUrl: './clickopartner.component.html',
  styleUrls: ['./clickopartner.component.css']
})
export class ClickopartnerComponent {
  router:Router = inject(Router);

  isExpanded1: boolean = false;
  isExpanded2: boolean = false;
  isExpanded3: boolean = false;
 
   
  @ViewChild('toTop') toTopButton!: ElementRef;
  

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 500) {
      this.toTopButton.nativeElement.classList.add('active');
    } else {
      this.toTopButton.nativeElement.classList.remove('active');
    }
  }

 
  signupPartner(){
    this.router.navigate(['signup-partner']);
  }
   

  tothetop(){
    window.scroll(0,0);
  }

  toggleArticle(articleNumber: number) {
    switch (articleNumber) {
      case 1:
        this.isExpanded1 = !this.isExpanded1;
        if (this.isExpanded1) {
          this.isExpanded2 = false;
          this.isExpanded3 = false;
        }
        break;
      case 2:
        this.isExpanded2 = !this.isExpanded2;
        if (this.isExpanded2) {
          this.isExpanded1 = false;
          this.isExpanded3 = false;
        }
        break;
      case 3:
        this.isExpanded3 = !this.isExpanded3;
        if (this.isExpanded3) {
          this.isExpanded1 = false;
          this.isExpanded2 = false;
        }
        break;
      default:
        break;
    }
  }
  
  isArticleInFrame(articleNumber: number): boolean {
    const article = document.querySelector(`.col .cards article:nth-child(${articleNumber})`) as HTMLElement;
    const container = document.querySelector('.col') as HTMLElement;
    if (article && container) {
      const articleRect = article.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      return (
        articleRect.left >= containerRect.left &&
        articleRect.right <= containerRect.right &&
        articleRect.top >= containerRect.top &&
        articleRect.bottom <= containerRect.bottom
      );
    }
    return false;
  }
  
  isParagraphVisible(articleNumber: number): boolean {
    return this.isArticleInFrame(articleNumber);
  }
  
  
 
}


  

