import { Component } from '@angular/core';


@Component({
  selector: 'wc-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent {

}
