import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormService } from 'src/app/services/form/form.service';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class Step1Component {
  @Output() onNext: EventEmitter<any> = new EventEmitter();
  step1Form: FormGroup;

  salonId: any;
  placeID: any;
  PlaceDTO: any;
  UserDTO: any;
  categories: any[] = [];
  allCategories: any[] = [];
  
  constructor(private treatmentsService: TreatmentsService,
    private formService: FormService,
    private auth: AuthService,
      private salonService: SalonService) {

      }


ngOnInit() {

  
  this.step1Form = new FormGroup({ 
    name: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    description: new FormControl(null),
  })

this.auth.getUser().subscribe({
next: (data) => {
if (data) {
  this.UserDTO = data;
  if (this.UserDTO && this.UserDTO.placeID) {
    this.placeID = this.UserDTO.placeID;
    this.auth.getSalon(this.placeID).subscribe({
      next: (data) => {
        if (data) {
          this.PlaceDTO = data;

        } else {
          console.warn();
        }
        this.salonService.getCategories().subscribe(
          (data) => {
            this.categories = data.map(category => this.transformWord(category)); 
          },
          (error) => {
            console.error( error);
          }
        );
      },
      error: (salonError) => {
        console.error( salonError);
      }
    });

    this.salonService.getPlaceDetails(this.placeID).subscribe(
      (data) => {
          this.PlaceDTO = data;
      },
      (error) => {
        console.error("", error);
      }
    );
  } else {
    console.warn();
  }
} else {
  console.warn();
}
},
error: (userError) => {
console.error( userError);
}
});

this.salonService.getALLCategories().subscribe(
(data) => {
this.allCategories = data.map(item => this.transformWord(item)); 
},
(error) => {
console.error( error);
}
);

}

transformWord(word: string): string {
return word.toLowerCase().split(' ').map(word => { 
return word.charAt(0).toUpperCase() + word.slice(1);
}).join(' ').replace(/_/g, ' ');
}

transformBack(word: string): string {
  return word.toUpperCase().replace(/ /g, '_');
  }

  goToNextStep() {
    if (this.step1Form.valid) {
    this.onNext.emit(this.step1Form.value);
  
    }
  }

  closeTreatment() {
    this.treatmentsService.closeTreatment();
  }

 
}
