import { Directive, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
  @Output() secondPageReached: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
    // Assuming second page starts at twice the window's height
    if (scrollPosition >= window.innerHeight * 2) {
      this.secondPageReached.emit(true);
    }
  }
}