<div class="full-page-overlay">
  <div class="btn-div">
   <button (click)="closeTreatment()" class="close-btn">
     <span class="material-symbols-outlined">
       close
     </span>
   </button>
  </div>
  <div class="info-div">
    <div class="steps">
     <div class="step" [ngClass]="{ 'active': currentStep === 1 }" >
       <p >Osnovno</p>
     </div>
     <div class="step" [ngClass]="{ 'active': currentStep === 2 }">
       <p>Zaposleni</p>
     </div>
    <div class="step" [ngClass]="{ 'active': currentStep === 3 }">
       <p>Cene i trajanje</p>
    </div>
    </div>
      <div *ngIf="currentStep === 1" class="width">
        <app-step1  (onNext)="nextStep($event)"></app-step1>
      </div>
      <div *ngIf="currentStep === 2" class="width">
        <app-step2  (onNext)="nextStep($event)" (onBack)="handleBack()"  [formData]="step1Form"></app-step2>
      </div>
      <div *ngIf="currentStep === 3" class="width">
        <app-step3 (onSubmit)="submitForm()" (onBack)="handleBack()" [formData]="step1Form"></app-step3>
      </div>
  </div>
</div>
