import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LOCALE_ID, isDevMode } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { MatNativeDateModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import localeSr from '@angular/common/locales/sr-Latn';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';






import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './partner/navbar/navbar.component';
import { MenuComponent } from './partner/menu/menu.component';
import { PageComponent } from './partner/page/page.component';
import { GeneralComponent } from './partner/page/general/general.component';
import { PhotoComponent } from './partner/page/photo/photo.component';
import { TreatmentsComponent } from './partner/page/treatments/treatments.component';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AddtreatmentsComponent } from './partner/page/treatments/addtreatments/addtreatments.component';
import { Step1Component } from './partner/page/treatments/addtreatments/step1/step1.component';
import { Step2Component } from './partner/page/treatments/addtreatments/step2/step2.component';
import { Step3Component } from './partner/page/treatments/addtreatments/step3/step3.component';
import { ClientsComponent } from './partner/clients/clients.component';
import { ClientsListComponent } from './partner/clients/clients-list/clients-list.component';
import { ClientDetailsComponent } from './partner/clients/clients-list/client-details/client-details.component';
import { DetailsComponent } from './partner/clients/clients-list/client-details/details/details.component';
import { AppointmentComponent } from './partner/clients/clients-list/client-details/appointment/appointment.component';
import { VoucherComponent } from './partner/clients/clients-list/client-details/voucher/voucher.component';
import { RatingsComponent } from './partner/clients/clients-list/client-details/ratings/ratings.component';
import { AppointmentsComponent } from './partner/appointments/appointments.component';
import { AllComponent } from './partner/appointments/all/all.component';
import { ConfirmedComponent } from './partner/appointments/confirmed/confirmed.component';
import { PendingComponent } from './partner/appointments/pending/pending.component';
import { CanceledComponent } from './partner/appointments/canceled/canceled.component';
import { SettingsComponent } from './partner/settings/settings.component';
import { ProfileComponent } from './partner/settings/profile/profile.component';
import { PackageComponent } from './partner/settings/package/package.component';
import { WorkpeopleComponent } from './partner/settings/workpeople/workpeople.component';
import { CalendarComponent } from './partner/calendar/calendar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PartnerComponent } from './partner/partner.component';
import { HeaderComponent } from './homepage/header/header.component';
import { FooterComponent } from './homepage/footer/footer.component';
import { ClickopartnerComponent } from './clickopartner/clickopartner.component';
import { FaqComponent } from './clickopartner/faq/faq.component';
import { SignUpPartnerComponent } from './login/sign-up-partner/sign-up-partner.component';
import { LoginComponent } from './login/login/login.component';
import { SignupComponent } from './login/signup/signup.component';
import { SalonComponent } from './salon/salon.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { ClientAppointmentComponent } from './client-profile/client-appointment/client-appointment.component';
import { ClientVoucherComponent } from './client-profile/client-voucher/client-voucher.component';
import { ClientFavoriteComponent } from './client-profile/client-favorite/client-favorite.component';
import { ClientInfoComponent } from './client-profile/client-info/client-info.component';
import { DeleteAccountComponent } from './client-profile/delete-account/delete-account.component';
import { SalonServicesComponent } from './salon/salon-services/salon-services.component';
import { SalonInfoComponent } from './salon/salon-info/salon-info.component';
import { SalonReviewComponent } from './salon/salon-review/salon-review.component';
import { ScrollSpyDirective } from 'src/app/scroll-spy.directive';
import { GalleryComponent } from './salon/gallery/gallery.component';
import { PlaceComponent } from './salon/place/place.component';
import { CreateAnAppointmentComponent } from './salon/create-an-appointment/create-an-appointment.component';
import { PlaceSearchComponent } from './salon/place-search/place-search.component';
import { AddTreatmentComponent } from './salon/add-treatment/add-treatment.component';
import { ScheduleAAppointmentComponent } from './salon/schedule-a-appointment/schedule-a-appointment.component';
import { AppointmentInfoComponent } from './partner/calendar/appointment-info/appointment-info.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrevDirective } from './directives/prev.directive';
import { NextDirective } from './directives/next.directive';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AddClientComponent } from './partner/clients/clients-list/add-client/add-client.component';
import { AddAppointmentComponent } from './partner/calendar/add-appointment/add-appointment.component';
import { AddStaffComponent } from './salon/create-an-appointment/add-staff/add-staff.component';
import { AddDateComponent } from './salon/create-an-appointment/add-date/add-date.component';
import { ConfirmComponent } from './salon/create-an-appointment/confirm/confirm.component';
import { AddAccountComponent } from './salon/create-an-appointment/add-account/add-account.component';
import { EditAppointmentComponent } from './partner/appointments/edit-appointment/edit-appointment.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { StaticMapComponent } from './map/static-map.component';








registerLocaleData(localeSr);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MenuComponent,
    PageComponent,
    GeneralComponent,
    PhotoComponent,
    TreatmentsComponent,
    AddtreatmentsComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ClientsComponent,
    ClientsListComponent,
    ClientDetailsComponent,
    DetailsComponent,
    AppointmentComponent,
    VoucherComponent,
    RatingsComponent,
    AppointmentsComponent,
    AllComponent,
    ConfirmedComponent,
    PendingComponent,
    CanceledComponent,
    SettingsComponent,
    ProfileComponent,
    PackageComponent,
    WorkpeopleComponent,
    HomepageComponent,
    PartnerComponent,
    HeaderComponent,
    FooterComponent,
    ClickopartnerComponent,
    FaqComponent,
    SignUpPartnerComponent,
    LoginComponent,
    SignupComponent,
    CalendarComponent,
    SalonComponent,
    ClientProfileComponent,
    ClientAppointmentComponent,
    ClientVoucherComponent,
    ClientFavoriteComponent,
    ClientInfoComponent,
    DeleteAccountComponent,
    SalonServicesComponent,
    SalonInfoComponent,
    SalonReviewComponent,
    ScrollSpyDirective,
    GalleryComponent,
    PlaceComponent,
    CreateAnAppointmentComponent,
    PlaceSearchComponent,
    AddTreatmentComponent,
    ScheduleAAppointmentComponent,
    AppointmentInfoComponent,
    PrevDirective,
    NextDirective,
    AddClientComponent,
    AddAppointmentComponent,
    AddStaffComponent,
    AddDateComponent,
    ConfirmComponent,
    AddAccountComponent,
    EditAppointmentComponent,
    StaticMapComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ScrollingModule,
    MatInputModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
     enabled: !isDevMode(),
     // Register the ServiceWorker as soon as the application is stable
     // or after 30 seconds (whichever comes first).
     registrationStrategy: 'registerWhenStable:30000'
   }),
    BrowserAnimationsModule,
    GoogleMapsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'sr-Latn' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    DatePipe,
    CommonModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
