import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';


@Component({
  selector: 'wc-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit{
    
  PlaceDTO: any;
  UserDTO: any;
  placeID:any;
  selectedFile: File | null = null;

  photos: any[] = [];

  deletePopUp: boolean = false;
  photoID: any;

  constructor(private auth: AuthService,
    private salonService:SalonService) {}

  ngOnInit(): void {

  

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          console.log(this.UserDTO);
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
                  console.log(this.PlaceDTO);
                  
                  if (this.PlaceDTO && this.PlaceDTO.photoIDs) {
                    console.log(this.PlaceDTO.photoIDs)
                    this.getPhotos(this.PlaceDTO.photoIDs);
                   
                  }
        
                } else {
                  console.warn();
                }
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });

  }

  getPhotos(photoId: number[]): void {
    for (const photoId of this.PlaceDTO.photoIDs) {
      this.auth.getPhoto(photoId).subscribe(photo => {
        this.photos.push(photo);
      });
    }
  }

  getPhotoUrl(photoId: number): string {
    const photo = this.photos.find(app => app.id === photoId);
    return photo ? photo.url : '';
  }


  onFileChange(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.uploadFiles(files);
    }
  }
  
  uploadFiles(files: File[]) {
    const placeId = this.PlaceDTO.id;
    console.log(placeId)
    this.salonService.addPhoto(files, placeId).subscribe(
      response => {
        console.log(files);
        console.log(placeId)
        console.log(response);

        window.location.reload();
      },
      error => {
        console.error(error);
      }
    );

  }


  deleteImage(){
  const photoId = this.photoID;
  console.log(photoId)
  this.salonService.deletePhoto(photoId).subscribe({
    next: (response) => {
        window.location.reload();
    },
    error: (error) => {
        console.error(error);
    }
});
  this.deletePopUp = false;
  }

  openPopUp(photoId: number){
    console.log(photoId)
    this.deletePopUp = true;
    this.photoID = photoId;
  }
   
  closePopUp(){
    this.deletePopUp = false;

  }


}
