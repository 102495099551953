<div class="main">
  <div class="main-div">
    <div (click)="handleBack()" class="goback-btn" *ngIf="!addAccount">
      <span class="material-symbols-outlined">
         arrow_back
      </span>
    </div>
    <div class="steps"  *ngIf="!addAccount">
     <div class="step" (click)="handleBack()">
       <p [ngClass]="{ 'active': currentStep === 1, 'completed': currentStep > 1 }">Zaposleni</p>
     </div>
     <p [ngClass]="{ 'active': currentStep === 2, 'completed': currentStep > 2 }">&#x2022;</p>
     <div class="step" (click)="handleBack()">
      <p [ngClass]="{ 'active': currentStep === 2, 'completed': currentStep > 2 }"> Datum i vreme</p>
     </div>
     <p [ngClass]="{ 'active': currentStep === 3, 'completed': currentStep > 3 }">&#x2022;</p>
    <div class="step">
      <p [ngClass]="{ 'active': currentStep === 3, 'completed': currentStep > 3 }"> Potvrda</p>
    </div>
    </div>
    <div (click)="goBack()" class="close-btn" >
        <span class="material-symbols-outlined">
          close
        </span>
      </div>
 
  </div>
  <div *ngIf="currentStep === 1 && !addAccount" class="width" >
    <app-add-staff  (onNext)="nextStep()"></app-add-staff>
  </div>
  <div *ngIf="currentStep === 2 && !addAccount" class="width">
    <app-add-date  (onNext)="nextStep()" ></app-add-date>
  </div>
  <div *ngIf="currentStep === 3 && !addAccount" class="width">
    <app-confirm (onSubmit)="submitForm()" ></app-confirm>
  </div>
  <div *ngIf="addAccount">
    <app-add-account></app-add-account>
  </div>
</div>
