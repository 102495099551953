<div class="main">
  <section class="header">
    <app-header></app-header>
  </section>  
  <section class="content">
      <div class="signup"> 
        <h2>Dobrodošli</h2>
        <p class="text">Unesite vaše podatke da kreirate nalog</p>
        <form class="form-box"  [formGroup]="signupForm">
         <div class="form-information">
         <div class="input-group"> 
           <label class="label-text" for="firstName">Ime</label>
           <input class="input-text" type="text" id="firstName" formControlName="firstName" >
           <!-- <small *ngIf="signupForm.controls['firstName'].dirty && signupForm.hasError('required','firstName')">*Molimo popunite ovo polje.</small> -->
         </div> 
         <div class="input-group"> 
           <label class="label-text" for="lastName">Prezime</label>
           <input class="input-text" type="text" id="lastName"  formControlName="lastName">
         </div>  
         <div class="input-group">
           <label class="label-text" for="email">Email</label>
           <input class="input-text" type="email" id="email"  formControlName="email">
         </div>
         <div class="input-group">
           <label class="label-text" for="telephone">Broj mobilnog telefona</label>
           <input class="input-text" type="text" id="telephone"  formControlName="telephone">
         </div>
       
         <div class="input-group">
           <label class="label-text" for="password">Lozinka</label>
           <input class="input-text  password" [type]="type" id="password" formControlName="password">
           <span (click)="hideShowPass()" class="material-symbols-outlined eye">
             {{ eyeIcon }}
           </span>
         </div>
       </div>
       <button (click)="OnSignup()" class="btn" type="submit" value="Submit" >Napravite nalog</button>
       <div class="hr-p">
         <hr><p>ili</p><hr>
     </div> 
     <div class="google">
      <p> Putem Google naloga</p>
      <img src="../assets/icons/logo googleg 48dp.png" alt="">
     </div>
     </form>
     <div class="make-account">
      <p class="first-p">Imate nalog?</p>
      <p class="second-p" (click)="navigatetoLogin()">Prijavite se</p>
     </div>
  </div>
   <div class="image">
    <img src="../assets/icons/Like_Svetle_Senke 2.png" alt="">
   </div>
  
  </section>
</div>
