import { TokenApiModel } from '../models/TokenApiModel';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError  } from 'rxjs';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

   constructor(private auth: AuthService, private router: Router) {}


  

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     
    const token = this.auth.getToken();

    if(token){
      const cloned = request.clone({
        headers: request.headers.set("Authorization",
            "Bearer " + token)
    });

    return next.handle(cloned);
}
else {
    return next.handle(request);
}
}
 
}