import { Component, Input, OnInit } from '@angular/core';
import { General } from '../model/general';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { GeneralService } from 'src/app/services/partner-services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'wc-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit{
  

  PlaceDTO: any;
  UserDTO: any;
  placeID:any;
  allStaff: any;
  photo: any;

  workinTimes: any[] = [];

  item: any[] = [];

  generalForm: FormGroup;
  workingHoursForm: FormGroup;
  isFormDirty = false;
  originalFormValues: any;
  currentFormValues: any;


  constructor(private generalService: GeneralService,
              private router: Router,
              private formBuilder: FormBuilder,
              private salonService: SalonService,
              private auth: AuthService) { 

               
  }
  

  ngOnInit(): void {

 

    this.generalForm = new FormGroup({ 
      id: new FormControl(null),
      address: new FormControl(null),
      address2: new FormControl(null),
      description: new FormControl(null),
      // workingTimes: new FormGroup({
      //     monday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('PONEDELJAK'),
      //     }),
      //     tuesday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('UTORAK'),
      //     }),
      //     wednesday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('SREDA'),
      //     }),
      //     thursday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('ČETVRTAK'),
      //     }),
      //     friday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('PETAK'),
      //     }),
      //     saturday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('SUBOTA'),
      //     }),
      //     sunday: new FormGroup({
      //       start: new FormControl(null),
      //       end: new FormControl(null),
      //       day: new FormControl('NEDELJA'),
      //     })
      // })
    })

    


    this.generalForm.addControl('id', new FormControl(null));
    this.originalFormValues = this.generalForm.value;


    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          console.log(this.UserDTO);
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;

      
      
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
        

                  this.workinTimes = this.PlaceDTO.workingTimes;
                  this.workinTimes.forEach(day => {
                    if (day.start !== null && day.end !== null) {
                      day.showDiv = false;
                    } else {
                      day.showDiv = true;
                    }
                  });
                
                  this.generalForm.patchValue({
                    
                    id: this.PlaceDTO.id,
                    address: this.PlaceDTO.address,
                    address2: this.PlaceDTO.address2,
                    description: this.PlaceDTO.description,
                  
                
                  }); 
             
        
                } else {
                  console.warn();
                }
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });



  }

  transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
  }


  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file && this.PlaceDTO?.id) {
      this.salonService.addMainPhoto(file, this.PlaceDTO.id)
        .subscribe({
          next: (res) => {
            this.PlaceDTO.photoURL = res.photoURL; 
          },
          error: (err) => {
            alert( err?.error?.message);
          }
        });
    } else {
      alert('Molimo izaberite validan fajl ili osvežite stranicu.');
    }
  }



  
  onSubmit() {
    this.isFormDirty = true;
    this.currentFormValues = this.generalForm.value;
    if (this.generalForm.valid) {
      this.auth.placeInfo(this.generalForm.value)
      .subscribe({
        next:(res)=>{
         console.log(res);
        },
        error:(err)=>{
          alert(err?.error.message)
        }
       });

      }else{
        console.warn('Greska');
      }

    }

    resetForm() {
      this.generalForm.reset(this.originalFormValues);
      this.isFormDirty = false;
      window.location.reload();
    }

    onCheckboxChange(event, day: any) {
      const dayId = this.workinTimes.find(s => s.day === day);
      if (event.target.checked) {
          dayId.showDiv = false;
      } else {
          dayId.showDiv = true;
      }
    
    }
  

  }



