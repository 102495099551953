import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  
  faqItems = [
    {
      question: 'Šta je Clicko?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit...',
      active: false
    },
    {
      question: 'Koliko će me koštati?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit...',
      active: false
    },
    {
      question: 'Zašto baš Clicko, a ne ...?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit...',
      active: false
    },
    {
      question: 'Kako će mi Clicko olakšati poslovanje?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit...',
      active: false
    },
    {
      question: 'Imam li kontrolu nad odabirom klijenata?',
      answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit...',
      active: false
    }
    
  ];

  togglePanel(item: any) {
    this.faqItems.forEach((faq) => {
      if (faq !== item) {
        faq.active = false;
      }
    });
    item.active = !item.active;
  }
}
