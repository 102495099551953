<div class="main">
    <div class="heading">
        <p>Opšta podešavanja</p>
        <h3 *ngIf="PlaceDTO">{{PlaceDTO.name}}</h3>
   </div>
   <div class="heading display">
        <h4>Opšta podešavanja</h4>
   </div>
    <div class="page">
        <ul class="page-options">
            <li  class="page-options-item" routerLinkActive="active"> 
                <a routerLink="profile"  href="">Opšte</a>
            </li>
            <li  class="page-options-item" routerLinkActive="active">
                <a routerLink="package"  href="">Paket</a>
            </li>
            <li class="page-options-item" routerLinkActive="active">
                <a routerLink="workpeople"  href="">Zaposleni</a>
            </li>
        </ul>
    </div>
    <router-outlet></router-outlet>
</div>
    
    