import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/partner-services/clients.service';
import { UserService } from 'src/app/services/user/user.service';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent {
  selectedComponent: string = 'details';

  router:Router = inject(Router);
  activeRoute:ActivatedRoute = inject(ActivatedRoute);

  clientId:any;
  client: any;
  generalForm: FormGroup;

  PlaceDTO: any;
  UserDTO: any;
  placeID:any;
 

  constructor(private clientsService: ClientsService,
    private location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    private salonService: SalonService,
    private auth: AuthService
    ) {}

  
    ngOnInit(): void {

 

      this.generalForm = new FormGroup({ 
        placeId: new FormControl(null),
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        telephone: new FormControl(null),
        dateOfBirth: new FormControl(null),
        gender: new FormControl(null),
      })

      this.auth.getUser().subscribe({
        next: (data) => {
          if (data) {
            this.UserDTO = data;
            if (this.UserDTO && this.UserDTO.placeID) {
              this.placeID = this.UserDTO.placeID;
              this.auth.getSalon(this.placeID).subscribe({
                next: (data) => {
                  if (data) {
                    this.PlaceDTO = data;


                  } else {
                    console.warn();
                  }
                },
                error: (salonError) => {
                  console.error( salonError);
                }
              });
            } else {
              console.warn();
            }
          } else {
            console.warn();
          }
        },
        error: (userError) => {
          console.error( userError);
        }
      });
    
  
    }
  
  showComponent(component: string) {
    this.selectedComponent = component;
  }

  closeClient() {
    this.location.back();
  }

  
  onFileChange(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.uploadFiles(files);
    }
  }
  
  uploadFiles(files: File[]) {

   
  }


  onSubmit() {

    this.generalForm.patchValue({
      placeId: this.PlaceDTO.id
    });

      this.salonService.addGhostClient(this.generalForm.value)
      .subscribe({
        next:(res)=>{
          
          console.log(res);
          this.router.navigate(['partner/clients/clients-list']);
          
        },
        error:(err)=>{
          alert(err?.error.message)
        }
       })
   

    }
}
