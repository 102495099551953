<div class="main">
    <div class="center" *ngIf="PlaceInfo">
    <h1>O salonu</h1>
    <p class="text" *ngIf="PlaceInfo.description">
        {{PlaceInfo.description}}
    </p>
    <div class="address" *ngIf="PlaceInfo.addressCity">
        <h4>Adresa</h4>
        <p>{{PlaceInfo.addressCity}}</p> 
        <div class="map" >
            <static-map
  [address]="PlaceInfo.addressCity"
  [zoom]="15"
  [width]="600"
  [height]="300">
</static-map>
        </div>
    </div>
    <div class="worktime">
        <h4>Radno vreme</h4>
        <div class="day" *ngFor=" let workingHour of workingHours">
            <p>{{ transformWord(workingHour.day) }}</p>
            <p class="red" *ngIf="workingHour.start && workingHour.end">{{ workingHour.start }} - {{ workingHour.end }}</p>
            <p class="red" *ngIf="!workingHour.start || !workingHour.end">Neradan</p>
        </div>
    </div>
    <div class="staff">
        <h4>Zaposleni</h4>
        <div class="display" >
            <ng-container *ngIf="allStaff && allStaff.length > 0">
            <article *ngFor="let staff of allStaff">
                <div class="image">
                     <img class="img" src="{{ staff.photoUrl }}" *ngIf="staff.photoUrl">
                     <p class="initial" *ngIf="!staff.photoUrl"> {{ staff.name?.slice(0, 1) }}</p>
                </div>
                <p class="name">{{ staff.name }}</p>
                <p class="position">{{ transformWord(staff.job) }}</p>
            </article>
           </ng-container>
        </div>
    </div>
    </div>

  <section class="fixed">
    <div class="card">
    <div class="name">
        <h2>{{PlaceInfo?.name}}</h2>
        <div class="rating">{{PlaceInfo?.rating}}</div>
    </div>
    <div class="time">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405"><path d="m625.85-305.85 28.3-28.3L500-488.33V-680h-40v208.31l165.85 165.84ZM480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120ZM480-480Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/></svg>
            <p *ngIf="isOpen">Otvoreno</p>
            <p *ngIf="!isOpen">Trenutno zatvoreno</p>
        </div>
    <div class="location">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405"><path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-172.92q112.77-98.16 178.31-199.66t65.54-175.57q0-109.77-69.5-181.2-69.5-71.42-174.35-71.42t-174.35 71.42q-69.5 71.43-69.5 181.2 0 74.07 65.54 175.57T480-172.92Zm0 53.69Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Zm0-436.15Z"/></svg>
            <p class="address-card">{{PlaceInfo?.address}}</p>
    </div>
    <button>Zakaži termin</button>
   </div> 
  </section>
  
</div>