import { Component, inject, ChangeDetectorRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from '../services/salon-services/salon.service';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';
import { FavoritePlaceDTO } from '../models/FavoritePlaceDTO';

interface NextWorkingDay {
  id: string | null;
  start: string | null;
  end: string | null;
  day: string | null;
  placeID: string | null;
}

interface PlaceInfoDTO {
  id: number;
  name: string;
  open: boolean;
  nextWorkingDay: NextWorkingDay;
  address: string;
  rating: number;
  images: string[];
}


@Component({
  selector: 'app-salon',
  templateUrl: './salon.component.html',
  styleUrls: ['./salon.component.css']
})
export class SalonComponent {
  router:Router = inject(Router);

  PlaceInfo: PlaceInfoDTO | null = null;
  UserDTO: any;
  appointments: any;
  isFavorite: boolean = false;

  isOpen: boolean;
  workinHours: any;
  nextWorkingDay: NextWorkingDay;
  item: any;

  salonId: any;
  placeID: string;
 
  currentPhotoIndex: number = 0;
  favorites: any [] = [];


  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService,
    private auth: AuthService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) { this.checkFavoriteStatus();}


  
  ngOnInit(): void {
    this.route.children.forEach(child => {
    child.params.subscribe(params => {
    this.salonId = params['id'];
    this.salonService.getPlaceInfo(this.salonId).subscribe(
      (data) => {
          this.PlaceInfo = data;
          this.isOpen = this.PlaceInfo.open;
          this.nextWorkingDay = this.PlaceInfo.nextWorkingDay;
      },
      (error) => {
        console.error("", error);
      }
    );
   });
  });


  }

  checkFavoriteStatus(): void {
    const userObservable = this.auth.getUser();

    if (userObservable) {
      userObservable.subscribe(
        (userData) => {
          this.UserDTO = userData;
          this.userService.getFavoriteSalons(this.UserDTO.id).subscribe(
            (favorites: any[]) => {
              const placeID = this.PlaceInfo.id;
              this.isFavorite = this.checkFavoritePlace(favorites, placeID);
              console.log(this.isFavorite);
            
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }


  checkFavoritePlace(favorites: any[], placeID: any): boolean {
    return favorites.some(favorite => favorite.id === placeID);
  }
  

  

  transformWord(word: string): string {
    return word.toLowerCase().split(' ').join(' ').replace(/_/g, ' ');
  } 

  nextPhoto(): void {
    if (this.currentPhotoIndex < this.PlaceInfo.images.length - 1) {
      this.currentPhotoIndex++;
    }
  }
  
  previousPhoto(): void {
    if (this.currentPhotoIndex > 0) {
      this.currentPhotoIndex--;
    } else {
      this.currentPhotoIndex = this.PlaceInfo.images.length - 1;
    }
}

shareToSocial() {
  const currentUrl = window.location.href;
  if (navigator.share) {
    navigator.share({
      title: this.PlaceInfo.name,
      url: currentUrl
    })
      .then(() => console.log(currentUrl))
      .catch((error) => console.error( error));
  } else {
  }
}

toggleFavorite(): void {
  if (!this.auth.isLoggedIn()) {
    this.router.navigate(['/login']);
    return;
  }

  const placeID = this.PlaceInfo.id;
  const userID = this.UserDTO.id;

  const favoritePlaceDTO: FavoritePlaceDTO = {
    placeID: placeID,
    userID: userID,
  };

  if (this.isFavorite) {
    this.userService.deleteFromFavorites(favoritePlaceDTO).subscribe(
      () => {
        this.isFavorite = false;
        console.log(favoritePlaceDTO);
        this.cdr.detectChanges();
      },
      (error) => {
        console.error(error);
      }
    );
  } else {
    this.userService.addToFavorites(favoritePlaceDTO).subscribe(
      () => {
        this.isFavorite = true;
        console.log(favoritePlaceDTO);
        this.cdr.detectChanges();
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

getDay(type: string): string {
  switch(type) {
    case 'SREDA':
      return 'sredu';
    case 'NEDELJA':
      return 'nedelju';
    case 'SUBOTA':
      return 'subotu';
    default:
      return type;
  }
}

navigateToGallery(salonId: number): void {
  this.router.navigate(['gallery/', salonId]);
}


navigateBack(){
  this.router.navigate(['place']);
}

navigateToSearch(){
  this.router.navigate(['place-search']);
}

navigateToHomepage(){
  this.router.navigate(['homepage']);
}


}