import { Component, ElementRef, EventEmitter, HostListener, Output, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'wc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  isMenuVisible = false;
  router:Router = inject(Router);

  isDropdownOpen1: boolean = false;
  isDropdownOpen2: boolean = false;

  constructor(private renderer: Renderer2,
              private el: ElementRef,
              private auth: AuthService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenWidth();
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  hideMenu() {
    this.isMenuVisible = false;
  }

  private checkScreenWidth() {
  
    const screenWidth = window.innerWidth;
    const breakpointWidth =768;

    if (screenWidth >= breakpointWidth) {
      this.isMenuVisible = false;
      this.renderer.removeClass(document.body, 'overlay-active');
    }
  }

  toggleDropdown(dropdownId: string) {
    if (dropdownId === 'dropdown1') {
      this.isDropdownOpen1 = !this.isDropdownOpen1;
      this.isDropdownOpen2 = false;
    } else if (dropdownId === 'dropdown2') {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
      this.isDropdownOpen1 = false;
    }
  }

  closeDropdown(dropdownId: string) {
    if (dropdownId === 'dropdown1') {
      this.isDropdownOpen1 = false;
    } else if (dropdownId === 'dropdown2') {
      this.isDropdownOpen2 = false;
    }
  }

  closeMenu(){
    this.isDropdownOpen1 = false;
  }

  navigatetoHomepage(){
    this.router.navigate(['homepage']);
  }

  navigatetoPage(){
    const userRole = localStorage.getItem('userRole');

    if (userRole === 'OWNER') {
      this.router.navigate(['partner']);
    } else if (userRole === 'CLICKO_USER') {
      this.router.navigate(['client']);
    } else {
      this.router.navigate(['homepage']);
    }
  }

  logout(){
    this.auth.signout();
   }

}
