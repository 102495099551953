import { Component, Input, ViewChild } from '@angular/core';
import { FormService } from 'src/app/services/form/form.service';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'wc-addtreatments',
  templateUrl: './addtreatments.component.html',
  styleUrls: ['./addtreatments.component.css']
})
export class AddtreatmentsComponent {

  @ViewChild(Step1Component) step1: Step1Component;
  @ViewChild(Step2Component) step2: Step2Component;
  currentStep = 1;
  previousStep = 0
 
  @Input() step1Form: FormGroup;

  @Input() step2Form: FormGroup;

  @Input() step3Form: FormGroup;
  
  constructor(private treatmentsService: TreatmentsService,
    private formService: FormService) {

    }

  closeTreatment() {
    this.treatmentsService.closeTreatment();
  }


  nextStep(event: any) {
    this.previousStep = this.currentStep;
    this.currentStep++;
    this.step1Form = event;
  }

  handleBack() {
    this.currentStep--;
    this.previousStep = this.currentStep;
  }


  submitForm() {
    
  }
}
