import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { Location } from '@angular/common';
import { AppointmentCategoryPlaceDTO } from 'src/app/models/AppointmentCategoryPlaceDTO';

@Component({
  selector: 'app-add-treatment',
  templateUrl: './add-treatment.component.html',
  styleUrls: ['./add-treatment.component.css']
})
export class AddTreatmentComponent {
  router:Router = inject(Router)

  PlaceDTO: any;
  appointmentIDs: number[] = [];
  appointments: any[] = [];
  categories: any[] = [];

  salonId: any;

  appointmentCategoryPlaceDTO: AppointmentCategoryPlaceDTO;


  constructor(
    public salonService: SalonService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private location: Location
    ) {}

    ngOnInit(): void {
    
      console.log(this.route.snapshot.paramMap.get('id')); 
      this.route.params.subscribe(params => {
        this.salonId = params['id'];
        console.log(this.salonId);
     
        this.salonService.getAppointmentsList(this.salonId).subscribe(
        (data) => {
            this.appointmentCategoryPlaceDTO = data;
            console.log(this.appointmentCategoryPlaceDTO);
            this.appointments = this.appointmentCategoryPlaceDTO.appointments;
            console.log(this.appointments);
        },
        (error) => {
          console.error("", error);
        }
      );
     });
    }
  
    transformWord(word: string): string {
      return word.toLowerCase().split(' ').map(word => { 
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ').replace(/_/g, ' ');
    } 

  

    addTreatment(appointment: any) {
  
      const treatment = {
        id: appointment.id,
        name: appointment.name,
        time: appointment.time,
        price: appointment.price,
        term: this.selectedTerm,
          
      };
     
      this.salonService.addTreatment(treatment);
      this.location.back();

      console.log(this.salonService.selectedTreatment);

} 

get selectedTerm() {
return this.salonService.selectedTerm;
}

  goBack(){
    this.location.back();
  }

  navigateToCreateAnAppointment(){
    this.location.back();
 }



}
