<div class="box">
   <div class="center">
       <div class="profile-box">
             <div class="contract">
                <div class="div-flex">
                   <h5>Ugovorna obaveza</h5>
                   <div>
                     <div class="date">
                       <p class="start-date">{{ formatDate(Package?.created) }}</p>
                       <p class="end-date">{{ formatDate(endDate) }}</p>
                     </div>
                     <div class="line">
                        <div class="contract-line"  #contractProgress></div>
                     </div>
                   </div>
                 </div>
                 <div class="div-flex">
                    <p>Broj ugovora: <span></span></p>
                    <p *ngIf="Package">Korisnik od: <span>{{ formatDate(Package.created) }}</span></p>
                    <p>Trenutna zaduženja: <span>nema</span></p>
                 </div>
             </div>
             <div class="card" *ngIf="Package">
                 <p>Trenutni paket</p>
                 <h5>{{getPackageType(Package.clickoPackage)}}</h5>
                 <p class="more-info" (click)="openPackageInfo()">Više informacija o paketu</p>
             </div>
        </div>
        <div class="btn-div">
           <button>Moj ugovor</button>
           <p>Zahtev za promenu paketa</p>
        </div>
  </div>
   <div *ngIf="openPackage" (click)="closePackageInfo()"
   class="overlay" id="overlay">
   </div>
   <div class="packages" *ngIf="openPackage">
      <div class=" close-btn">
         <span class="material-symbols-outlined" (click)="closePackageInfo()" >
           close
         </span>
      </div>
      <div class="scroll">
         <h1>Vaš Clicko paket</h1>
         <div class="package">
            <div class="image">
                  <img src="../assets/icons/image 3.png" alt="">
                  <p>Clicko</p>
                  <h1>{{this.currentPackage.name}}</h1>
            </div>
            <div class="price-div">
               <div class="price">{{this.currentPackage.price}} RSD</div>  
               <div class="arrow" (click)="toggleList(currentPackage)">
                  <span class="material-symbols-outlined" [class.rotated]="currentPackage.isListVisible">
                     arrow_back
                  </span>
               </div>      
            </div>
            <div class="list"  [class.visible]="currentPackage.isListVisible">
               <ul>
                  <li *ngFor="let info of currentPackage.infoList">
                     {{ info }}
                  </li>
               </ul>
            </div>
         </div>
         <h5>Ostali Clicko paketi</h5>
         <div class="second">
            <div class="package">
               <div class="image">
                     <img src="../assets/icons/image 3.png" alt="">
                     <p>Clicko</p>
                     <h1>{{this.secondPackage.name}}</h1>
               </div>
               <div class="price-div">
                  <div class="price">{{this.secondPackage.price}} RSD</div>  
                  <div class="arrow" (click)="toggleList(secondPackage)">
                     <span class="material-symbols-outlined" [class.rotated]="secondPackage.isListVisible">
                        arrow_back
                     </span>
                  </div>      
               </div>
               <div class="list" [class.visible]="secondPackage.isListVisible">
                  <ul>
                     <li *ngFor="let info of this.secondPackage.infoList">
                        {{ info }}
                     </li>
                  </ul>
               </div>
            </div>
            <div class="package">
               <div class="image">
                     <img src="../assets/icons/image 3.png" alt="">
                     <p>Clicko</p>
                     <h1>{{this.thirdPackage.name}}</h1>
               </div>
               <div class="price-div">
                  <div class="price">{{this.thirdPackage.price}} RSD</div>  
                  <div class="arrow" (click)="toggleList(thirdPackage)">
                     <span class="material-symbols-outlined" [class.rotated]="thirdPackage.isListVisible">
                        arrow_back
                     </span>
                  </div>      
               </div>
               <div class="list" [class.visible]="thirdPackage.isListVisible">
                  <ul>
                     <li *ngFor="let info of this.thirdPackage.infoList">
                        {{ info }}
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <p class="change">Zahtev za promenu paketa</p>
      </div>
   </div>
 </div>
 