<div class="flex">
    <section class="card-info" *ngIf="UserDTO">
        <div class="div1" >
            <div class="img">
                 <div class="file-upload">
                    <input type="file" (change)="onFileChange($event)" id="fileInput" />
                  </div>
                  <img   [src]="UserDTO.profilePhoto" alt="User Image">
                  <ng-template *ngIf="!UserDTO.profilePhoto">
                    <p class="initial"> {{ UserDTO.firstName?.slice(0, 1) }}</p>
                  </ng-template>
            </div>
            <h2 class="name">{{ UserDTO.firstName }} {{ UserDTO.lastName }}</h2>
        </div>
        <div class="div2">
            <div>
                <p class="title">Datum rođenja</p>
                <p class="data">{{ dateTranform(UserDTO.dateOfBirth) }}</p>
            </div>
            <div >
                <p class="title">Pol</p>
                <p class="data">{{ transformWord(UserDTO.gender) }}</p>
            </div>
            <div>
                <p class="title">Broj telefona</p>
                <p class="data">{{ UserDTO.telephone }}</p>
            </div>
            <div >
                <p class="title">Email adresa</p>
                <p class="data">{{ UserDTO.email }}</p>
            </div>
        </div>
        <div class="btns">
            <button class="delete-btn" (click)="navigateToDeleteAccount()">
                <span class="material-symbols-outlined">
                    delete
                </span>
                Obriši nalog
            </button>
            <button class="signout-btn" (click)="logout()">
                Odjavi me
            </button>
        </div>
        <div class="btn-edit">
            <button (click)="openEditDiv()">
                <span class="material-symbols-outlined">
                    edit
                </span>
                Uredi
            </button>
        </div>
    </section>
    <section class="info">
         <div class="edit" *ngIf="openEdit">
            <div class="scroll">
                <div class="div-btn">
                    <button  (click)="exitEditDiv()" class="back-btn">
                     <span class="material-symbols-outlined">
                       close
                    </span>
                   </button>
               </div>
                <h4>Osnovne informacije</h4>
                <form class="form-box"  [formGroup]="form" >
                    <div class="form-information">
                    <div class="input-group"> 
                      <label class="label-text" for="name">Ime</label>
                      <input class="input-text" type="text" id="name" formControlName="firstName"   >
                    </div>  
                    <div class="input-group">
                      <label class="label-text" for="last-name">Prezime</label>
                      <input class="input-text" type="text" id="last-name" formControlName="lastName" > 
                    </div>
                    <div class="input-group">
                      <label class="label-text" for="telephone">Broj telefona</label>
                      <input class="input-text" type="text" id="telephone" formControlName="telephone" >
                    </div>
                    <div class="input-group">
                      <label class="label-text" for="email">Email adresa</label>
                      <input class="input-text" type="email" id="email" formControlName="email" email>
                    </div>
                    <div class="input-group">
                      <label class="label-text" for="date">Datum rođenja</label>
                      <input class="input-text" type="date" id="date" formControlName="dateOfBirth">
                    </div>
                    <div class="select-group">
                        <label class="label-text" for="gender">Pol</label>
                        <select   id="gender" formControlName="gender" >
                            <option class="option" value="ŽENSKI">Ženski</option>
                            <option class="option" value="MUŠKI">Muški</option> 
                          </select>
                    </div>
                    <button type="submit" (click)="OnFormSubmitted()" class="save">Sačuvaj</button>
                  </div>
                </form>
            </div>
           
         </div>
         <div class="gap">
            <h4>Notifikacije</h4>
            <div class="notifications">
                <div class="width">
                    <p class="sibtitle">Email obaveštenje</p>
                    <p class="description">Pošalji mi email obaveštenje o predstojećem terminu</p>
                </div>
                <label class="switch">
                    <input type="checkbox" [checked]="notification" (change)="onCheckboxChangeNotification($event)">
                    <span class="slider"></span>
                </label>
            </div>
            <div class="notifications">
                <div class="width">
                    <p class="sibtitle">Email promocije</p>
                    <p class="description">Želim da dobijam putem email-a promocije, popuste i novosti</p>
                </div>
                <label class="switch">
                    <input type="checkbox" [checked]="promotion" (change)="onCheckboxChangePromotion($event)">
                    <span class="slider"></span>
                </label>
            </div>
         </div>
         <div class="account">
            <h4>Povezani nalozi</h4>
            <p class="p">Poveži druge naloge za lakši pristup Clicko nalogu</p>
            <div class="google">
                <div>
                    <img src="../assets/icons/logo googleg 48dp.png" alt="">
                    <p>Google nalog</p>
                </div>
            </div>
         </div>
    </section>
    <div class="btns-mobile">
        <button class="delete-btn" (click)="navigateToDeleteAccount()">
            Obriši nalog
        </button>
        <button class="signout-btn" (click)="logout()" >
            Odjavi me
        </button>
    </div>
</div>

