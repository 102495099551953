import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent {
  router:Router = inject(Router);

  constructor(private location: Location) { }

  navigatetoHomepage(){
    this.router.navigate(['homepage']);
  }

  


  
}
