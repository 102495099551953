import { Component } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'app-appointment-info',
  templateUrl: './appointment-info.component.html',
  styleUrls: ['./appointment-info.component.css'],
  providers: [DatePipe]
})
export class AppointmentInfoComponent {

  appointmentId: any;
  appointment: any;
  selectedStatus: any;
   
  constructor(private location: Location,
    private route: ActivatedRoute,
    public salonService: SalonService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.appointmentId = params['id'];
      this.salonService.getBookedAppointment(this.appointmentId).subscribe({
                    next: (data) => {
                      this.appointment = data;   
                      this.selectedStatus = this.appointment.attended ? this.appointment.attended : 'NULL';
            },
                    error: (data) => {
                     
                    }
           });
    
          });
      }

      formatTime(startTime: string, duration: number): string {
        let startHours: number;
        let startMinutes: number;
      
        if (typeof startTime === 'string') {
          const parts = startTime.split(':');
          startHours = parseInt(parts[0], 10);
          startMinutes = parseInt(parts[1], 10);
        } else if (typeof startTime === 'number') {
          startHours = Math.floor(startTime / 60);
          startMinutes = startTime % 60;
        } else {
          return '';
        }
      
        let endHours = startHours;
        let endMinutes = startMinutes + duration;
        if (endMinutes >= 60) {
          endHours += Math.floor(endMinutes / 60);
          endMinutes = endMinutes % 60;
        }
      
        return `${startHours}:${startMinutes < 10 ? '0' + startMinutes : startMinutes} - ${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`;
      }

      
      parseDate(dateString: string): Date {
        const parts = dateString.split('-');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; 
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      }
    
      formatDate(dateString: string): string {
        const dateObject = this.parseDate(dateString);
        if (isNaN(dateObject.getTime())) {
          console.error("Invalid date:", dateString);
          return "Invalid Date";
        }
        return this.datePipe.transform(dateObject, 'EEEE, d MMMM') || "Invalid Date";
      } 

      convertDateTime(dateString: string, timeString: string): Date {
        const dateParts = dateString.split('-');
        const timeParts = timeString.split(':');
        
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; 
        const year = parseInt(dateParts[2], 10);
      
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
      
        return new Date(year, month, day, hours, minutes);
      }

      isAppointmentPast(): boolean {
        const appointmentDateTime = this.convertDateTime(this.appointment.date, this.appointment.time);
        const now = new Date();
        return appointmentDateTime < now;
      }


      editAppointment(selectedStatus: string){
        const id = this.appointmentId;
        console.log(selectedStatus, id);
         this.salonService.updateAttended(id, selectedStatus).subscribe({
          next: (response) => {
               console.log(response);
               window.location.reload();
              this.location.back();
            
          },
          error: (error) => {
              console.error(error);
          }
      });
      
      }

  navigateBack(): void {
    this.location.back();
  }
}
