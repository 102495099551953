<div class="main">
    <div class="btn-div">
        <button (click)="goBack()" class="close-btn">
          <span class="material-symbols-outlined">
            close
          </span>
        </button>
    </div>
    <div class="box">
      <div class="image-container" *ngFor="let photo of photos">
        <img class="photoId" *ngIf="photo" [src]="getPhotoUrl(photo.id)" />
    </div>
    </div> 
</div>