import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClientsService } from 'src/app/services/partner-services/clients.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { Location } from '@angular/common';

@Component({
  selector: 'wc-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.css']
})
export class ClientsListComponent {
  
  PlaceDTO: any;
  UserDTO: any;
  placeID:any;
  clients: any[] = [];
  originalClients: any[] = [];
  filterApplied: boolean = false;

  filterPopUp: boolean = false;

  searchTerm: string = '';

  
  router:Router = inject(Router);
  activeRoute:ActivatedRoute = inject(ActivatedRoute);


  constructor(public clientService: ClientsService,
              private auth:AuthService,
              private location: Location,
              private salonService: SalonService) {
 
  }

  ngOnInit(): void {

  

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
  
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
                 
                } else {
                  console.warn();
                }
                this.salonService.getClients(this.placeID).subscribe(
                  (data) => {
                    this.clients = data;
            
                  },
                  (error) => {
                    console.error( error);
                  }
                );
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });

  }

  getClientType(type: string): string {
    switch(type) {
      case 'CLICKO_USER':
        return 'Clicko korisnik';
      case 'GHOST_USER':
        return 'Ghost korisnik';
      case 'STAFF':
        return 'Zaposleni';
      case 'OWNER':
        return 'Vlasnik';
      default:
        return type;
    }
  }

  
  get filteredClients(): any[] {
    const searchTerms = this.searchTerm.toLowerCase().split(' ');
    return this.clients.filter(client =>
      searchTerms.every(term =>
        (client.firstName && client.firstName.toLowerCase().includes(term)) ||
        (client.lastName && client.lastName.toLowerCase().includes(term)) ||
        (client.telephone && client.telephone.toLowerCase().includes(term))
      )
    );
  }

  toggleFilter(filter: string) {

    console.log(filter);
    if (!this.originalClients.length) {
      this.originalClients = [...this.clients];
    }
    const filteredClients = this.clients.filter(client => client.role === filter);
    this.clients = filteredClients;
  
    this.filterApplied = true;
    this.filterPopUp = false;
  }

  transformWord(word) {
    if(word){
      return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
    }
  
  }

  openClient(clientId: string) {
    this.router.navigate(['client-info', clientId],  { relativeTo:this.activeRoute});
    console.log(clientId)
    this.clientService.openClient();
  }

  addClient() {
    this.router.navigate(['add-client'],  { relativeTo:this.activeRoute});
  }

  closeClient(){
    this.location.back();
    this.clientService.closeClient();
  }

  openFilter(){
    if (this.filterApplied) {
      this.clients = [...this.originalClients];
      this.filterApplied = false;
    }

    this.filterPopUp = true;
  }

  closeFilterPopUp(){
    if (!this.filterApplied) {
      this.clients = [...this.originalClients];
    }
    this.filterPopUp = false;
  }
}
