import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreatmentsService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  public isOpen$: Observable<boolean> = this.isOpenSubject.asObservable();

  openTreatment() {
    this.isOpenSubject.next(true);
  }

  closeTreatment() {
    this.isOpenSubject.next(false);
  }


  constructor() { }
}
