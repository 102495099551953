<div class="main">
   <div class="wrap">
   <article *ngFor="let fav of favorite" (click)="navigatetoSalon(fav.id)">
      <div class="img-container">
         <img *ngIf="fav.photoUrl" src="{{fav.photoUrl}}" alt="Place Photo">
      </div>
      <div class="flex">
          <p class="name">{{fav.name}}</p>
          <div class="rating">{{fav.rating}}</div>
      </div>
           <p class="address">{{fav.addressCity}}</p>
   </article>

  </div>
</div>
