<div class="main">
  <div class="center">
    <div class="btn-div">
      <button  (click)="exit()" class="back-btn">
        <span class="material-symbols-outlined">
          arrow_back
       </span>
      </button>

  </div>
  <section class="display">
    <div class="confirm-treatment">
      <h5>Dodavanje novog termina</h5>
      <div class="list">
        <div class="treatment"   *ngIf="this.selectedTreatment"
        [ngClass]="{ 'selected': isTreatmentSelected && this.selectedTreatment === selectedTreatment }"
        >
          <p>{{ this.selectedTreatment.name }}</p>

        </div>
        <button type="button"  class="add-btn" (click)="navigateToAddTreatment(placeID)">
            <span class="material-symbols-outlined btnicon">
                add
            </span>
            <p>
               Dodaj tretman
            </p>
        </button>
    </div>
      <div class="input-group"> 
          <label class="label-text" for="name">Ime i prezime klijenta</label>
          <input type="search" class="input-text" [formControl]="searchControl" >
          <ul *ngIf="isClientsListVisible && (filteredClients$ | async) as filteredClients">
            <li *ngFor="let client of filteredClients"  (click)="selectClient(client)">{{ client.firstName }} {{ client.lastName }}</li>
          </ul>
        </div>  

  
    <section class="staff">
       <p>Zaposleni</p>
        <div class="staff-div">
         <article *ngFor="let staff of allStaff " (click)="selectUser(staff)"  [ngClass]="{ 'selected-div': isUserSelected && staff === selectedUser}">
             <div class="image">
               <img class="img" src="{{ staff.photoUrl }}" *ngIf="staff.photoUrl">
               <p class="initial" *ngIf="!staff.photoUrl"> {{ staff.name?.slice(0, 1) }}</p>
             </div>
             <p class="name">{{ staff.name }}</p>
             <p class="position">{{ transformWord(staff.job) }}</p>
         </article>
       </div>
      </section>
     </div>
  
    <article class="calendar">
      <div class="first-div">
          <p>Odaberite termin</p>
        <div>
          <h2>{{ getMonthYearString(startOfWeek) }}</h2>
        </div>
      </div>
      <div class="second-div">
         <div class="week">
            <p class="week-date web" > {{ formatDate(startOfWeek) }} - {{ formatDate(endOfWeek) }} </p>
            <div class="arrows">
              <span class="material-symbols-outlined web" (click)="prevWeek()">
                chevron_left 
              </span>
              <span class="material-symbols-outlined web" (click)="nextWeek()">
                chevron_right
              </span>
            </div>
         </div>
         <div *ngIf="!isMobile; else mobileView">
          <div class="days">
            <div class="day" *ngFor="let day of weekDays; let i = index" [ngClass]="{'past-date': isPastDate(startOfWeek, i), 'current-date': isCurrentDate(startOfWeek, i)}">
              <div class="circle">{{ getCircleDate(startOfWeek, i) }}</div>
              <p>{{ day }}</p>
            </div>
          </div>
        </div>
        <ng-template #mobileView>
          <div class="days">
            <div class="day" *ngFor="let day of generateMobileDays(); let i = index" [ngClass]="{'past-date': isPastDate(startOfWeek, i), 'current-date': isCurrentDate(startOfWeek, i)}">
              <div class="circle">{{ getCircleDate(startOfWeek, i) }}</div>
              <p>{{ day }}</p>
            </div>
          </div>
        </ng-template>
        <div class="timetable"> 
          <div class="scroll">
            <div class="terms"  *ngFor="let date of weekDates; let i = index">
              <div *ngIf="dateData[date]" class="gap">
                <div *ngFor="let time of generateTimeSlots(dateData[date].start, dateData[date].end)">
                  <div class="term" 
                  [class.disabled]="isDisabled(date, time)"
                  [class.selected-term]="term === time && termDate === date"
                   (click)="!isDisabled(date, time) && selectTerm(time, date)">
                    {{ time }}
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
  <div class="button-box" > 
    <button type="button" class="discard-button" (click)="goBack()">Odustani</button>
    <button type="submit" class="save-button" (click)="bookAppointment()">Dodaj termin</button> 
    </div>  
  </div>
</div>