import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
   ) {
   
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
    const expectedRole = route.data['expectedRole'];

   
    if (this.auth.isLoggedIn()) {
      const userRole = localStorage.getItem('userRole');
      
      
      if (userRole === expectedRole) {
        return true;
      } else {
       
        this.router.navigate(['/login']);
        return false;
      }
    } else {
     
      this.router.navigate(['/login']);
      return false;
    }
}

}