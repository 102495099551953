import { Component } from '@angular/core';

@Component({
  selector: 'wc-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent {

}
