<div class="main">
    <section class="service">
       <h1>Usluge</h1>
       <div class="mobile-category">
        <div class="container" *ngIf="appointmentCategoryPlaceDTO">
          <div class="container-div" *ngFor="let category of appointmentCategoryPlaceDTO.categories" 
           (click)="scrollToCategory(category)">
             {{ transformWord(category) }}
          </div>
         </div>
        </div>  
       <div class="all-categories" *ngIf="appointmentCategoryPlaceDTO">
       <div class="categories-name" *ngFor="let categories of appointmentCategoryPlaceDTO.categories">
         <h4 #categoryHeaders >{{ transformWord(categories) }}</h4>
         <div class="all-treatments" >
            <ng-container *ngFor="let appointment of appointments">
            <div class="treatment" *ngIf="transformWord(appointment.category) === transformWord(categories)">
                <div class="text">
                    <p class="full-name"> {{ appointment.name }}</p>
                    <p class="grey"  *ngIf="appointment.time">{{ appointment.time }}min</p>
                    <p class="price" *ngIf="appointment.price">{{ appointment.price }} RSD</p>
                </div>
                <div class="add" (click)="addTreatment(appointment)" (click)="navigateToCreateAnAppointment(salonId)">
                    <p>Zakaži</p>
                </div>  
            </div>
          </ng-container>
         </div>
       </div>
    </div>
    </section>
    <section class="fixed">
      <div class="categories" *ngIf="appointmentCategoryPlaceDTO">
         <h4>Sve kategorije</h4>
         <ul>
            <li *ngFor="let category of appointmentCategoryPlaceDTO.categories" 
           (click)="scrollToCategory(category)">
             {{ transformWord(category) }}
            </li>
         </ul>
      </div>
    </section>
    <section>
      <div *ngIf="salonService.showAppointment$ | async"
      class="overlay" id="overlay">
    </div>
    <app-create-an-appointment  *ngIf="salonService.showAppointment$ | async"></app-create-an-appointment>
    </section>
    
</div>