
<div class="main">
   <section class="header">
     <app-header></app-header>
   </section>  
   <section class="content">
    <div class="signup"> 
      <h2>Dobrodošli nazad</h2>
      <p class="text">Unesite vaše podatke da se prijavite</p>
      <form class="form-box"  [formGroup]="loginForm">
       <div class="form-information">
       <div class="input-group">
         <label class="label-text" for="email">Email </label>
         <input formControlName="email" class="input-text" type="email" id="email" >
       </div>
       <div class="input-group">
         <label class="label-text" for="password">Lozinka</label>
         <input formControlName="password" class="input-text  password" [type]="type" id="password">
         <span (click)="hideShowPass()" class="material-symbols-outlined eye">
           {{ eyeIcon }}
         </span>
       </div>
     </div>
     <div class="flex-row">
       <div class="custom-checkbox">
         <input type="checkbox" name="" id="checkbox">
         <label for="checkbox">Zapamti me</label>
       </div>
       <p class="forgot-password">Zaboravili ste lozinku?</p>
     </div>
     <button (click)="OnLogin()" class="btn" type="submit" value="Submit">Prijavi se</button>
      <div class="hr-p">
          <hr><p>ili</p><hr>
      </div> 
      <div class="google">
       <p> Putem Google naloga</p>
       <img src="../assets/icons/logo googleg 48dp.png" alt="">
      </div>
      </form>
      <div class="make-account">
       <p class="first-p">Nemate nalog?</p>
       <p class="second-p" (click)="navigatetoSignup()">Napravite nalog</p>
     </div>
   </div>
  
  <div class="image">
   <img src="../assets/icons/Gleda_Telefon 1.png" alt="">
  </div>
   </section>     
</div>