import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

interface Package {
  name: string;
  price: string;
  infoList: string[];
}

@Component({
  selector: 'wc-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent {
  @ViewChild('contractProgress', { static: false }) contractProgress!: ElementRef;
  
  openPackage: boolean = false;

  PlaceDTO: any;
  UserDTO: any;
  placeID:any;

  Package: any;

  endDate: '2025-02-20';
  startDate: any;

  dataLoaded: boolean = false;


  progressLine:number;
  progressLineWidth: any;

  currentPackage: any;
  secondPackage : any;
  thirdPackage: any;

  startPackage = {
    name: "Start",
    price: "2 000 RSD",
    infoList: [
      "Neograničen broj usluga",
      "Neograničen broj mesečnih zakazivanja",
      "Jedan zaposleni",
      "E-mail obaveštenja",
      "Privatni profil",
      "Booking kalendar",
      "Samostalno unošenje usluga"
    ],
    isListVisible: false
  };
  
  standardPackage = {
    name: "Standard",
    price: "1500 RSD",
    infoList: [
      "Viber i sms obavestenje",
      "Neograničen broj radnika",
      "Javni profil",
      "Clicko stiker sa QR kodom",
      "Analitika"
    ],
    isListVisible: false
  };
  
  premiumPackage = {
    name: "Premium",
    price: "4 000 RSD",
    infoList: [
      "Sve iz Clicko Standard, plus",
      "Podrska 24/7",
      "Istaknutost pri pretrazi",
      "Usluzno unosenje usluga"
    ],
    isListVisible: false
  };

  constructor(
    private router: Router,
    private salonService: SalonService,
    private auth: AuthService) { 

   
  
    
}

ngOnInit(): void {

  this.auth.getUser().subscribe({
    next: (data) => {
      if (data) {
        this.UserDTO = data;
        if (this.UserDTO && this.UserDTO.placeID) {
          this.placeID = this.UserDTO.placeID;
          this.auth.getSalon(this.placeID).subscribe({
            next: (data) => {
              if (data) {
                this.PlaceDTO = data;
              } else {
                console.warn();
              }
            },
            error: (salonError) => {
              console.error( salonError);
            }
          });
          this.salonService.getPackage(this.placeID).subscribe({
            next: (data) => {
              if (data) {
                this.Package = data;
                this.startDate = this.Package.created;
                this.dataLoaded = true;

                this.displayPackage(this.Package.clickoPackage);

              } else {
                console.warn();
              }
            },
            error: (salonError) => {
              console.error( salonError);
            }
          });
        } else {
          console.warn();
        }
      } else {
        console.warn();
      }
    },
    error: (userError) => {
      console.error( userError);
    }
  });

  this.endDate = '2025-02-20';

  
}

getPackageType(type: string): string {
  switch(type) {
    case 'VIP':
      return 'Premium';
    case 'STANDARD':
      return 'Standard';
    case 'PRIVATE':
      return 'Start';
    default:
      return type;
  }
}

displayPackage(packageType: string): void {
  switch(packageType) {
    case 'VIP':
      this.currentPackage = this.premiumPackage;
      this.secondPackage = this.startPackage;
      this.thirdPackage = this.standardPackage;
      break;
    case 'STANDARD':
      this.currentPackage = this.standardPackage;
      this.secondPackage = this.startPackage;
      this.thirdPackage = this.premiumPackage;
      break;
    case 'PRIVATE':
      this.currentPackage = this.startPackage;
      this.secondPackage = this.standardPackage;
      this.thirdPackage = this.premiumPackage;
      break;
    default:
      this.currentPackage = null;
      this.secondPackage = null;
      this.thirdPackage = null;
      break;
  }
}

toggleList(pak):any {
  pak.isListVisible = !pak.isListVisible;
}


formatDate(dateString: string): string {
  if (!dateString) {
    throw new Error();
  }
  
  const [year, month, day] = dateString.split('-');
  const isValidDate = !isNaN(Date.parse(dateString));
  
  if (!isValidDate) {
    throw new Error('Invalid date format.');
  }
  
  return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}.`;
}

calculateProgress() {


  const currentTime = new Date().getTime();
  const startDate = new Date(this.startDate);
  const startTime = startDate.getTime();

  const endDate = new Date(this.endDate);
  const endTime = endDate.getTime();

  const totalTime = endTime - startTime;
  const elapsedTime = currentTime - startTime;

  this.progressLine = Math.round((elapsedTime / totalTime) * 100);

  if (this.progressLine <= 0) {
    const progressLinePlus = Math.abs(this.progressLine);
    this.progressLineWidth = progressLinePlus + '%';
  } else {
    this.progressLineWidth = this.progressLine + '%';
  }

  if (this.contractProgress) {
    this.contractProgress.nativeElement.style.width = this.progressLineWidth;
  }
}

ngAfterViewInit() {
  if (this.dataLoaded) {
    this.calculateProgress();
  }
  if (this.contractProgress) {
    this.contractProgress.nativeElement.style.width = this.progressLineWidth.toString();
   
  } 
}

openPackageInfo(){
  this.openPackage = true;
}

closePackageInfo(){
  this.openPackage = false;
}

showDivOne(){

}

}
