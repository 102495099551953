<div class="full-page-overlay">
      <div class="client">
        <div class="info">
            <div class="image">
                <div class="img">
                    <img  *ngIf="client.profilePhoto"   [src]="client.profilePhoto" alt="User Image">
                    <p *ngIf="!client.profilePhoto" class="initial"> {{ client.firstName?.slice(0, 1) }}</p> 
                </div>
            </div>
            <h3>{{client.firstName}} {{client.lastName}}</h3>
            <p>{{client.telephone}}</p>
            <div class="icons">
                <span class="material-symbols-outlined">
                    calendar_add_on
                </span>
                <span class="material-symbols-outlined">
                    mail
                </span>
            </div>
        </div>
        <div class="page">
            <ul class="page-options">
                <li class="page-options-item" > 
                    <a  (click)="showComponent('details')" [class.active]="selectedComponent === 'details'">Detalji </a>
                </li>
                <li  class="page-options-item" >
                    <a (click)="showComponent('appointment')" [class.active]="selectedComponent === 'appointment'">Termini</a>
                </li>
                <li  class="page-options-item disabled" >
                    <!-- (click)="showComponent('voucher')" -->
                    <a  [class.active]="selectedComponent === 'voucher'">Vaučeri</a>
                </li>
                <li  class="page-options-item disabled" >
                    <!-- (click)="showComponent('ratings')" -->
                    <a  [class.active]="selectedComponent === 'ratings'">Ocene</a>
                </li>
            </ul>
        </div>
        <div class="menu">
            <a  (click)="showComponent('details')" [class.active]="selectedComponent === 'details'" >
                Detalji 
            </a>
            <a  (click)="showComponent('appointment')" [class.active]="selectedComponent === 'appointment'" >
                Termini
            </a>
            <a  (click)="showComponent('voucher')" [class.active]="selectedComponent === 'voucher'">
               Vaučeri
            </a>
            <a  (click)="showComponent('ratings')" [class.active]="selectedComponent === 'ratings'">
               Ocene
            </a>
        </div>
        <div class="delete">
            <span class="material-symbols-outlined">
                delete
            </span>
            <p>Obriši</p>
        </div>
      </div>
      <div class="details">
          <button (click)="closeClient()" class="close-btn">
             <span class="material-symbols-outlined">
               close
             </span>
          </button>
          <div class="scroll">
            <div *ngIf="selectedComponent === 'details'">
                <wc-details ></wc-details>
            </div>
            <div *ngIf="selectedComponent === 'appointment'">
                <wc-appointment></wc-appointment>
            </div>
            <div *ngIf="selectedComponent === 'voucher'">
                <wc-voucher></wc-voucher>
            </div>
            <div *ngIf="selectedComponent === 'ratings'">
                <wc-ratings></wc-ratings>
            </div>
          </div>
      </div>
    </div>