import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  isClientOpen = false;

  openClient() {
    this.isClientOpen = true;
  }

  closeClient() {
    this.isClientOpen = false;
  }

}
