import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private currentStepSubject = new BehaviorSubject<number>(1);
  currentStep$ = this.currentStepSubject.asObservable();

  nextStep() {
    this.currentStepSubject.next(this.currentStepSubject.value + 1);
  }

  setCurrentStep(step: number) {
    this.currentStepSubject.next(step);
  }
}
