import { Component, ElementRef, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HomeDTO } from 'src/app/models/homepage';
import { Observable, map, startWith } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { HomepageService } from 'src/app/services/homepage/homepage.service';
import ValidateForm from 'src/app/helpers/validateform';

@Component({
  selector: 'app-place-search',
  templateUrl: './place-search.component.html',
  styleUrls: ['./place-search.component.css']
})
export class PlaceSearchComponent {
  router:Router = inject(Router)
  hovered: boolean = false;

  homeDTO: HomeDTO;
  cityList: any[] = [];
  categoriesList: any[] = [];
  
  filteredCities$: Observable<any[]>;
  filteredCategories$: Observable<any[]>;
  searchForm: FormGroup;
  isCityListVisible = false;
  isCategoryListVisible = false;

  constructor(
    private salonService: SalonService,
    private homepageService: HomepageService,
    private elementRef: ElementRef,
    private location: Location,
    private renderer: Renderer2,
    private fb: FormBuilder) {
    
      this.searchForm = this.fb.group({
        category: new FormControl(''),
        city: new FormControl(null),
        date: new FormControl(null),
      });
  }

  ngOnInit(): void {


    this.homepageService.homepageApi().subscribe({
      next: (data) => {
        this.homeDTO = data;
        this.cityList = data.city;
        this.categoriesList = data.categories;

      },
      error: (error) => {
        console.error(error);
      }
    });
  
    this.filteredCities$ = this.searchForm.get('city').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCities(value))
    );
  
    this.filteredCategories$ = this.searchForm.get('category').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCategories(value))
    );
  
    this.searchForm.get('city').valueChanges.subscribe(value => {
      this.isCityListVisible = !!value;
    });
  
    this.searchForm.get('category').valueChanges.subscribe(value => {
      this.isCategoryListVisible = !!value;
    });
  }
  
  private filterCities(value: string): string[] {
    const filterValues = value.toLowerCase().split(' ').filter(Boolean);
    return this.cityList
      .filter(city => city !== null)
      .filter(city => {
        const cityName = city.toLowerCase().replace(/[čćžšđ]/g, (match) => {
          switch (match) {
            case 'č': return 'c';
            case 'ć': return 'c';
            case 'ž': return 'z';
            case 'š': return 's';
            case 'đ': return 'd';
            default: return match;
          }
        });
        return filterValues.every(word => cityName.includes(word));
      });
  }
  
  private filterCategories(value: string): string[] {
    const filterValues = value.toLowerCase().split(' ').filter(Boolean); 
    return this.categoriesList
    .filter(category => category !== null)
    .filter(category => {
      const categoryName = category.toLowerCase().replace(/[čćžšđ]/g, (match) => {
        switch(match) {
          case 'č': return 'c';
          case 'ć': return 'c';
          case 'ž': return 'z';
          case 'š': return 's';
          case 'đ': return 'd';
          default: return match; 
        }
      });
      return filterValues.every(word => categoryName.includes(word));
    });
  }
  
  selectCity(city: string): void {
    this.searchForm.get('city').setValue(city);
    this.isCityListVisible = false;
  }
  
  selectCategory(category: string): void {
    this.searchForm.get('category').setValue(this.transformWord(category));
    this.isCategoryListVisible = false;
  }
  
  onSearchSubmit(): void {
      const transformedCategory = this.transformBack(this.searchForm.get('category').value);
      const transformedCity = this.searchForm.get('city').value;
      const transformedDate = this.searchForm.get('date').value;
      
    
      this.salonService.setData(transformedCategory, transformedCity, transformedDate);
    
      this.searchForm.setValue({
        category: transformedCategory,
        city: transformedCity,
        date: transformedDate,
      });
    
      
      this.router.navigate(['place']);
 
  }
  
  transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
    return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
    }
    
  transformBack(word) {
    if(word){
      return word.toUpperCase().replace(/ /g, '_');
    }  
  }
  
  getFormattedDateForApi(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }
  
  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  

     goBack(){
      this.location.back();
    }

  addHoverEffect() {
    const spanElement = this.elementRef.nativeElement.querySelector('.mat-icon span');
    this.renderer.addClass(spanElement, 'hover-effect');
  }
  
}
