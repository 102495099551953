import { Component, inject } from '@angular/core';
import { ClientsService } from 'src/app/services/partner-services/clients.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'wc-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent {
  selectedComponent: string = 'details';

  router:Router = inject(Router);
  activeRoute:ActivatedRoute = inject(ActivatedRoute);

  clientId:any;
  client: any;
 

  constructor(private clientService: ClientsService,
    private location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {

      console.log(this.route.children)
     
       this.clientId = this.route.snapshot.paramMap.get('id');
        console.log(this.clientId);

        this.userService.getUserId(this.clientId).subscribe({
          next: (data) => {
              this.client = data;
              console.log(this.client);
                },
         error: (error) => {
              console.error( error);
             }
        });
  
     
   
    }
  

  showComponent(component: string) {
    this.selectedComponent = component;
    this.clientService.openClient();
  }

  closeClient() {
    this.location.back();
    this.clientService.closeClient();
  }


}
