<div class="main">
    <div class="header">
        <app-header></app-header>
    </div>
    <div class="header-mobile">
        <p (click)="navigateToHomepage()">Početna strana</p>
        <p>&#x2022;</p>
        <p (click)="navigateToSearch()">Pretraga</p>
        <p>&#x2022;</p>
        <p class="black">Salon</p>
    </div>
    <section>
        <div class="card" *ngIf="PlaceInfo">
            <div class="name">
                <h1>{{PlaceInfo.name}}</h1>
                <div class="rating">{{PlaceInfo.rating}}</div>
            </div>
            <div class="flex">
                <p class="address">{{PlaceInfo.address}}</p>
                <div class="time">
                    <p *ngIf="isOpen">Otvoreno</p>
                    <p *ngIf="!isOpen">Zatvoreno</p>
                    <p class="grey" *ngIf="isOpen && item?.end">Radi do {{ item.end }}</p>
                   <p class="grey" *ngIf="!isOpen">Otvara se u   
                     {{ transformWord(getDay(nextWorkingDay.day)) }}
                       u {{ nextWorkingDay.start }}</p> 
                </div>
            </div>
            <div class="icons">
                <span class="material-symbols-outlined" (click)="shareToSocial()">
                    ios_share
                </span>
                <div class="favorite" (click)="toggleFavorite()" >
                    <svg *ngIf="!isFavorite" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405"><path d="m480-197.85-22.31-20.38q-96.92-87.46-160-148.65-63.07-61.2-98.65-106.85-35.58-45.65-49.31-82.04Q136-592.15 136-629q0-71.31 49.85-121.15Q235.69-800 307-800q49.77 0 95 26t78 75.54Q512.77-748 558-774q45.23-26 95-26 71.31 0 121.15 49.85Q824-700.31 824-629q0 36.85-13.23 72.23-13.23 35.39-48.81 80.54-35.58 45.15-98.77 107.35Q600-306.69 500.31-216.23L480-197.85Zm0-43.15q93-83.77 153-142.65 60-58.89 95.5-102.39t49.5-77.11q14-33.62 14-65.85 0-59-40-99t-99-40q-35.77 0-66.65 15.27-30.89 15.27-69.81 55.11l-35 41h-3.08l-35-41q-39.69-40.61-71.19-55.5Q340.77-768 307-768q-58.23 0-98.62 40Q168-688 168-629q0 32.23 13 64.35 13 32.11 47.5 75.11t95 102Q384-328.54 480-241Zm0-264Z"/></svg>
                    <svg *ngIf="isFavorite" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405"><path d="m480-197.85-22.31-20.38q-96.92-87.46-160-148.65-63.07-61.2-98.65-106.85-35.58-45.65-49.31-82.04Q136-592.15 136-629q0-71.31 49.85-121.15Q235.69-800 307-800q49.77 0 95 26t78 75.54Q512.77-748 558-774q45.23-26 95-26 71.31 0 121.15 49.85Q824-700.31 824-629q0 36.85-13.23 72.23-13.23 35.39-48.81 80.54-35.58 45.15-98.77 107.35Q600-306.69 500.31-216.23L480-197.85Z"/></svg>
                </div>
            </div>
        </div>
        <div class="gallery" *ngIf="PlaceInfo && PlaceInfo.images.length > 0" (swipeleft)="nextPhoto()" (swiperight)="previousPhoto()">
                <img *ngIf="PlaceInfo.images" [src]="PlaceInfo.images[currentPhotoIndex]" alt="">
                <div class="arrows">
                    <span class="material-symbols-outlined arrow" (click)="previousPhoto()">
                        arrow_left_alt
                    </span>
                    <span class="material-symbols-outlined arrow" (click)="nextPhoto()">
                        arrow_right_alt
                    </span>
                    <button (click)="navigateToGallery(PlaceInfo.id)">Vidi sve</button>
             
            </div>
           
             <div class="icons-mobile">
                <span class="material-symbols-outlined" (click)="shareToSocial()">
                    ios_share
                </span>
                <div class="favorite" (click)="toggleFavorite()" >
                    <svg *ngIf="!isFavorite" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405"><path d="m480-197.85-22.31-20.38q-96.92-87.46-160-148.65-63.07-61.2-98.65-106.85-35.58-45.65-49.31-82.04Q136-592.15 136-629q0-71.31 49.85-121.15Q235.69-800 307-800q49.77 0 95 26t78 75.54Q512.77-748 558-774q45.23-26 95-26 71.31 0 121.15 49.85Q824-700.31 824-629q0 36.85-13.23 72.23-13.23 35.39-48.81 80.54-35.58 45.15-98.77 107.35Q600-306.69 500.31-216.23L480-197.85Zm0-43.15q93-83.77 153-142.65 60-58.89 95.5-102.39t49.5-77.11q14-33.62 14-65.85 0-59-40-99t-99-40q-35.77 0-66.65 15.27-30.89 15.27-69.81 55.11l-35 41h-3.08l-35-41q-39.69-40.61-71.19-55.5Q340.77-768 307-768q-58.23 0-98.62 40Q168-688 168-629q0 32.23 13 64.35 13 32.11 47.5 75.11t95 102Q384-328.54 480-241Zm0-264Z"/></svg>
                    <svg *ngIf="isFavorite" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405"><path d="m480-197.85-22.31-20.38q-96.92-87.46-160-148.65-63.07-61.2-98.65-106.85-35.58-45.65-49.31-82.04Q136-592.15 136-629q0-71.31 49.85-121.15Q235.69-800 307-800q49.77 0 95 26t78 75.54Q512.77-748 558-774q45.23-26 95-26 71.31 0 121.15 49.85Q824-700.31 824-629q0 36.85-13.23 72.23-13.23 35.39-48.81 80.54-35.58 45.15-98.77 107.35Q600-306.69 500.31-216.23L480-197.85Z"/></svg>
                </div>
             </div>
        </div>
    </section>
    <div class="page">
        <ul class="page-options">
            <li  class="page-options-item" routerLinkActive="active"  [routerLink]="'/salon/salon-services/' + salonId"> 
                <a >Usluge</a>
            </li>
            <li class="page-options-item" routerLinkActive="active" [routerLink]="'/salon/salon-info/' + salonId">
                <a >O nama</a>
            </li>
            <li  class="page-options-item" routerLinkActive="active" [routerLink]="'/salon/salon-review/' + salonId">
                <a >Utisci</a>
            </li>
        </ul>
    </div>
    <div class="router">
      <router-outlet></router-outlet>
    </div> 
    <div class="footer">
        <app-footer></app-footer>
    </div>
     
</div>

