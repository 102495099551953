<div class="full-page-overlay">
  
      <div class="client">
        <div class="info">
            <!-- <div class="image">
                <div class="img">
                    <div class="file-upload">
                        <input type="file" (change)="onFileChange($event)" id="fileInput" />
                        <label for="fileInput" class="custom-button"> <span class="material-symbols-outlined">
                            cloud_upload
                        </span> </label>
                      </div>
                </div>
            </div>
            <form class="form-box"  [formGroup]="generalForm" (ngSubmit)="onSubmit()">
              <div class="form-information">
              <div class="input-group"> 
                <label class="label-text" for="firstName">Ime</label>
                <input class="input-text" type="text" id="firstName"  formControlName="firstName" >
              </div> 
              <div class="input-group">
                <label class="label-text" for="email">Prezime</label>
                <input class="input-text" type="email" id="email" formControlName="lastName" >
              </div>
              </div>
            </form>  -->
          </div>
        <div class="page">
        </div>
      </div>
      <div class="details">
          <button (click)="closeClient()" class="close-btn">
             <span class="material-symbols-outlined">
               close
             </span>
          </button>
          <div class="position">
            <div class="scroll">
            <div class="div">
                <div class="title-div">
                    <p class="title">Dodaj klijenta</p>
                </div>  
                <form class="form-box"  [formGroup]="generalForm" >
                  <div class="form-information">
                  <div class="input-group"> 
                    <label class="label-text" for="name">Ime</label>
                    <input class="input-text" type="text" id="name" formControlName="firstName"   >
                  </div>  
                  <div class="input-group">
                    <label class="label-text" for="last-name">Prezime</label>
                    <input class="input-text" type="text" id="last-name" formControlName="lastName" > 
                  </div>
                  <div class="input-group">
                    <label class="label-text" for="telephone">Broj telefona</label>
                    <input class="input-text" type="text" id="telephone" formControlName="telephone" >
                  </div>
                  <div class="input-group">
                    <label class="label-text" for="date">Datum rođenja</label>
                    <input class="input-text" type="date" id="date" formControlName="dateOfBirth">
                  </div>
                  <div class="select-group">
                      <label class="label-text" for="gender">Pol</label>
                      <select   id="gender" formControlName="gender" >
                          <option class="option" value="ŽENSKI">Ženski</option>
                          <option class="option" value="MUŠKI">Muški</option> 
                        </select>
                  </div>
    
                </div>

                <div class="btns">
                  <button (click)="closeClient()" class="exit">
                  Poništi
                </button>
                <button  class="btn-next" (click)="onSubmit()" type="submit">
                  Sačuvaj
                </button>
                </div>
              </form>
            </div>
            </div>
            </div>
      </div>
    </div>