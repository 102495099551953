import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { PlaceDTO } from 'src/app/models/PlaceDTO';
import { BookAppointmentDTO } from 'src/app/models/BookAppointmentDTO';
import { AppointmentCategoryPlaceDTO } from 'src/app/models/AppointmentCategoryPlaceDTO';

@Injectable({
  providedIn: 'root'
})
export class SalonService {

  selectedTreatment: any;
  selectedTerm: string | null = null;
  selectedUser: any;
  selectedClient: any;
  
  totalTime: number = 0;
  totalPrice: number = 0;
  formattedTotalTime: string = '';

  private addAccount = new BehaviorSubject<boolean>(false);
  addAccount$ = this.addAccount.asObservable();

  private _showAppointment = new BehaviorSubject<boolean>(false);
  showAppointment$ = this._showAppointment.asObservable();
  
  appointmentIDs: number[] = [];

  category: string;
  city: string;
  date: string;
 

  private baseUrl = 'https://test.clicko.rs/api/place/'

  constructor(private http: HttpClient, private auth: AuthService) {
   }

   setSelectedClient(client: any): void {
    this.selectedClient = client;
  }

  getSelectedClient(): any {
    return this.selectedClient;
  }

   setData(category: string, city: string, date: string): void {
    this.category = category;
    this.city = city;
    this.date = date;
  }

  getData(): { category: string, city: string, date: string } {
    return { category: this.category, city: this.city, date: this.date };
  }

   updateAddAccount(status: boolean) {
    this.addAccount.next(status);
  }

   setShowAppointment(value: boolean) {
    this._showAppointment.next(value);
  }
  
  getBookedAppointments(date:string, staffId:number): Observable<any[]>{
    const url = "https://test.clicko.rs/api/bookAppointment/getAllBookAppointementByDay";
    return this.http.get<any[]>(`${url}/${date}/${staffId}`);
   }

   
   getPlaces(): Observable<any[]>{
    return this.http.get<any[]>(this.baseUrl);
   }

   getPlaceDetails(salonId: number): Observable<any> {
      const url = `${this.baseUrl}${salonId}`;
      return this.http.get<any>(url); 
  }

  getPlaceInfoDetails(placeId: number): Observable<any> {
    const url = `https://test.clicko.rs/api/place/placeMore`;
    return this.http.get<any>(`${url}/${placeId}`); 
}

  getPlaceInfo(placeId: number): Observable<any> {
    const url = `https://test.clicko.rs/api/place/placeInfo`;
    return this.http.get<any>(`${url}/${placeId}`); 
}

 getSearchedPlaces(category: string,city: string, date:string): Observable<any> {
  const url = `https://test.clicko.rs/api/appointment/getByAppointmentAndCity`;
  const cityParam = city ? city : 'null'; 
  const dateParam = date ? date : 'null'; 
  return this.http.get<any>(`${url}/${category}/${cityParam}/${dateParam}`); 
 }

 getSearchedPlacesbyCity(city: string, date:string): Observable<any> {
  const url = `https://test.clicko.rs/api/appointment/getByCity`;
  const dateParam = date ? date : 'null';
  return this.http.get<any>(`${url}/${city}/${dateParam}`); 
 }
 
 getBookedAppointment(appointmentIDs: any): Observable<any[]> {
  const url = "https://test.clicko.rs/api/bookAppointment";
  return this.http.get<any>(`${url}/${appointmentIDs}`);
}

  getAppointments(appointmentIDs: any): Observable<any[]> {
    const url = "https://test.clicko.rs/api/appointment";
    return this.http.get<any>(`${url}/${appointmentIDs}`);
  }

  getStaffByAppointment(appointmentIDs: any): Observable<any[]> {
    const url = "https://test.clicko.rs/api/appointment/getStaffByAppointmentId";
    return this.http.get<any>(`${url}/${appointmentIDs}`);
  }

  getAppointmentsList(salonId: number): Observable<AppointmentCategoryPlaceDTO> {
    const url = "https://test.clicko.rs/api/appointment/getByPlaceID"
    return this.http.get<AppointmentCategoryPlaceDTO>(`${url}/${salonId}`);
  }


  makeAppointment(appointment): Observable<any> {
    const url = "https://test.clicko.rs/api/appointment/";
    return this.http.post<any>(url, appointment);
  }

  deleteAppointment(Id: number): Observable<any> {
    const url = "https://test.clicko.rs/api/appointment";
    return this.http.delete<any>(`${url}/${Id}`);
  }

  deletePhoto(Id: number): Observable<any> {
    const url = "https://test.clicko.rs/api/photo";
    console.log("Brisem: ", Id, `${url}/${Id}`);
    return this.http.delete<any>(`${url}/${Id}`);
  }

  bookAppointment(bookappointment: BookAppointmentDTO): Observable<any> {
    const url = "https://test.clicko.rs/api/bookAppointment/";
    return this.http.post<any>(url, bookappointment);
  }

  getClients(placeId: number): Observable<any[]> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getUsersByPlaceId';
    return this.http.get<any[]>(`${url}/${placeId}`);
  }

  addGhostClient(client): Observable<any>{
    const url = 'https://test.clicko.rs/api/user/addGhostUser/'
    return this.http.post<any>(url, client);
  }

  getClientHistory(placeId: number, userId: number): Observable<any> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllBAByUserAndPlace';
    return this.http.get<any>(`${url}/${placeId}/${userId}`);
  }

  getCategories(): Observable<any> {
    const url = "https://test.clicko.rs/api/appointment/getCategories/"
    return this.http.get<any>(url);
  }

  sentCategories(salonId: number, category: string): Observable<any> {
    const url = "https://test.clicko.rs/api/appointment/getCategoriesForPlace"
    return this.http.post<any>(`${url}/${salonId}`, category);
  }

  getALLCategories(): Observable<any> {
    const url = "https://test.clicko.rs/api/appointment/getAllCategories/"
    return this.http.get<any>(url);
  }

  getALLJobs(): Observable<any> {
    const url = "https://test.clicko.rs/api/place/getJobs/"
    return this.http.get<any>(url);
  }
  

  getStaff(salonId: number): Observable<any>{
    const url = `${'https://test.clicko.rs/api/place/getAllStaffs/'}${salonId}`;
    return this.http.get<any>(url); 
  }

  addStaff(staff): Observable<any>{
    const url = 'https://test.clicko.rs/api/place/addNewStaff/';
    return this.http.put<any>(url, staff); 
  }


  addTreatment(treatment: any) {
    this.selectedTreatment = treatment;
}

setSelectedUser(user: any) {
  this.selectedUser = user;
}


removeTreatment(): void {
   this.selectedTreatment = [];
}

clearTreatments() {
    this.selectedTreatment = [];
}

selectTerm(term: string) {
    this.selectedTerm = term;
}



  getWorkingStatus(salonId: number): Observable<boolean> {
    const url = 'https://test.clicko.rs/api/workingTime/check';
    return this.http.get<boolean>(`${url}/${salonId}`);
  }

  getWorkingHours(salonId: number): Observable<any>{
    const url = 'https://test.clicko.rs/api/workingTime/getByPlace';
    return this.http.get<any>(`${url}/${salonId}`);
  }

  getAllBookAppointementByDay(salonId: number): Observable<any>{
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllBookAppointementByDay/2024-03-20';
    return this.http.get<any>(`${url}/${salonId}`);
  }

  setWorkingHours(salonId: number, form): Observable<any>{
    const url = 'https://test.clicko.rs/api/workingTime/getByPlace';
    return this.http.post<any>(`${url}/${salonId}`, form);
  }

  getNextWorkingDay(salonId: number): Observable<any>{
    const url = 'https://test.clicko.rs/api/workingTime/getNextWorkingDay';
    return this.http.get<any>(`${url}/${salonId}`);
  }

  getPackage(salonId: number): Observable<any>{
    const url = 'https://test.clicko.rs/api/place/getPackage';
    return this.http.get<any>(`${url}/${salonId}`);
  }

  addPhoto(files: File[], placeId: number): Observable<any[]> {
  const formData: FormData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i], files[i].name); 
  }
  formData.append('placeId', placeId.toString());
  const url = 'https://test.clicko.rs/api/photo/';
  return this.http.post<any>(url, formData);
  }

  addMainPhoto(file: File, placeId: number): Observable<any> {
    const url = 'https://test.clicko.rs/api/photo/addMainPhoto/';
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('placeId', placeId.toString());
    return this.http.post<any>(url, formData);
}

  getAllAppointments(placeID: string): Observable<any> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllByPlaceId';
    return this.http.get<any>(`${url}/${placeID}`);
  }

  updateAttended(appointmentId, attended): Observable<any> {
    const url = 'https://test.clicko.rs/api/bookAppointment/updateAttended';
    return this.http.get<any>(`${url}/${appointmentId}/${attended}`);
  }

  updateAppointment( id:number, attended: string): Observable<any> {
    const url = 'https://test.clicko.rs/api/bookAppointment/updateAttended';
    const params = new HttpParams().set('attended', attended);
    return this.http.get<any>(`${url}/${id}`, { params });
  }

  editAppointment(appointment): Observable<any>{
    const url = 'https://test.clicko.rs/api/bookAppointment/';
    return this.http.put<any>(url, appointment); 
  }

  getAcceptedAppointments(placeID: string): Observable<string[]> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllAcceptedByPlaceId';
    return this.http.get<string[]>(`${url}/${placeID}`);
  }

  getRejectedAppointments(placeID: string): Observable<string[]> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllRejectedByPlaceId';
    return this.http.get<string[]>(`${url}/${placeID}`);
  }

  getOnHoldAppointments(placeID: string): Observable<string[]> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllOnHoldByPlaceId';
    return this.http.get<string[]>(`${url}/${placeID}`);
  }

  acceptAppointment( id:number, typeOfBA: string): Observable<any> {
    const url = 'https://test.clicko.rs/api/bookAppointment/accept';
    const params = new HttpParams().set('typeOfBA', typeOfBA);
    return this.http.get<any>(`${url}/${id}`, { params });
  }

  rejectAppointment( id:number, typeOfBA: string): Observable<any> {
    const url = 'https://test.clicko.rs/api/bookAppointment/reject';
    const params = new HttpParams().set('typeOfBA', typeOfBA);
    return this.http.get<any>(`${url}/${id}`, { params });
  }

  getAllButNotOnHoldAppointments(placeID: string): Observable<string[]> {
    const url = 'https://test.clicko.rs/api/bookAppointment/getAllButNotOnHold';
    return this.http.get<string[]>(`${url}/${placeID}`);
  }

  getReview(placeID: string): Observable<any> {
    const url = 'https://test.clicko.rs/api/comment/getByPlaceId';
    return this.http.get<any>(`${url}/${placeID}`);
  }
 

}
