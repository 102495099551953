<div>
<footer>
    <div class="copyrights">
      <img src="../assets/icons/Clicko Logo.svg" alt="">
      <div>
          <p> &#169; 2024 made with clicks</p>
          <p>all rights reserved</p>
      </div>
    </div>
    <div class="privacypolicy">
      <p>O nama</p>
      <p>Clicko Partner</p>
      <p>Uslovi korišćenja</p>
      <p>Najčešća pitanja</p>
      <p>Privatnost</p>
    </div>
    <div class="socialm">
      <img src="../assets/icons/instagram 1.png" alt="">
      <img src="../assets/icons/instagram 3.png" alt="">
      <img src="../assets/icons/instagram 4.png" alt="">
      <img src="../assets/icons/instagram 2.png" alt="">
    </div>
    <div class="mobile">
      <p> &#169; 2024 made with clicks</p>
      <p>all rights reserved</p>
   </div>
 </footer>
</div>