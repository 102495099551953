import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'wc-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
 

  settingsForm: FormGroup;
  form: FormGroup;
  PlaceDTO: any;
  UserDTO: any;
  placeID:any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private salonService: SalonService,
    private auth: AuthService) { 
    
}

ngOnInit(): void {

  this.settingsForm = new FormGroup({ 
    id: new FormControl(null),
    name: new FormControl(null),
    ownerName: new FormControl(null),
    ownerEmail: new FormControl(null),
    city: new FormControl(null),
    adress: new FormControl(null),
    telephone: new FormControl(null),
    telephone2: new FormControl(null),
    
  })


  this.auth.getUser().subscribe({
    next: (data) => {
      if (data) {
        this.UserDTO = data;
        if (this.UserDTO && this.UserDTO.placeID) {
          this.placeID = this.UserDTO.placeID;
          this.auth.getSalon(this.placeID).subscribe({
            next: (data) => {
              if (data) {
                this.PlaceDTO = data;

            
                this.settingsForm.patchValue({
                  id: this.PlaceDTO.id,
                  name: this.PlaceDTO.name,
                  ownerName: this.PlaceDTO.ownerDTO.firstName + ' ' + this.PlaceDTO.ownerDTO.lastName,
                  ownerTelephone: this.PlaceDTO.ownerDTO.telephone,
                  ownerEmail: this.PlaceDTO.ownerDTO.email,
                  adress: this.PlaceDTO.address,
                  city: this.PlaceDTO.city,
                  telephone: this.PlaceDTO.telephone,
                  telephone2: this.PlaceDTO.telephone2,
                }); 
      
              } else {
                console.warn();
              }
            },
            error: (salonError) => {
              console.error( salonError);
            }
          });
        } else {
          console.warn();
        }
      } else {
        console.warn();
      }
    },
    error: (userError) => {
      console.error( userError);
    }
  });

}



onSubmit() {
  if (this.settingsForm.valid) {
    this.auth.placeInfo(this.settingsForm.value)
    .subscribe({
      next:(res)=>{
       console.log(res);
      },
      error:(err)=>{
        alert(err?.error.message)
      }
     })
    }else{
      console.warn('Greska');
    }

  }


}
