import { Component } from '@angular/core';

@Component({
  selector: 'app-client-voucher',
  templateUrl: './client-voucher.component.html',
  styleUrls: ['./client-voucher.component.css']
})
export class ClientVoucherComponent {

}
