import { Component } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { AllByPlaceDTO } from 'src/app/models/AllByPlaceDTO';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'wc-canceled',
  templateUrl: './canceled.component.html',
  styleUrls: ['./canceled.component.css']
})
export class CanceledComponent {
  salonId: any;
  placeID: any;
  PlaceDTO: any;
  UserDTO: any;
  categories: any[] = [];
  allCategories: any[] = [];
  appointmentIDs: number[] = [];
  appointments: any[] = [];
  allAccepted: any[] = [];
  allRejected: any[] = [];
  allHold: any[] = [];
  allNotOnHold: any[] = [];

  pastallAccepted: any[] = [];
  pastallRejected: any[] = [];
  pastallHold: any[] = [];
  pastallNotOnHold: any[] = [];
 
  constructor(
              private auth: AuthService,
              private salonService: SalonService,
              private userService: UserService
             ) {

  }

  ngOnInit() {

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.salonService.getAllAppointments(this.placeID).subscribe({
              next: (item: AllByPlaceDTO) => {
                this.pastallAccepted = item.allAccepted;
                this.pastallRejected = item.allRejected;
                this.pastallHold = item.allHold;

                this.pastallNotOnHold = this.pastallAccepted.concat(this.pastallRejected);

                this.pastallAccepted = this.sortAppointmentsByDateTime(this.pastallAccepted);
                this.pastallRejected = this.sortAppointmentsByDateTime(this.pastallRejected);
                this.pastallHold = this.sortAppointmentsByDateTime(this.pastallHold);
                this.pastallNotOnHold = this.sortAppointmentsByDateTime(this.pastallNotOnHold);
                
                const currentTime = new Date(); 

                this.allAccepted = this.pastallAccepted.filter(appointment => {
                  const appointmentDateTime = this.convertDateTime(appointment.date, appointment.time);
                  return appointmentDateTime.getTime() > currentTime.getTime();
                });
                
                this.allNotOnHold = this.pastallNotOnHold.filter(appointment => {
                  const appointmentDateTime = this.convertDateTime(appointment.date, appointment.time);
                  return appointmentDateTime.getTime() > currentTime.getTime();
                });
                
                this.allRejected = this.pastallRejected.filter(appointment => {
                  const appointmentDateTime = this.convertDateTime(appointment.date, appointment.time);
                  return appointmentDateTime.getTime() > currentTime.getTime();
                });
                
                this.allHold = this.pastallHold.filter(appointment => {
                  const appointmentDateTime = this.convertDateTime(appointment.date, appointment.time);
                  return appointmentDateTime.getTime() > currentTime.getTime();
                });

              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
     
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
                } else {
                  console.warn();
                }
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });


  }

  convertDateTime(dateString: string, timeString: string): Date {
    const dateParts = dateString.split('-');
    const timeParts = timeString.split(':');
    
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; 
    const year = parseInt(dateParts[2], 10);
  
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
  
    return new Date(year, month, day, hours, minutes);
  }

  sortAppointmentsByDateTime(appointments: any[]): any[] {
    return appointments.sort((a, b) => {
      const dateTimeA = this.convertDateTime(a.date, a.time).getTime();
      const dateTimeB = this.convertDateTime(b.date, b.time).getTime();
      return dateTimeA - dateTimeB;
    });
  }

  dateTranform(inputDate: string): string {
    if(inputDate!=null){
      const dateParts = inputDate.split('-');
      return `${dateParts[0]}.${dateParts[1]}.${dateParts[2]}.`;
    }
    else{
      return ""
    }
      
    }

  formatTime(startTime: string, duration: number): string {
    let startHours: number;
    let startMinutes: number;
  
    if (typeof startTime === 'string') {
      const parts = startTime.split(':');
      startHours = parseInt(parts[0], 10);
      startMinutes = parseInt(parts[1], 10);
    } else if (typeof startTime === 'number') {
      startHours = Math.floor(startTime / 60);
      startMinutes = startTime % 60;
    } else {
      return '';
    }
  
    let endHours = startHours;
    let endMinutes = startMinutes + duration;
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes = endMinutes % 60;
    }
  
    return `${startHours}:${startMinutes < 10 ? '0' + startMinutes : startMinutes} - ${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`;
  }



}
