<div class="position">
<p class="component">Detalji o klijentu</p>
<div class="scroll">
<div class="div">
    <p class="title">Ukupan broj termina</p>
    <p class="total">{{history.sumAppointment}}</p>
    <div class="info">
        <p class="text">Došao</p>
        <p class="text">{{history.sumArrived}}</p>
    </div>
    <div class="info">
        <p class="text">Otkazao</p>
        <p class="text">{{history.sumCanceled}}</p>
    </div>
    <div class="info">
        <p class="text red">Nije došao</p>
        <p class="text red">{{history.sumNot}}</p>
    </div>
</div>
<div class="div">
    <div class="title-div">
        <p class="title">Osnovne informacije</p>
        <span class="material-symbols-outlined red">
          edit
        </span>
    </div> 
    <div class="info">
        <p class="text">Datum rođenja</p>
        <p class="text">{{ dateTranform(client.dateOfBirth) }}</p>
    </div>
    <div class="info">
        <p class="text">Pol</p>
        <p class="text">{{ transformWord(client.gender) }}</p>
    </div>
    <div class="info red">
        <p class="text">Broj telefona</p>
        <p class="text">{{client.telephone}}</p>
    </div>
</div>
<div class="div">
    <div class="title-div">
      <p class="title">Beleške</p>
      <span class="material-symbols-outlined red">
        edit
      </span>
    </div> 
    <textarea class="textarea" name="" rows="10" cols="30">
    </textarea>

    <div class="btns">
        <button (click)="closeClient()" class="btn-back">
        Odustani
      </button>
      <button  class="btn-next">
        Sačuvaj
      </button>
      </div>
</div> 

</div>
</div>