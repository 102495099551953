import { Component, Input, ViewChild, inject } from '@angular/core';
import { FormService } from 'src/app/services/form/form.service';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddStaffComponent } from './add-staff/add-staff.component';
import { AddDateComponent } from './add-date/add-date.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { AnyComponent } from '@fullcalendar/core/preact';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-an-appointment',
  templateUrl: './create-an-appointment.component.html',
  styleUrls: ['./create-an-appointment.component.css']
})
export class CreateAnAppointmentComponent {
 
  currentStep = 1;
  previousStep = 0
  salonId: any;
  router:Router = inject(Router);
  addAccount: boolean;
  private subscription: Subscription;
 
  
  constructor(private treatmentsService: TreatmentsService,
    private salonService: SalonService,
    private location: Location,
    private route: ActivatedRoute,) {

    }

    ngOnInit(): void {

      this.subscription = this.salonService.addAccount$.subscribe(value => {
        this.addAccount = value;
      }); 
 
    }

    ngOnDestroy(): void {
      this.salonService.setShowAppointment(false);
      this.salonService.updateAddAccount(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  
    }

    goBack(){
      this.salonService.setShowAppointment(false);
    }

  nextStep() {
    this.previousStep = this.currentStep;
    this.currentStep++;
  }

  handleBack() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }


  submitForm() {
    
  }
}

