import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { DatePipe } from '@angular/common';
import { ElementRef } from '@angular/core';
import { AppointmentCategoryPlaceDTO } from 'src/app/models/AppointmentCategoryPlaceDTO';
import { ViewChildren, QueryList } from '@angular/core';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-salon-services',
  templateUrl: './salon-services.component.html',
  styleUrls: ['./salon-services.component.css']
})
export class SalonServicesComponent {

  @ViewChildren('categoryHeaders') categoryHeaders: QueryList<ElementRef>;

  router:Router = inject(Router);
  isDropdownOpen: boolean = false;

  PlaceDTO: any;
  showAppointment: boolean = false;

  appointmentCategoryPlaceDTO: AppointmentCategoryPlaceDTO;
  appointmentIDs: number[] = [];
  appointments: any[] = [];
  categories: any[] = [];

  salonId: any;

  constructor(
    private route: ActivatedRoute,
    public salonService: SalonService,
    private auth: AuthService,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private viewportScroller: ViewportScroller
  ) { 
  
  }
 

  ngOnInit(): void {
    
    // console.log(this.route.snapshot.paramMap.get('id')); 
    this.route.params.subscribe(params => {
      this.salonId = params['id'];
      // console.log(this.salonId);
   
      this.salonService.getAppointmentsList(this.salonId).subscribe(
      (data) => {
          this.appointmentCategoryPlaceDTO = data;
          // console.log(this.appointmentCategoryPlaceDTO);
          this.appointments = this.appointmentCategoryPlaceDTO.appointments;
          // console.log(this.appointments);
      },
      (error) => {
        console.error("", error);
      }
    );
   });
  }

  transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
  } 


  addTreatment(appointment: any) {
  
        const treatment = {
          id: appointment.id,
          name: appointment.name,
          time: appointment.time,
          price: appointment.price,
          term: this.selectedTerm,
            
        };
       
        this.salonService.addTreatment(treatment);
        // console.log(treatment);

        // console.log(this.salonService.selectedTreatment);
  
} 

get selectedTerm() {
  return this.salonService.selectedTerm;
}


scrollToCategory(category: string): void {
  const targetHeader = this.categoryHeaders.find(header => header.nativeElement.textContent.trim() === this.transformWord(category));
  if (targetHeader) {
      let yOffset: number;
      if (this.isMobile()) {
          yOffset = targetHeader.nativeElement.getBoundingClientRect().top + window.pageYOffset - 70;
      } else {
          yOffset = targetHeader.nativeElement.getBoundingClientRect().top + window.pageYOffset - 120;
      }
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
  }
}

isMobile(): boolean {
  return window.innerWidth < 768;
}

  navigateToCreateAnAppointment(salonId: number){
     this.router.navigate([ '/create-an-appointment', salonId]);
     this.salonService.setShowAppointment(true); 
  }
}
