<div class="information">
    <p class="info-title">Zaposleni</p>
    <p class="info-description">Odredite ko od zaposlenih pruža ovu uslugu </p>
    <div class="form-div">
      <form [formGroup]="step2Form" >
        <div class="scroll">
          <div class="st">
             <div class="staff" *ngFor="let staff of allStaff; let i = index" >
            <div class="input-div">
              <div class=staff-photo>
                <img class="img" src="{{ staff.profilePhoto }}" *ngIf="staff.profilePhoto">
                <p class="initial" *ngIf="!staff.profilePhoto"> {{ staff.firstName?.slice(0, 1) }}</p>
              </div>
              <div class="staff-info">
                 <p>{{ staff.firstName }} {{ staff.lastName }}</p>
                 <p>{{ transformWord(staff.jobPosition)}}</p>
              </div>
             </div>
          </div>
          </div>
          <div class="label-div">
            <label class="switch" *ngFor="let staff of allStaff; let i = index">
              <input type="checkbox" [formControlName]="staff" [value]="staff" (change)="onCheckboxChange($event, i)">
              <span class="slider"></span>
            </label>
          </div>
        </div>
        <div class="btns">
         <button (click)="closeTreatment()" class="btn-back">
          Odustani
         </button>
         <button (click)="goToNextStep()"  class="btn-next">
          Sačuvaj
         </button>
        </div>
      </form>
    </div>
  </div>