import { Component, EventEmitter, HostListener, inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import ValidateForm from 'src/app/helpers/validateform';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
  router:Router = inject(Router);
  isDropdownOpen: boolean = false;

  type: string = "password";
  isText: boolean = false;
  eyeIcon: string = "visibility_off";

  signupForm!: FormGroup;
  formdata: any = {};
  
  constructor(private fb: FormBuilder, private location: Location, private auth: AuthService) { }



  ngOnInit(){
    this.signupForm =this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: [ '', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      userRole: ['CLICKO_USER'],
    });

  }

  OnSignup(){
    if(this.signupForm.valid){
      this.auth.signup(this.signupForm.value)
      .subscribe({
        next:(res)=>{
          this.signupForm.reset();
          this.router.navigate(['client/']);
        },
        error:(err)=>{
          alert(err?.error.message || 'Došlo je do greške. Pokušajte ponovo.');
        }
       })
      }else{
        ValidateForm.validateAllFormFields( this.signupForm);
        this.handleFormErrors();
      }
  }

  private handleFormErrors() {
    let errorMessage = '';

    if (this.signupForm.get('password').hasError('minlength')) {
      errorMessage += 'Lozinka mora imati najmanje 6 karaktera.\n';
    }

    if (errorMessage) {
      alert(errorMessage);
    } else {
      alert('Molimo popunite sva polja.');
    }
  }

  hideShowPass(){
    this.isText = !this.isText;
    this.isText ?  this.eyeIcon = "visibility" : this.eyeIcon = "visibility_off";
    this.isText ? this.type = "text" : this.type = "password"
 }



  navigateBack(): void {
    this.location.back();
  }
  toggleDropdown(event: Event) {
    event.stopPropagation(); 
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event) {
    if (!(event.target as HTMLElement).classList.contains('dropbtn')) {
      this.isDropdownOpen = false;
    }
  }

  navigatetoPartner(){
    this.router.navigate(['partnerpage']);
  }
  navigatetoHomepage(){
    this.router.navigate(['homepage']);
  }

  navigatetoLogin(){
    this.router.navigate(['login']);
  }
  navigatetoSignup(){
    this.router.navigate(['signup']);
  }
  navigatetoSignupPartner(){
    this.router.navigate(['signup-partner']);
  }
 
}
