import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FavoritePlaceDTO } from 'src/app/models/FavoritePlaceDTO'
import { NotificationDTO } from 'src/app/models/NotificationDTO';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiUrl = 'https://test.clicko.rs/api/user';

  constructor(private http: HttpClient) {}

  getUserId(userId: number): Observable<any> {
    const url = 'https://test.clicko.rs/api/user';
    return this.http.get<any>(`${url}/${userId}`);
  }

  addToFavorites(favoritePlaceDTO: FavoritePlaceDTO): Observable<any> {
    const url = `${this.apiUrl}/addToFavorites/`;
 
    return this.http.put<any>(url, favoritePlaceDTO);
  }

  deleteFromFavorites(favoritePlaceDTO: FavoritePlaceDTO): Observable<any> {
    const url = `${this.apiUrl}/deleteFromFavorites/`;
    return this.http.put<any>(url, favoritePlaceDTO);
  }

  getFavoriteSalons(userID: number): Observable<any[]> {
    const url = `${this.apiUrl}/getFavorites`;
    return this.http.get<any[]>(`${url}/${userID}`);
  }

  getAppointments(userID: string): Observable<string[]> {
  const url = 'https://test.clicko.rs/api/bookAppointment/getByUserID';
  return this.http.get<string[]>(`${url}/${userID}`);
}

  addProfilePhoto(photo: File, userId: number): Observable<any> {
    const url = 'https://test.clicko.rs/api/user/uploadProfilePhoto/';
    const formData: FormData = new FormData();
    formData.append('photo', photo);
    formData.append('userId', userId.toString());
    return this.http.post<any>(url, formData);
  }

  getNotification(userID: string): Observable<string[]> {
    const url = 'https://test.clicko.rs/api/notification/getByUserId';
    return this.http.get<string[]>(`${url}/${userID}`);
  }

  postNotification(notification: NotificationDTO): Observable<any> {
    const url = 'https://test.clicko.rs/api/notification/';
    return this.http.post<any>(url, notification);
  }

  putNotification(notification: NotificationDTO): Observable<any> {
    const url = 'https://test.clicko.rs/api/notification/';
    return this.http.put<any>(url, notification);
  }
}
