import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {  
 

  constructor(private router: Router) {}

  navigateToPage(route: string) {
    this.router.navigate(['page']);
  }

  

}
