import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { UserService } from 'src/app/services/user/user.service';

interface Comment {
  id: number;
  commentText: string;
  rating: number;
  placeID: number;
  userID: number;
  userName: string;
  userProfilePhoto: string;
}

interface NextWorkingDay {
  id: string;
  start: string;
  end: string;
  day: string;
  placeID: string;
}

interface PlaceInfo {
  comments: Comment[];
  name: string;
  rating: number;
  address: string;
  open: boolean;
  nextWorkingDay: NextWorkingDay;
}

@Component({
  selector: 'app-salon-review',
  templateUrl: './salon-review.component.html',
  styleUrls: ['./salon-review.component.css']
})
export class SalonReviewComponent {
  router:Router = inject(Router);

  allReview: any[] = [];
  averageRating: any;
  totalRating: any;
  numberOfItems:any;

  salonId: any;
  user: any;
  PlaceInfo: PlaceInfo;

  isOpen: boolean;
  workinHour: any;
  nextWorkingDay: NextWorkingDay ;
  item: any;
  

  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService,
    private userService: UserService
  ) { }
  
  ngOnInit(): void {
    
    this.salonId = this.route.snapshot.paramMap.get('id');
    this.salonService.getReview(this.salonId).subscribe({
      next: (data: any) => {
        this.PlaceInfo = data;
        this.allReview = this.PlaceInfo.comments;
        this.totalRating = 0;
        this.numberOfItems = this.PlaceInfo.comments.length;
        this.isOpen = this.PlaceInfo.open;
        this.nextWorkingDay = this.PlaceInfo.nextWorkingDay;

        console.log(this.PlaceInfo)
        for (let review of this.allReview) {
          if (review.rating) { 
            this.totalRating += review.rating;
          }
        }

       this.averageRating = this.totalRating / this.numberOfItems;
      },
      error: (error: any) => {
        console.error(error);
      }
    });


}

transformWord(word: string): string {
return word.toLowerCase().split(' ').map(word => { 
return word.charAt(0).toUpperCase() + word.slice(1);
}).join(' ').replace(/_/g, ' ');
}

  getUserFirstName(userId: number): Observable<string>  {
    return this.userService.getUserId(userId).pipe(
      map(user => user.firstName) 
    );
  }

  getUserLastName(userId: number): Observable<string>  {
    return this.userService.getUserId(userId).pipe(
      map(user => user.lastName) 
    );
  }

  getUserPhoto(userId: number): Observable<string>  {
    return this.userService.getUserId(userId).pipe(
      map(user => user.profilePhoto) 
    );
  }

}
