<div class="main">
    <section class="section1" #header>
        <app-header></app-header>
    </section>
    <section class="section1-mobile">
        <div class="title-div"> 
            <div>
                <p class="name">Naziv tretmana</p>
                <p class="info">Lokacija</p>
            </div>
        </div>
        <button  (click)="navigateToSearch()" class="search-btn">
            <span class="material-symbols-outlined">
                instant_mix
            </span>
        </button>
    </section>
    <section class="section2">
        <div class="search">
            <form
            [formGroup]="searchForm"
            class="searchBar"
          >
          <div class="search-container">
            <input type="search" placeholder="Usluga" class="search-input" formControlName="category">
             <span class="material-symbols-outlined">
                search
             </span>
             <ul *ngIf="isCategoryListVisible && (filteredCategories$ | async) as filteredCategories">
                <li *ngFor="let category of filteredCategories" (click)="selectCategory(category)">{{ transformWord(category) }}</li>
              </ul>
          </div>
        <div class="date" >
            <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-datepicker-toggle matIconSuffix [for]="picker" (mouseenter)="hovered = true" (mouseleave)="hovered = false" >
                  <mat-icon matDatepickerToggleIcon >
                    <div class="svg" [ngStyle]="{ 'background-color': (hovered ? '#C6DDA8' : '') }">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405" class="desktop-svg"><path d="M480.21-377.85q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19ZM480.21-240q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T480.21-240Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T324.21-240Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T636.21-240ZM240.62-136q-24.32 0-40.47-16.5T184-192.62v-478.76q0-23.62 16.15-40.12Q216.3-728 240.62-728h102.76v-105.23h35.08V-728h206.16v-105.23h32V-728h102.76q24.32 0 40.47 16.5T776-671.38v478.76q0 23.62-16.15 40.12Q743.7-136 719.38-136H240.62Zm0-32h478.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-310.76H216v310.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69Z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405" class="mobile-svg"><path d="M240.62-136q-24.32 0-40.47-16.5T184-192.62v-478.76q0-23.62 16.15-40.12Q216.3-728 240.62-728h102.76v-105.23h35.08V-728h206.16v-105.23h32V-728h102.76q24.32 0 40.47 16.5T776-671.38v478.76q0 23.62-16.15 40.12Q743.7-136 719.38-136H240.62Zm0-32h478.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-310.76H216v310.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM216-535.39h528v-135.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H240.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v135.99Zm0 0V-696v160.61Zm264.21 157.54q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19ZM480.21-240q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T480.21-240Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T324.21-240Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T636.21-240Z"/></svg>
                    </div>
                </mat-icon>
              </mat-datepicker-toggle>
            <mat-datepicker #picker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Otkaži</button>
                    <button mat-raised-button  matDatepickerApply>OK</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>    
        </div>
        <button class="btn-search" type="submit" (click)="onSearchSubmit()">Pretraži</button>
    </form>
       
        </div>
    </section>
    <section class="section3" >
       <div class="list" #listDiv [ngClass]="{'onePlaceList': places.length === 1}">
        <p class="sum">{{ placeCount }} {{ placeCount === 1 ? 'salon' : 'salona' }} u blizini</p>
         <div class="scroll" [ngClass]="{'onePlace': places.length === 1}">
            <div class="place" *ngFor=" let place of places" (click)="navigatetoSalon(place.id)">
                <div class="image">
                    <img *ngIf="place.urlImg" src="{{place.urlImg}}" alt="Place Photo">
                 </div>
                 <h3 class="place-name" >
                    {{ place.name }}
                 </h3>
                 <div class="address" >
                    <p>{{ place.address }}</p>
                    <div class="rating">{{ place.rating }}</div>
                 </div>
            
                </div>   
            </div>
         </div>
         
       <div class="map" #mapDiv>

        <span #expand class="material-symbols-outlined expand" (click)="expandContent()">
            expand_content
        </span>
        <span #collapse class="material-symbols-outlined collapse" (click)="collapseContent()">
            collapse_content
        </span>
       </div>
    </section>
</div>