import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-client-favorite',
  templateUrl: './client-favorite.component.html',
  styleUrls: ['./client-favorite.component.css']
})
export class ClientFavoriteComponent {

  router:Router = inject(Router);
  
  UserDTO: any;
  favorite: any[] = [];
  photos: any[] =[];
  

  constructor(private auth: AuthService,
              private userService: UserService
    ){}

  ngOnInit(){

    this.auth.getUser().pipe(
      switchMap(data => {
        if (data) {
          this.UserDTO = data;
          return this.userService.getFavoriteSalons(this.UserDTO.id);
        } else {
          console.warn("");
          return of([]);
        }
      })
    ).subscribe({
      next: (data: string[]) => {
        this.favorite = data;
        for (const place of this.favorite) {
    
         }
        
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  navigatetoSalon(salonId: number): void {
    this.router.navigate(['salon', 'salon-services', salonId]);
  }


}
