<div class="information">
    <p class="info-title">Osnovne informacije</p>
    <p class="info-description">Dodajte naziv i odaberite kategoriju</p>
    <div class="form-div">
      <form [formGroup]="step1Form">
        <div class="input-div">
          <label class="text-label first" for="">Naziv usluge</label>
          <input class="text-input"  type="text" formControlName="name">
        </div>
        <div class="input-div">
          <label class="text-label" for="">Kategorija</label>
          <select class="select" id="" name="" form="" formControlName="category">
            <option class="option" hidden disabled selected></option>
            <option  class="option" *ngFor="let category of categories" [value]="transformBack(category)">{{ category }} </option>
          </select>
        </div>
        <div class="input-div">
          <label class="text-label" for="">Opis usluge</label>
          <textarea class="textarea" name="" rows="10" cols="30" formControlName="description">
          </textarea>
        </div>
        <div class="btns">
          <button (click)="closeTreatment()" class="btn-back">
           Odustani
          </button>
          <button (click)="goToNextStep()"  class="btn-next">
           Sačuvaj
          </button>
         </div>
      </form>
    </div>
  </div>