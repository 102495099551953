import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UserDTO } from 'src/app/models/PlaceDTO';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormService } from 'src/app/services/form/form.service';
import { TreatmentsService } from 'src/app/services/partner-services/treatments.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component {

  allStaff: any;
  appointments: any;

  salonId: any;
  placeID: any;
  PlaceDTO: any;
  UserDTO: any;
  step1Form: any;
  step2Form: FormGroup;
  userDTOs: FormControl<any>;

  isChecked: false;

  userList: UserDTO[];


  @Input() formData: any;

  @Output() onNext: EventEmitter<any> = new EventEmitter();
  @Output() onBack: EventEmitter<any> = new EventEmitter();

  constructor(private treatmentsService: TreatmentsService,
    private formService: FormService,
    private salonService: SalonService,
    private auth: AuthService) {
   
    }

    ngOnInit() {



      this.step2Form = new FormGroup({ 
        userDTOs: new FormArray([])
      });


   
      if (this.formData) {
        this.step2Form.patchValue(this.formData);
        console.log(this.formData);
      }

      console.log(this.step2Form.value);
    
      
  
      this.auth.getUser().subscribe({
        next: (data) => {
          if (data) {
            this.UserDTO = data;
         
            if (this.UserDTO && this.UserDTO.placeID) {
              this.placeID = this.UserDTO.placeID;
              this.auth.getSalon(this.placeID).subscribe({
                next: (data) => {
                  if (data) {
                    this.PlaceDTO = data;
          
            
                    this.salonService.getStaff(this.placeID).subscribe({
                      next: (staffData) => {
                        this.allStaff = staffData;
                        console.log(this.allStaff);

                        if (this.allStaff) {
                          this.allStaff.forEach(staff => {
                            const control = new FormControl(false); // Početno označavanje checkboxova kao false
                            (this.step2Form.get('userDTOs') as FormArray).push(control);
                          });
                        }
                      
                        // Postavljanje vrednosti checkboxova na osnovu formData-a
                        if (this.formData && this.formData.userDTOs) {
                          const formDataUserDTOs = this.formData.userDTOs;
                          const userDTOsArray = this.step2Form.get('userDTOs') as FormArray;
                          this.allStaff.forEach((staff, index) => {
                            const isUserDTOChecked = formDataUserDTOs.findIndex(dto => dto.id === staff.id) !== -1;
                            userDTOsArray.controls[index].setValue(isUserDTOChecked);
                          });
                        }
                        
                      },
                      error: (staffError) => {
                        console.error("nema zaposlenih", staffError);
                      }
                    });   
      
                  } else {
                    console.warn();
                  }
                },
                error: (salonError) => {
                  
                }
              });
            } else {
              console.warn();
            }
          } else {
            console.warn();
          }
        },
        error: (userError) => {
          console.error( userError);
        }
      });
   
  
    }


    onCheckboxChange(event, index) {
      const userDTOsArray = this.step2Form.get('userDTOs') as FormArray;
      if (event.target.checked) {
        // Označavanje zaposlenog u listi
        userDTOsArray.controls[index].setValue(true);
      } else {
        // Odznačavanje zaposlenog u listi
        userDTOsArray.controls[index].setValue(false);
      }
    }
    
    // Funkcija koja vraća niz čekiranih zaposlenih
    getCheckedStaff() {
      const userDTOsArray = this.step2Form.get('userDTOs') as FormArray;
      return this.allStaff.filter((staff, index) => userDTOsArray.controls[index].value);
    }

    transformWord(word: string): string {
      return word.toLowerCase().split(' ').map(word => { 
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ').replace(/_/g, ' ');
    }

    
    receiveFormData(formData: any) {
      this.formData = formData;
  
    }

    goToNextStep() {
      const userDTOsArray = this.step2Form.get('userDTOs') as FormArray;
      const checkedStaff = [];
      for (let i = 0; i < userDTOsArray.length; i++) {
        if (userDTOsArray.at(i).value) {
          checkedStaff.push(this.allStaff[i]);
        }
      }
      this.formData.userDTOs = checkedStaff;
      this.onNext.emit(this.formData);
      console.log(this.formData);
    }

closeTreatment() {
  this.treatmentsService.closeTreatment();
}

  goBack() {
    this.onBack.emit();
  }
}
