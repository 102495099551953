<div class="center">
    <span class="material-symbols-outlined" (click)="navigateBack()">
        arrow_back
    </span>
   <div class="content">
    <div class="div">
        <div>
            <h1>Žao nam je što odlaziš</h1>
            <p>Ukoliko imaš sugestije možeš ih poslati na <a class="red" href="mailto:hi@clicko.rs">hi&#64;clicko.rs</a></p>
        </div>
        <ul>
            <li>
                <p>Pre nego što odeš, navedi razlog brisanja naloga na Clicku</p>
            </li>
            <li>
                <div class="custom-radio">
                    <input type="radio" id="radio1" name="reason" [(ngModel)]="selectedReason" value="komplikovano">
                    <label for="radio1">
                        <p>
                           Komplikovano je za korišćenje 
                        </p>
                    </label>
                </div>
            </li>
            <li>
                <div class="custom-radio">
                    <input type="radio" id="radio2" name="reason" [(ngModel)]="selectedReason" value="usluge">
                    <label for="radio2">
                        <p>
                            Usluge koje su mi potrebne nisu na platformi
                        </p>
                    </label>
                </div>
            </li>
            <li>
                <div class="custom-radio">
                    <input type="radio" id="radio3" name="reason" [(ngModel)]="selectedReason" value="druga">
                    <label for="radio3">
                        <p>
                            Koristim drugu aplikaciju
                        </p>
                    </label>
                </div>
            </li>
            <li>
                <div class="custom-radio">
                    <input type="radio" id="radio4" name="reason" [(ngModel)]="selectedReason" value="ostalo">
                    <label for="radio4">
                        <p>
                            Ostalo
                         </p>
                    </label>
                </div>
            </li>
        </ul>
        <button (click)="onDeleteUser()">Obriši nalog</button>
    </div>
    <div class="image">
        <img src="../assets/icons/Clicko_Tuzan 1.png" alt="">
    </div>
   </div>
</div>
