import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent {
  
  router:Router = inject(Router);


  constructor() {}

  navigatetoLogin(){
    this.router.navigate(['login']);
  }
  navigatetoSignup(){
    this.router.navigate(['signup']);
  }
}
