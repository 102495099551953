import { Component, HostListener, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import ValidateForm from 'src/app/helpers/validateform';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  router:Router = inject(Router);
  isDropdownOpen: boolean = false;
  
  type: string = "password";
  isText: boolean = false;
  eyeIcon: string = "visibility_off";

  loginForm!: FormGroup;
 
  constructor(private fb: FormBuilder, private location: Location, private auth: AuthService) { }

  ngOnInit(){
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

  }

  hideShowPass(){
    this.isText = !this.isText;
    this.isText ?  this.eyeIcon = "visibility" : this.eyeIcon = "visibility_off";
    this.isText ? this.type = "text" : this.type = "password"
 }

  OnLogin(){
    if(this.loginForm.valid){
        this.auth.login(this.loginForm.value)
     .subscribe({
      next:(res)=>{
        this.auth.storeToken(res.idToken);
        this.navigateBasedOnRole(res.userRole);
      
      },
      error:(err)=>{
        alert(err?.error?.message || 'Neuspešna prijava. Pokušajte ponovo.');
      }
     })
    }else{
      ValidateForm.validateAllFormFields( this.loginForm);
      alert("Unesite email i lozinku.");
    }
  }

  private navigateBasedOnRole(userRole: string): void {
    switch (userRole) {
      case 'CLICKO_USER':
        this.router.navigate(['client/']);
        break;
      case 'OWNER':
        this.router.navigate(['partner/']);
        break;
      default:
        this.router.navigate(['homepage']);
        break;
    }
  }

  toggleDropdown(event: Event) {
    event.stopPropagation(); 
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event) {
    if (!(event.target as HTMLElement).classList.contains('dropbtn')) {
      this.isDropdownOpen = false;
    }
  }

  navigatetoPartner(){
    this.router.navigate(['partnerpage']);
  }

  navigatetoHomepage(){
    this.router.navigate(['homepage']);
  }

  
  navigateBack(): void {
    this.location.back();
  }

  navigatetoLogin(){
    this.router.navigate(['login']);
  }
  navigatetoSignup(){
    this.router.navigate(['signup']);
  }
  navigatetoSignupPartner(){
    this.router.navigate(['signup-partner']);
  }


}


