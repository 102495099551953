import { PlaceDTO } from "./PlaceDTO";

export class NotificationDTO{
    
    notificationName: NotificationName;
    active: boolean;
    userID: string;
}

export class NotificationName {
    static EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION'; 
    static EMAIL_PROMOTION = 'EMAIL_PROMOTION';
  }