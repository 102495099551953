<div class="position">
    <p class="component">Istorija Tretmana</p>
    <div class="scroll">
    <div class="div" *ngFor="let treatment of history.lista">
      <div class="div1">
        <p class="treatment">{{treatment.name}}</p>
        <p class="time">{{treatment.time}}</p>
      </div>  
      <div class="div2">
        <div class="date">
          {{formatDate(treatment.date)}}
        </div>
        <p class="staff">{{treatment.staffName}}</p>
        <p class="price red">{{treatment.price}} RSD</p>
      </div>
    </div>
    <div class="btns">
      <button (click)="closeClient()" class="btn-back">
      Odustani
    </button>
    <button  class="btn-next">
      Sačuvaj
    </button>
    </div>
    </div>
</div>