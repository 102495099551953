import { Component } from '@angular/core';

@Component({
  selector: 'wc-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.css']
})
export class RatingsComponent {

}
