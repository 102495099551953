<div class="main">
  <h2>Odaberite zaposlenog</h2>
  <div class="display" >
    <article>
        <div class="image" (click)="selectUser(firstUser)"  [ngClass]="{ 'selected-div': isUserSelected && this.firstUser === selectedUser}">
            <img class="img" src="../assets/icons/Ellipse 15.png" >
       </div>
       <p class="name">Prvi slobodan</p>
    </article>
    <ng-container *ngIf="allStaff && allStaff.length > 0">
    <article *ngFor="let staff of allStaff"  (click)="selectUser(staff)"  [ngClass]="{ 'selected-div': isUserSelected && staff === selectedUser}">
        <div class="image">
             <img class="img" src="{{ staff.photoUrl }}" *ngIf="staff.photoUrl">
             <p class="initial" *ngIf="!staff.photoUrl"> {{ staff.name?.slice(0, 1) }}</p>
        </div>
        <p class="name">{{ staff.name }}</p>
        <p class="position">{{ transformWord(staff.job) }}</p>
    </article>
   </ng-container>
  </div>
  <div class="bottom">
    <div *ngIf="this.PlaceDTO">
      <h5>{{ this.selectedTreatment.name }}</h5> 
      <p class="grey">{{ this.PlaceDTO.name }} &#x2022; {{ this.selectedTreatment.time }} min</p>
      <p *ngIf="this.selectedTreatment.price">{{ this.selectedTreatment.price }} RSD</p>
    </div>
    <button (click)="goToNextStep()">
        Dalje
    </button>
  </div>
</div>
