<div class="full-page-overlay">
    <div class="btn-div">
     <h3>Informacije o terminu</h3>
     <button  class="close-btn" (click)="navigateBack()">
       <span class="material-symbols-outlined">
         close
       </span>
     </button>
    </div>
    <div class="info-div">
      <div>
         <h4>{{appointment?.userName}}</h4>
         <h5 class="formatted-date">{{ formatDate(appointment?.date)}}</h5>
         <h5 class="name">{{appointment?.appointmentName}}</h5>
         <p>{{ formatTime(appointment?.time,appointment?.timeForWork)}}</p>
         <p>{{appointment?.staffName}}</p>
         <p class="red">{{appointment?.price}} RSD</p>
      </div> 
        <div class="input-div">
          <select class="select width" #selectedStatusDropdown [(ngModel)]="selectedStatus">
          <option class="option" value="NULL"  hidden>Status termina</option>
          <option class="option" value="ARRIVED" [disabled]="!isAppointmentPast()">Došao</option>
          <option class="option red" value="NOT_ARRIVED" [disabled]="!isAppointmentPast()">Nije Došao</option>
          <option class="option" value="CANCELED">Otkaži</option>
        </select>
        </div>
        <div class="input-div">
          <label class="text-label" for="">Beleške</label>
          <textarea class="textarea" name="" rows="10" cols="30">
          </textarea>
        </div>
        <div class="btns">
          <button (click)="navigateBack()" class="btn-back">
          Odustani
        </button>
        <button  class="btn-save"  (click)="editAppointment(selectedStatusDropdown.value)">
          Sačuvaj
        </button>
      </div>    
    </div>
</div>
  