<div class="main">
    <section class="header">
        <app-header></app-header>
     </section>   
   <section class="section-1">
     <div class="col-1">
        <h1>Zakaži svoj naredni tretman</h1>
        <img src="../assets/icons/Odabir_Usluge_SvetleSenke 1.png" alt="3D MODEL">
     </div>
     <div class="col-2">
            <form
            [formGroup]="searchForm"
            class="searchBar"
          >
          <div class="search-container">
            <input type="search" placeholder="Usluga" class="search-input" formControlName="category">
             <span class="material-symbols-outlined">
                search
             </span>
             <ul *ngIf="isCategoryListVisible && (filteredCategories$ | async) as filteredCategories">
                <li *ngFor="let category of filteredCategories" (click)="selectCategory(category)">{{ transformWord(category) }}</li>
              </ul>
          </div>
      
        <div class="search-container" >
            <input type="search" placeholder="Lokacija" class="search-input" formControlName="city">
            <div class="svg">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405" class="mobile-svg"><path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-172.92q112.77-98.16 178.31-199.66t65.54-175.57q0-109.77-69.5-181.2-69.5-71.42-174.35-71.42t-174.35 71.42q-69.5 71.43-69.5 181.2 0 74.07 65.54 175.57T480-172.92Zm0 53.69Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Zm0-436.15Z"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405" class="desktop-svg"><path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Z"/></svg>
            </div>
            <ul *ngIf="isCityListVisible && (filteredCities$ | async) as filteredCities">
                <li *ngFor="let city of filteredCities" (click)="selectCity(city)">{{ city }}</li>
              </ul>
        </div>
        <div class="date" >
            <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-datepicker-toggle matIconSuffix [for]="picker" (mouseenter)="hovered = true" (mouseleave)="hovered = false" >
                  <mat-icon matDatepickerToggleIcon >
                    <div class="svg" [ngStyle]="{ 'background-color': (hovered ? '#C6DDA8' : '') }">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405" class="desktop-svg"><path d="M480.21-377.85q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19ZM480.21-240q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T480.21-240Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T324.21-240Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T636.21-240ZM240.62-136q-24.32 0-40.47-16.5T184-192.62v-478.76q0-23.62 16.15-40.12Q216.3-728 240.62-728h102.76v-105.23h35.08V-728h206.16v-105.23h32V-728h102.76q24.32 0 40.47 16.5T776-671.38v478.76q0 23.62-16.15 40.12Q743.7-136 719.38-136H240.62Zm0-32h478.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-310.76H216v310.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69Z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405" class="mobile-svg"><path d="M240.62-136q-24.32 0-40.47-16.5T184-192.62v-478.76q0-23.62 16.15-40.12Q216.3-728 240.62-728h102.76v-105.23h35.08V-728h206.16v-105.23h32V-728h102.76q24.32 0 40.47 16.5T776-671.38v478.76q0 23.62-16.15 40.12Q743.7-136 719.38-136H240.62Zm0-32h478.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-310.76H216v310.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM216-535.39h528v-135.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H240.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v135.99Zm0 0V-696v160.61Zm264.21 157.54q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19ZM480.21-240q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T480.21-240Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T324.21-240Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T636.21-240Z"/></svg>
                    </div>
                </mat-icon>
              </mat-datepicker-toggle>
            <mat-datepicker #picker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Otkaži</button>
                    <button mat-raised-button  matDatepickerApply>OK</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>    
        </div>
        <button class="btn-search" type="submit" (click)="onSearchSubmit()">Pretraži</button>
    </form>
     </div>
   </section>
   <!-- <section class="categories-section">
    <div class="categories">
        <div class="salons">Saloni</div>
        <div class="gruming">Gruming</div>
        <div class="auto">Automobili</div>
    </div>   
   </section> -->
   <section class="section-2" *ngIf="(homeDTO && homeDTO.news && homeDTO.news.length > 0)">
    <section class="product">
        <div class="con">
           <div class="slider-wrap">
              <div class="title">
                  <h4>Novo na Clicku</h4>
                  <span class="material-symbols-outlined" appPrev *ngIf="( homeDTO.news.length > 4)">
                      arrow_left_alt
                  </span>
                  <span class="material-symbols-outlined" appNext *ngIf="( homeDTO.news.length > 4)">
                      arrow_right_alt
                  </span>
               </div>
             <div class="slider-main">
                 <div  class="item"  *ngFor="let place of homeDTO.news">
                   <div class="salon" (click)="navigatetoSalon(place.id)">
                    <div class="image">
                       <img *ngIf="place.coverPhoto" [src]="place.coverPhoto" alt="">
                    </div>
                    <p class="name">{{ place.name }}</p>
                    <div class="flex">
                      <p class="address">{{ place.address }}</p>
                      <div *ngIf="place.rating" class="rating">{{ place.rating }}</div>
                    </div>
                   </div>
                 </div>
             </div>
           </div>
          </div>
        </section>
   </section>
   <section class="section-3">
       <h3>Najpopularnije usluge</h3>
       <div class="right">
       <div class="popular">
        <div class="div-popular color1">
            <p>manikir</p>
        </div>
        <div class="div-popular color2">
            <p>pedikir</p>
        </div>
        <div class="div-popular color5">
            <p>laserska epilacija</p>
        </div>
        <div class="div-popular color7">
            <p>gel lak</p>
        </div>
        <div class="div-popular color4">
            <p>estetski pedikir</p>
        </div>
        <div class="div-popular color6">
            <p>estetski pedikir</p>
        </div>
        <div class="div-popular color6">
            <p>manikir</p>
        </div>
        <div class="div-popular color7">
            <p>estetski pedikir</p>
        </div>
        <div class="div-popular color7">
            <p>gel lak</p>
        </div>
        <div class="div-popular color6">
            <p>pedikir</p>
        </div>
        <div class="div-popular color2">
            <p>laserska epilacija</p>
        </div>
        <div class="div-popular color3">
            <p>gel lak</p>
        </div>
        <div class="div-popular color6">
            <p>laserska epilacija</p>
        </div>
        <div class="div-popular color3">
            <p>pedikir</p>
        </div>
        <div class="div-popular color8">
            <p>gel lak</p>
        </div>
    </div>
       </div>
   </section>
   <section class="section-4">
     <div>
        <h1>Na klik do Clicka</h1>
        <p>Pristupi Clicku bez muke. Olakšaj sebi naredna zakazivanja i sačuvaj Clicko aplikaciju.</p>
     </div>
        <img src="../assets/icons/image 3.png" alt="">
   </section>
    <section class="section-5">
     <h1>U par Clickova do novog termina</h1>
     <div class="div2">
        <div class="slideshow-image">
            <img class="slideshow-img" [src]="currentImage"  src="" alt="">
        </div>
         <div class="div-text" #triggerElement>
           <p [ngClass]="{ 'active': currentSlide === 0 }" (click)="showImage(0)">Odaberi uslugu</p>
           <p [ngClass]="{ 'active': currentSlide === 1 }" (click)="showImage(1)">Odaberi termin</p>
           <p [ngClass]="{ 'active': currentSlide === 2 }" (click)="showImage(2)">Sačekaj potvrdu</p>
           <p [ngClass]="{ 'active': currentSlide === 3 }" (click)="showImage(3)">Vidimo se!</p>
         </div>
         <div class="div-text-mobile">
            <span class="material-symbols-outlined" (click)="prevSlide()">
                arrow_left_alt
            </span>
            <div class="div-mobile" #triggerElement>
                <div>{{ currentDiv }}</div>
              </div>
            <span class="material-symbols-outlined" (click)="nextSlide()">
                arrow_right_alt
            </span>
         </div>
     </div>
    
   </section>
   <section class="section-6" *ngIf="(homeDTO && homeDTO.specialPrices && homeDTO.specialPrices.length > 0)">
    <section class="product">
        <div class="con">
           <div class="slider-wrap">
              <div class="title">
                  <h4>Akcije u salonima</h4>
                  <span class="material-symbols-outlined" appPrev  *ngIf="(homeDTO.specialPrices.length > 4)">
                      arrow_left_alt
                  </span>
                  <span class="material-symbols-outlined" appNext  *ngIf="(homeDTO.specialPrices.length > 4)">
                      arrow_right_alt
                  </span>
               </div>
             <div class="slider-main">
                <div  class="item"  *ngFor="let place of homeDTO.specialPrices">
                    <div class="salon" (click)="navigatetoSalon(place.id)">
                     <div class="image">
                        <img *ngIf="place.coverPhoto"  [src]="place.coverPhoto" alt="">
                     </div>
                     <p class="name">{{ place.name }}</p>
                     <div class="flex">
                       <p class="address">{{ place.address }}</p>
                       <div class="rating">{{ place.rating }}</div>
                     </div>
                    </div>
                 </div>
             </div>
           </div>
          </div>
        </section>
   </section>
   <section class="cards-section">
    <div class="display" >
        <div class="background">
          <p>Zašto postati Clicko</p>
          <h1>Partner</h1>
        </div>
        <div class="col">
            <div class="cards">
                <article [ngClass]="{'expanded': isExpanded1, 'in-frame': isArticleInFrame(1)}">
                    <h2>01</h2>
                    <div class="dot1"></div>
                    <h5>Onlajn profil na Clicko platformi =</h5>
                    <p *ngIf="isExpanded1 && isParagraphVisible(1)">Hiljade potencijalnih klijenata u vašoj okolini</p>
                    <span class="material-symbols-rounded" (click)="toggleArticle(1)"[ngClass]="{'rotated': isExpanded1}">
                        arrow_outward
                    </span>
                </article>
                <article [ngClass]="{'expanded': isExpanded2, 'in-frame': isArticleInFrame(2)}">
                    <h2>02</h2>
                    <h5>Onlajn profil na Clicko platformi =</h5>
                    <p *ngIf="isParagraphVisible(2) && isExpanded2">Hiljade potencijalnih klijenata u vašoj okolini</p>
                    <span class="material-symbols-rounded" (click)="toggleArticle(2)" [ngClass]="{'rotated': isExpanded2}">
                        arrow_outward
                    </span>
                </article>
                <article [ngClass]="{'expanded': isExpanded3, 'in-frame': isArticleInFrame(3)}">
                    <h2>03</h2>
                    <h5>Onlajn profil na Clicko platformi =</h5>
                    <p *ngIf="isParagraphVisible(3) && isExpanded3">Hiljade potencijalnih klijenata u vašoj okolini</p>
                    <span class="material-symbols-rounded" (click)="toggleArticle(3)" [ngClass]="{'rotated': isExpanded3}">
                        arrow_outward
                    </span>
                </article>
                <article class="last">
                    <img src="../assets/icons/Like_Svetle_Senke 1.png" alt="3D MODEL">
                </article>
                      
              </div>
         </div>
          <button class="btn-partnerinfo" (click)="navigatetoPartner()" >Saznaj više o Clicko Partneru</button>
      </div> 
   </section>
   <section class="section-7">
      <div class="mail">
         <div>
        <p>Ukoliko imate dodatnih pitanja ili vam se ćaska sa nama, pišite nam</p>
        <button><a class="link" href="mailto:hi@clicko.rs">hi&#64;clicko.rs</a></button>
         </div>
          <img src="../assets/icons/TV3_01 3.png" alt="">
      </div>  
   </section>
   <a  class="to-top" #toTop>
    <span class="material-symbols-outlined" (click)="tothetop()">
      arrow_upward_alt
    </span>
    </a>
   <section class="section-8">
    <app-footer></app-footer>
   </section>
</div>
