import { Component, OnInit, ViewChild, inject, ElementRef, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { NotificationDTO, NotificationName } from 'src/app/models/NotificationDTO';

interface Notification {
  id: number;
  active: boolean;
  notificationName: string;
  userID: number;
}

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit{

  @ViewChild('editContainer') editContainer: ElementRef;
  router:Router = inject(Router);
  UserDTO: any;

  form: FormGroup;

  notification: boolean = false;
  promotion: boolean = false;
  active: boolean = false;

  PromotionNotifications: any[] = [];
  emailNotification: any;
  emailPromotion: any;

 openEdit: boolean = false;
 
  constructor(private auth: AuthService, 
              private fb: FormBuilder,
              private userService: UserService,
              private elRef: ElementRef){}

  navigateToDeleteAccount(){
    this.router.navigate(['delete-account']);
  }

  logout(){
   this.auth.signout();
  }

  exitEditDiv(){
    this.openEdit = false;
  }
  openEditDiv(){
    this.openEdit = true;
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
  }

  ngOnInit(){

    this.form = new FormGroup({
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      email: new FormControl(null),
      telephone: new FormControl(null),
      dateOfBirth: new FormControl(null),
      gender: new FormControl(null)
 
  })

  this.form.addControl('id', new FormControl(null));

    this.auth.getUser().subscribe(
      (data) => {
        if (data) {
          this.UserDTO = data;
          this.checkNotifications(data.notifications);
    

          this.form.patchValue({
            firstName: this.UserDTO.firstName,
            lastName: this.UserDTO.lastName,
            email: this.UserDTO.email,
            telephone: this.UserDTO.telephone,
            dateOfBirth: this.UserDTO.dateOfBirth,
            gender: this.UserDTO.gender
          });

 
  
          this.form.patchValue({ id: this.UserDTO.id });

    

        } else {
          console.warn("");
        }
      },
      (error) => {
        console.error("", error);
      }
    );
   

   
 
  }

  transformWord(word: string): string {
    if(word!=null){
    return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
  }
  else{
    return ""
  }
  }

  dateTranform(inputDate: string): string {
  if(inputDate!=null){
    const dateParts = inputDate.split('-');
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}.`;
  }
  else{
    return ""
  }
    
  }

  checkNotifications(notifications: Notification[]): void {
    const emailNotification = notifications.find(item => item.notificationName === 'EMAIL_NOTIFICATION' && item.active === true);
    this.notification = !!emailNotification;

    const emailPromotion = notifications.find(item => item.notificationName === 'EMAIL_PROMOTION' && item.active === true);
    this.promotion = !!emailPromotion;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    if (window.innerWidth > 768) {
      this.openEdit = true;
    }  else {
      this.openEdit = false;
    }
  }



  OnFormSubmitted() {
  
    if (this.form.valid) {
      // console.log(this.form.value);
      // console.log(this.UserDTO.id);
      // this.form.patchValue({ id: this.UserDTO.id });
      this.auth.clientInfo(this.form.value)
      .subscribe({
        next:(res)=>{
         console.log(res);
        },
        error:(err)=>{
          alert(err?.error.message)
        }
       })
      }else{
        console.warn('Greska');
      }

      window.location.reload();
    }

    onFileChange(event: any) {
      const files = event.target.files;
      if (files && files.length > 0) {
        this.uploadFiles(files[0]); 
      }
    }
    
    uploadFiles(photo: File): void {
      const userId = this.UserDTO.id; 
      console.log(userId);
      this.userService.addProfilePhoto(photo, userId).subscribe(
        response => {
          console.log(response);
        },
        error => {
          console.error(error);
        }
      );
      window.location.reload();
}

onCheckboxChangePromotion(event: any){

  const notificationDTO: NotificationDTO = {
    notificationName: NotificationName.EMAIL_PROMOTION, 
    active: true, 
    userID: this.UserDTO.id,
  };


  if (event.target.checked) {
  
    this.userService.putNotification(notificationDTO).subscribe(
      (response) => {
        console.log(notificationDTO);
      },
      (error) => {
        console.error(error);
  
      }
    );
  } else {
    notificationDTO.active = false;
    this.userService.putNotification(notificationDTO).subscribe(
      (response) => {
      },
      (error) => {
        console.error(error);

      }
    );
  }

}

onCheckboxChangeNotification(event: any){
  const notificationDTO: NotificationDTO = {
    notificationName: NotificationName.EMAIL_NOTIFICATION, 
    active: true, 
    userID: this.UserDTO.id,
  };


  if (event.target.checked) {
  
    this.userService.putNotification(notificationDTO).subscribe(
      (response) => {
        console.log(notificationDTO);

      },
      (error) => {
        console.error(error);
        console.log(this.UserDTO.id);
        console.log(notificationDTO);
      }
    );
  } else {
    notificationDTO.active = false;
    this.userService.putNotification(notificationDTO).subscribe(
      (response) => {
       
      },
      (error) => {
        console.error(error);
        console.log(notificationDTO);
      }
    );
  }

}
}
 
