<div class="main">
    <div class="header">
        <app-header></app-header>
    </div>
      <div class="page">
        <ul class="page-options">
            <li  class="page-options-item" routerLinkActive="active"> 
                <a routerLink="client-info"  href="">Profil</a>
            </li>
            <li  class="page-options-item" routerLinkActive="active">
                <a routerLink="client-appointment"  href="">Termini</a>
            </li>
            <!-- <li class="page-options-item" routerLinkActive="active">
                <a routerLink="client-vocher"  href="">Vaučeri</a>
            </li> -->
            <li  class="page-options-item" routerLinkActive="active" >
                <a routerLink="client-favorite" href="">Omiljeni</a>
            </li>
        </ul>
      </div>
    <router-outlet></router-outlet>
    <section class="footer">
        <app-footer></app-footer>
       </section>
</div>

