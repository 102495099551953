import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapsModule } from "@angular/google-maps";
import { AuthService } from 'src/app/services/auth/auth.service';
import { SalonService } from 'src/app/services/salon-services/salon.service';

interface WorkingHour {
  day: string;
  start: string | null;
  end: string | null;
}

interface Staff {
  name: string;
  job: string;
  photoUrl: string | null;
}

interface NextWorkingDay {
  id: string | null;
  start: string | null;
  end: string | null;
  day: string | null;
  placeID: string | null;
}

interface CustomPlaceDTO {
  addressCity: string;
  description: string | null;
  workingHours: WorkingHour[];
  staffs: Staff[];
  name: string;
  rating: number;
  address: string;
  open: boolean;
  nextWorkingDay: NextWorkingDay;
  telephone: string;
  email: string;
}
@Component({
  selector: 'app-salon-info',
  templateUrl: './salon-info.component.html',
  styleUrls: ['./salon-info.component.css']
})
export class SalonInfoComponent {
 
  router:Router = inject(Router);

  PlaceInfo: CustomPlaceDTO | null = null;
  appointments: any;
  workingHours: any[] = [];
  allStaff: any;

  salonId: any;
 
  isOpen: boolean;
  workingHour: WorkingHour;
  nextWorkingDay: NextWorkingDay;
  item: any;

  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService,
    private auth: AuthService
  ) { }



  ngOnInit(): void {
    this.salonId = this.route.snapshot.paramMap.get('id');

    if (this.salonId && this.salonService.getPlaceInfoDetails(this.salonId)) {
      this.salonService.getPlaceInfoDetails(this.salonId).subscribe({
        next: (data) => {
          if (data) {
            this.PlaceInfo = data;
            this.workingHours = this.PlaceInfo.workingHours;
            this.allStaff = this.PlaceInfo.staffs;
            this.isOpen = data.open;
          } else {
            console.error("");
          }
        },
        error: (error) => {
          console.error(error);
        }
      });
    } else {
      console.error("Salon ID or service is null");
    }
   
}

transformWord(word) {
  if(word){
    return word.toLowerCase().split(' ').map(word => { 
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ').replace(/_/g, ' ');
  }
  
}

}
