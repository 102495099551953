<section class="main">
    <div class="btn-div">
        <button (click)="goBack()" class="close-btn">
          <span class="material-symbols-outlined">
            close
          </span>
        </button>
    </div>
    <div class="col-1">
       <h1>Zakažite vaš naredni tretman</h1>
    </div>
    <div class="col-2">
      <form
      [formGroup]="searchForm"
      class="searchBar"
    >
    <div class="search-container">
      <input type="search" placeholder="Usluga" class="search-input" formControlName="category">
       <span class="material-symbols-outlined">
          search
       </span>
       <ul *ngIf="isCategoryListVisible && (filteredCategories$ | async) as filteredCategories">
          <li *ngFor="let category of filteredCategories" (click)="selectCategory(category)">{{ transformWord(category) }}</li>
        </ul>
    </div>

  <div class="search-container" >
      <input type="search" placeholder="Lokacija" class="search-input" formControlName="city">
      <div class="svg">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405" class="mobile-svg"><path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-172.92q112.77-98.16 178.31-199.66t65.54-175.57q0-109.77-69.5-181.2-69.5-71.42-174.35-71.42t-174.35 71.42q-69.5 71.43-69.5 181.2 0 74.07 65.54 175.57T480-172.92Zm0 53.69Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Zm0-436.15Z"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405" class="desktop-svg"><path d="M480.14-490.77q26.71 0 45.59-19.02 18.89-19.02 18.89-45.73 0-26.71-19.03-45.6Q506.57-620 479.86-620q-26.71 0-45.59 19.02-18.89 19.02-18.89 45.73 0 26.71 19.03 45.6 19.02 18.88 45.73 18.88ZM480-119.23Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Z"/></svg>
      </div>
      <ul *ngIf="isCityListVisible && (filteredCities$ | async) as filteredCities">
          <li *ngFor="let city of filteredCities" (click)="selectCity(city)">{{ city }}</li>
        </ul>
  </div>
  <div class="date" >
      <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
          <mat-label>Datum</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date">
          <mat-datepicker-toggle matIconSuffix [for]="picker" (mouseenter)="hovered = true" (mouseleave)="hovered = false" >
            <mat-icon matDatepickerToggleIcon >
              <div class="svg" [ngStyle]="{ 'background-color': (hovered ? '#C6DDA8' : '') }">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405" class="desktop-svg"><path d="M480.21-377.85q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19ZM480.21-240q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T480.21-240Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T324.21-240Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T636.21-240ZM240.62-136q-24.32 0-40.47-16.5T184-192.62v-478.76q0-23.62 16.15-40.12Q216.3-728 240.62-728h102.76v-105.23h35.08V-728h206.16v-105.23h32V-728h102.76q24.32 0 40.47 16.5T776-671.38v478.76q0 23.62-16.15 40.12Q743.7-136 719.38-136H240.62Zm0-32h478.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-310.76H216v310.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69Z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#1F3405" class="mobile-svg"><path d="M240.62-136q-24.32 0-40.47-16.5T184-192.62v-478.76q0-23.62 16.15-40.12Q216.3-728 240.62-728h102.76v-105.23h35.08V-728h206.16v-105.23h32V-728h102.76q24.32 0 40.47 16.5T776-671.38v478.76q0 23.62-16.15 40.12Q743.7-136 719.38-136H240.62Zm0-32h478.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-310.76H216v310.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM216-535.39h528v-135.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H240.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v135.99Zm0 0V-696v160.61Zm264.21 157.54q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.57 0-10.6 7.98-18.79t18.58-8.19q10.59 0 18.79 7.98 8.19 7.98 8.19 18.57 0 10.6-7.98 18.79t-18.58 8.19ZM480.21-240q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T480.21-240Zm-156 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T324.21-240Zm312 0q-10.59 0-18.79-7.98-8.19-7.98-8.19-18.58 0-10.59 7.98-18.79 7.98-8.19 18.58-8.19 10.59 0 18.79 7.98 8.19 7.99 8.19 18.58 0 10.6-7.98 18.79T636.21-240Z"/></svg>
              </div>
          </mat-icon>
        </mat-datepicker-toggle>
      <mat-datepicker #picker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Otkaži</button>
              <button mat-raised-button  matDatepickerApply>OK</button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>    
  </div>
  <button class="btn-search" type="submit" (click)="onSearchSubmit()">Pretraži</button>
</form>
</div>
 </section>
