import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/partner-services/clients.service';
import { UserService } from 'src/app/services/user/user.service';
import { CommonModule, Location } from '@angular/common';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'wc-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent {
  
  clientId:any;
  client: any;
  PlaceDTO: any;
  UserDTO: any;
  placeID:any;
  history: any;
 

  constructor(private clientService: ClientsService,
    private location: Location,
    private userService: UserService,
    private salonService: SalonService,
    private auth: AuthService,
    private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {

      this.auth.getUser().subscribe({
        next: (data) => {
          if (data) {
            this.UserDTO = data;
            if (this.UserDTO && this.UserDTO.placeID) {
              this.placeID = this.UserDTO.placeID;
              this.auth.getSalon(this.placeID).subscribe({
                next: (data) => {
                  if (data) {
                    this.PlaceDTO = data;
    
                   
        this.userService.getUserId(this.clientId).subscribe({
          next: (data) => {
              this.client = data;
                },
         error: (error) => {
              console.error( error);
             }
        });

        this.salonService.getClientHistory(this.PlaceDTO.id, this.clientId).subscribe({
          next: (data) => {
              this.history = data;
              console.log(this.history)
                },
         error: (error) => {
              console.error( error);
             }
        });
          
                  } else {
                    console.warn();
                  }
                },
                error: (salonError) => {
                  console.error( salonError);
                }
              });
            } else {
              console.warn();
            }
          } else {
            console.warn();
          }
        },
        error: (userError) => {
          console.error( userError);
        }
      });
     
       this.clientId = this.route.snapshot.paramMap.get('id');
      

  
    }

    transformWord(word: string): string {
      return word.toLowerCase().split(' ').map(word => { 
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ').replace(/_/g, ' ');
    }
  
    dateTranform(inputDate: string): string {
      const dateParts = inputDate.split('-');
      return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}.`;
    }
  
  

  closeClient() {
    this.location.back();
    this.clientService.closeClient();
  }

}