<div class="box" >
    <div class="center">
    <!-- <div class="btn-box">
        <button type="button"  class="add-btn" (click)="addCategory()">
            <span class="material-symbols-outlined">
                add
            </span>
            <p>
               Dodaj Kategorije
            </p>
        </button>
    </div> -->
    <!-- <div class="addcategory" *ngIf="isAddCategory">
         <h4>Dodaj kategoriju</h4>
         <div class="select-group">
         <label for="">Naziv kategorije</label>
         <select #selectedCategoryDropdown>
            <option value="" hidden></option>
            <option [value]="item" *ngFor="let item of allCategories">{{ item }}</option>
         </select>
         </div>
         <div class="addcategory-btn">
            <button type="reset" class="discard-button" (click)="hideAddCategory()">Poništi</button>
            <button type="submit" class="save-button" (click)="saveCategory(selectedCategoryDropdown.value)">Sačuvaj</button> 
        </div>
    </div> -->
    <!-- <div class="overlay" *ngIf="isAddCategory" (click)="hideAddCategory()"></div> -->
    <div class="c-box">
     <div class="category"  *ngFor="let category of appointmentCategoryPlaceDTO?.categories">
        <div class="c-title">
           <h3 class="title-h3">{{ transformWord(category) }}</h3>
        </div>
     
         <div class="c-info"> 
        <ng-container *ngFor="let appointment of appointments">
          <div class="treatment"  *ngIf="transformWord(appointment.category) === transformWord(category)" >
            <div class="edit-or-delete" *ngIf="appointmentStates[appointment.id]?.isEditOrDelete">
                <p class="edit">Uredi</p>
             <p class="delete" (click)="deleteTretment(appointment.id, appointment)">Obriši</p>
         </div>
            <p class="c-name" (click)="openEditDelete(appointment.id)"  [class]="'c-name-' + appointment.id">
                <span class="material-symbols-outlined">
                    more_vert
                </span>
                {{ getTreatmentName(appointment.id) }}
            </p>
            <div class="inline">
                <ng-container class="inline" *ngFor="let staff of appointment.userDTOs; let last = last">
                    <p class="c-staff">{{staff.firstName}}</p>
                    <p class="c-staff" *ngIf="!last">,  </p>
                  </ng-container>
                </div>
            <p class="c-time"*ngIf="getTreatmentTime(appointment.id)">{{ getTreatmentTime(appointment.id) }}min</p>
            <p class="c-price"*ngIf="getTreatmentPrice(appointment.id)">{{ getTreatmentPrice(appointment.id) }} RSD</p>
            <p class="c-discount-price"*ngIf="getTreatmentPrice(appointment.id)">{{ getTreatmentPrice(appointment.id) }} RSD</p>
           </div>
        </ng-container>
        <div>
            <button type="button"  class="addtreatment-btn" (click)="openTreatment()" >
                <span class="material-symbols-outlined btnicon">
                    add
                </span>
                <p>
                   Dodaj Tretman
                </p>
            </button>
    </div>
     </div>   
    </div>
    <div class="overlay-mobile" *ngIf="isTreatmentOpen"  (click)="closeTreatment()" ></div>
    <wc-addtreatments *ngIf="isTreatmentOpen" class="z-index" ></wc-addtreatments>
  </div>
</div>