import { Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { AnyComponent } from '@fullcalendar/core/preact';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent {

  @Output() onNext: EventEmitter<any> = new EventEmitter();
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  router:Router = inject(Router);

  PlaceDTO: any;
  allStaff: any;
  salonId: any;

  selectedTreatment: any;
  isTreatmentSelected: boolean = false;

  isUserSelected: boolean = false;
  selectedUsers: any[] = [];
  selectedUser: any;
  firstUser: any;

  isMobile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private salonService: SalonService,
  
  ) { }


  
  ngOnInit(): void {
    
    this.salonId = this.route.snapshot.paramMap.get('id');

  if (this.salonId) {
    this.salonService.getPlaceDetails(this.salonId).subscribe(
      (data) => {
        this.PlaceDTO = data;
      },
      (error) => {
        console.error("", error);
      }
    );
  } else {
    console.error("salonId is null");
  }
   

  this.checkScreenSize(); 
  window.addEventListener('resize', this.checkScreenSize.bind(this));
  
  this.selectedTreatment = this.salonService.selectedTreatment;
  

    this.salonService.getStaffByAppointment(this.selectedTreatment.id).subscribe(
          (data) => {
              this.allStaff = data;
              this.firstUser = this.allStaff[0];
              this.selectedUser = this.allStaff[0];
              this.isUserSelected = true;
              // console.log(this.selectedTreatment.id);
              // console.log(this.allStaff);
          },
          (error) => {
            console.error("nema zaposlenih", error);
          }
      );   
      

} 

checkScreenSize() {
  this.isMobile = window.innerWidth < 768;
}

selectUser(staff: AnyComponent){
  this.selectedUser = staff;
  this.isUserSelected = true;
  console.log(this.selectedUser)
  
   

  if (this.isTreatmentSelected) {
    this.selectedTreatment.staff = staff; 
   
  } 

  if (this.isMobile) {
    this.salonService.setSelectedUser(this.selectedUser);
    this.onNext.emit();
  }


}

transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
    return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ').replace(/_/g, ' ');
}

goToNextStep() {
  this.salonService.setSelectedUser(this.selectedUser);
  this.onNext.emit();
}
      

}
