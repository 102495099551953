import { Component, ViewChild, ElementRef, AfterViewInit, inject, HostListener } from '@angular/core';
import { Event, Router } from '@angular/router';
import { SalonService } from '../services/salon-services/salon.service';
import { HomepageService } from '../services/homepage/homepage.service';
import {  Renderer2 } from '@angular/core';
import { HomeDTO } from '../models/homepage';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import ValidateForm from 'src/app/helpers/validateform';




@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements AfterViewInit {
  @ViewChild('triggerElement') triggerElement!: ElementRef; 
  @ViewChild('divMobile') divMobile!: ElementRef;

currentIndex = 0;
observer!: IntersectionObserver;

isExpanded1: boolean = false;
isExpanded2: boolean = false;
isExpanded3: boolean = false;

hovered: boolean = false;

homeDTO: HomeDTO;
cityList: any[] = [];
categoriesList: any[] = [];


router:Router = inject(Router);

filteredCities$: Observable<any[]>;
filteredCategories$: Observable<any[]>;
searchForm: FormGroup;
isCityListVisible = false;
isCategoryListVisible = false;


currentSlide: number = 0;
currentImage: string = '../assets/icons/Frame 2608606.png'; 
currentDiv: string = 'Odabir usluge';

constructor(private salonService: SalonService,
  private homepageService: HomepageService,
  private elementRef: ElementRef,
   private renderer: Renderer2,
   private fb: FormBuilder) {
    
    this.searchForm = this.fb.group({
      category: new FormControl(''),
      city: new FormControl(null),
      date: new FormControl(null),
    });
}




ngAfterViewInit() {
  this.observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.startSlideshow();
        this.observer.disconnect();
      }
    });
  });

  this.observer.observe(this.triggerElement.nativeElement);
}

ngOnInit(): void {


  this.homepageService.homepageApi().subscribe({
    next: (data) => {
      this.homeDTO = data;
      this.cityList = data.city;
      this.categoriesList = data.categories;
      // console.log(this.homeDTO);
      // console.log(this.cityList)
    },
    error: (error) => {
      console.error(error);
    }
  });

  this.filteredCities$ = this.searchForm.get('city').valueChanges.pipe(
    startWith(''),
    map(value => this.filterCities(value))
  );

  this.filteredCategories$ = this.searchForm.get('category').valueChanges.pipe(
    startWith(''),
    map(value => this.filterCategories(value))
  );

  this.searchForm.get('city').valueChanges.subscribe(value => {
    this.isCityListVisible = !!value;
  });

  this.searchForm.get('category').valueChanges.subscribe(value => {
    this.isCategoryListVisible = !!value;
  });
}

private filterCities(value: string): string[] {
  if (!value) {
    return [];
  }

  const filterValues = value.toLowerCase().split(' ').filter(Boolean);
  return this.cityList
    .filter(city => city !== null)
    .filter(city => {
      const cityName = city.toLowerCase().replace(/[čćžšđ]/g, (match) => {
        switch (match) {
          case 'č': return 'c';
          case 'ć': return 'c';
          case 'ž': return 'z';
          case 'š': return 's';
          case 'đ': return 'd';
          default: return match;
        }
      });
      return filterValues.every(word => cityName.includes(word));
    });
}

private filterCategories(value: string): string[] {
  const filterValues = value.toLowerCase().split(' ').filter(Boolean); 
  return this.categoriesList
  .filter(category => category !== null)
  .filter(category => {
    const categoryName = category.toLowerCase().replace(/[čćžšđ]/g, (match) => {
      switch(match) {
        case 'č': return 'c';
        case 'ć': return 'c';
        case 'ž': return 'z';
        case 'š': return 's';
        case 'đ': return 'd';
        default: return match; 
      }
    });
    return filterValues.every(word => categoryName.includes(word));
  });
}

selectCity(city: string): void {
  this.searchForm.get('city').setValue(city);
  this.isCityListVisible = false;
}

selectCategory(category: string): void {
  this.searchForm.get('category').setValue(this.transformWord(category));
  this.isCategoryListVisible = false;
}

onSearchSubmit(): void {
  const transformedCategory = this.transformBack(this.searchForm.get('category').value);
  const transformedCity = this.searchForm.get('city').value;
  const transformedDate = this.searchForm.get('date').value;


  if (!transformedCategory && !transformedCity && !transformedDate) {
      this.getAllSalons();
  } else {
   
      this.salonService.setData(transformedCategory, transformedCity, transformedDate);

      this.searchForm.setValue({
          category: transformedCategory,
          city: transformedCity,
          date: transformedDate,
      });

      console.log(this.searchForm.value);
      this.router.navigate(['place']);
  }
}

getAllSalons(): void {
  this.salonService.getPlaces().subscribe((item) => {
      console.log(item);
      this.router.navigate(['place']);
  
  });
}

transformWord(word: string): string {
  return word.toLowerCase().split(' ').map(word => { 
  return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ').replace(/_/g, ' ');
  }
  
transformBack(word){
  if(word != null){
    return word.toUpperCase().replace(/ /g, '_');
  } 
}

getFormattedDateForApi(date: Date): string {
  const year = date.getFullYear();
  const month = this.padZero(date.getMonth() + 1);
  const day = this.padZero(date.getDate());
  return `${year}-${month}-${day}`;
}

padZero(num: number): string {
  return num < 10 ? '0' + num : num.toString();
}

navigatetoSalon(salonId: number): void {
  console.log(salonId); 
  this.router.navigate(['salon', 'salon-services', salonId]);
}

navigatetoPartner(){
  this.router.navigate(['partnerpage']);
}

startSlideshow() {
  setInterval(() => {
    this.currentSlide = (this.currentSlide + 1) % 4;
    this.currentImage = this.getImagePath(this.currentSlide);
    this.currentDiv = this.getDiv(this.currentSlide);
  }, 3000); 
}

prevSlide() {
  const newSlide = (this.currentSlide - 1 + 4) % 4; 
  this.showImage(newSlide);
}

nextSlide() {
  const newSlide = (this.currentSlide + 1) % 4;
  this.showImage(newSlide);
}

showImage(slideNumber: number) {
  this.currentSlide = slideNumber;
  this.currentImage = this.getImagePath(slideNumber);
  this.currentDiv = this.getDiv(slideNumber);
}

getImagePath(slideNumber: number): string {
   if (slideNumber === 0) {
   return '../assets/icons/Frame 2608606.png';
   } else if (slideNumber === 1) {
    return '../assets/icons/Frame 2608653.png';
  } else if (slideNumber === 2) {
    return '../assets/icons/Frame 2608654.png';
  } 
  return '../assets/icons/Frame 2608655.png';
}

getDiv(slideNumber: number): string {
  if (slideNumber === 0) {
  return 'Odabir usluge';
  } else if (slideNumber === 1) {
   return 'Odabir termina';
 } else if (slideNumber === 2) {
   return 'Potvrda termina';
 } 
 return 'To je to!';
}

toggleArticle(articleNumber: number) {
  switch (articleNumber) {
    case 1:
      this.isExpanded1 = !this.isExpanded1;
      if (this.isExpanded1) {
        this.isExpanded2 = false;
        this.isExpanded3 = false;
      }
      break;
    case 2:
      this.isExpanded2 = !this.isExpanded2;
      if (this.isExpanded2) {
        this.isExpanded1 = false;
        this.isExpanded3 = false;
      }
      break;
    case 3:
      this.isExpanded3 = !this.isExpanded3;
      if (this.isExpanded3) {
        this.isExpanded1 = false;
        this.isExpanded2 = false;
      }
      break;
    default:
      break;
  }
}

isArticleInFrame(articleNumber: number): boolean {
  const article = document.querySelector(`.col .cards article:nth-child(${articleNumber})`) as HTMLElement;
  const container = document.querySelector('.col') as HTMLElement;
  if (article && container) {
    const articleRect = article.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    return (
      articleRect.left >= containerRect.left &&
      articleRect.right <= containerRect.right &&
      articleRect.top >= containerRect.top &&
      articleRect.bottom <= containerRect.bottom
    );
  }
  return false;
}

isParagraphVisible(articleNumber: number): boolean {
  return this.isArticleInFrame(articleNumber);
}



@ViewChild('toTop') toTopButton!: ElementRef;
  

@HostListener('window:scroll', [])
onWindowScroll() {
  if (window.pageYOffset > 500) {
    this.toTopButton.nativeElement.classList.add('active');
  } else {
    this.toTopButton.nativeElement.classList.remove('active');
  }
}


tothetop(){
  window.scroll(0,0);
}


addHoverEffect() {
  const spanElement = this.elementRef.nativeElement.querySelector('.mat-icon span');
  this.renderer.addClass(spanElement, 'hover-effect');
}


}
