<div class="main">
    <div class="heading">
         <p>Termini</p>
         <h3>{{PlaceDTO?.name}}</h3>
    </div>
    <div class="heading display">
         <h4>Termini</h4>
    </div>
    <div class="page">
        <ul class="page-options">
            <li  class="page-options-item" routerLinkActive="active"> 
                <a routerLink="all"  href="">Svi</a>
            </li>
            <li  class="page-options-item" routerLinkActive="active">
                <a routerLink="confirmed"  href="">Potvrđeni</a>
            </li>
            <li class="page-options-item" routerLinkActive="active">
                <a routerLink="pending"  href="">Na čekanju</a>
            </li>
            <li  class="page-options-item" routerLinkActive="active" >
                <a routerLink="canceled" href="">Otkazani</a>
            </li>
        </ul>
    </div>
    <router-outlet></router-outlet>
    </div>
    
    