<div class="box">
    <div class="center">
    <div class="btn-box">
        <button type="button"  class="share-btn">
            <span class="material-symbols-outlined">
                link
            </span>
        </button>
        <button type="button"  class="add-btn"  (click)="addClient()">
            <span class="material-symbols-outlined">
                add
            </span>
            <p>
               Dodaj 
            </p>
        </button>
    </div>
    <div class="list">
        <div class="title">
               <div class="client-count">
                 <h3>
                    Klijenti
                </h3>
                <p class="number">
                   ({{clients.length}}) 
                </p>
               </div>
                <div class="search">
                <div class="search-container">
                <input type="search" [(ngModel)]="searchTerm">
                <button type="submit">
                    <span class="material-symbols-outlined ">
                        search
                    </span>
                </button>
              </div>
                 <a class="filter" (click)="openFilter()">
                <span class="material-symbols-outlined">
                    filter_alt
                </span>
                <p>Filter</p>
                </a>
            </div>
        </div>
        <div class="client-list">
              <div class="client-info"  *ngFor="let client of filteredClients" (click)="openClient(client.id)">
                <div class="name">
                  <div class="img">
                     <img  *ngIf="client.profilePhoto"   [src]="client.profilePhoto" alt="User Image">
                     <p *ngIf="!client.profilePhoto" class="initial"> {{ client.firstName?.slice(0, 1) }}</p> 
                 </div>
                   <p class="client-name">{{client.firstName}} {{client.lastName}}</p>
                </div>   
                <p class="client-type">{{getClientType(client.role)}}</p>
                <p class="client-phonenumber">{{client.telephone}} </p>
              </div>
        </div> 
    </div>
    <div class="overlay-mobile" *ngIf="clientService.isClientOpen" (click)="closeClient()" ></div>
    <router-outlet></router-outlet>
  </div>
  <div class="filterPopUp" *ngIf="filterPopUp">
    <div class="close-btn" (click)="closeFilterPopUp()">
        <span class="material-symbols-outlined">
          close
        </span>
    </div>
        <p (click)="toggleFilter('CLICKO_USER')">Clicko korisnik</p>
        <!-- <p >SMS korisnik</p> -->
        <p (click)="toggleFilter('GHOST_USER')">Ghost korisnik</p>
  
  </div>
  <div *ngIf="filterPopUp" (click)="closeFilterPopUp()"
  class="overlay">
   </div>
</div>
